import EmptyImage from "../../../../assets/images/avatars/blank.png";
import Layout from "../../../../Common/Layout";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import Alert from "../../../../Common/Alert/Alert";
import FormError from "../../../../Common/FormError/FormError";
import Select from "react-select";
import { connect } from "react-redux";
import { ImageUrl } from "../../../../Utils/BaseUrl";
import {
  userProfile,
  getCountry,
  updateUser,
  profilePic,
} from "../../../../Store/action/UserInfo.action";
import {
  initialValues_prsnlSetting,
  validationSchema_prsnlSetting,
  getFormat,
} from "./Validation";
import { customStyles, stringNameLength } from "../../Club/AllEventCalculation/Calculation";

const PersonalSettings = (props?: any) => {
  const {
    $userProfile,
    $getCountry,
    $updateUser,
    _getCountry,
    _userProfile,
    _updateUser,
  }: IPersonalSettingProps = props;
  const history = useHistory<any>();
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [optionsIs, setOptions] = useState<any>(null);
  const [imageLoader, setImgLoader] = useState<boolean>(false);
  const [countryLoader, setCountryLoader] = useState<boolean>(false)
  const [emailSame, setEmailSame]=useState<any>()
  const [alert, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });

  const setPicture = (pic: any) => {
    const formData = new FormData();
    formData.append("file", pic);
    setImgLoader(true);
    props.$profilePic(formData);
  };

  const handleProfilePic = (e?: any) => {
    const pictureIs = e.target.files[0];
    if (pictureIs) {
      setPicture(pictureIs);
    }
  };

  const handleRemovePic = () => {
    fetch(EmptyImage)
      .then((e) => {
        return e.blob();
      })
      .then((blob) => {
        var b: any = blob;
        b.lastModifiedDate = new Date();
        b.name = "";
        if (b) {
          setPicture(b);
        }
        return b as File;
      });
  };

  useEffect(() => {
    const picture = props._profilePic.profilePicIs;
    const pictireErr = props._profilePic.profilePicErrIs;
    if (picture?.status === 1 && imageLoader) {
      personalDetails();
      // getAlert(picture?.message, "alert-success")
      setImgLoader(false);
    } else if (pictireErr?.status === 0 && imageLoader) {
      getAlert(pictireErr?.message, "alert-danger");
      setImgLoader(false);
    }
  }, [props._profilePic]);

  // country && profile data api calling

  const personalDetails = () => {
    $userProfile();
  };

  useEffect(() => {
    setCountryLoader(true)
    $getCountry();    
    // personalDetails()
  }, []);

  useEffect(() => {
    personalDetails();
  },[_getCountry && (!countryLoader)])

  // country api response

  useEffect(() => {    
    const countryData = _getCountry.countryIs;
    const currentData = getFormat(countryData?.data);
    if (currentData?.length) {
      const d = [
        {
          id: 0,
          label: "Select country",
          name: "Select country",
          shortName: "",
          value: "",
        },
        ...currentData,
      ];
      setOptions([...d]);
      setCountryLoader(false)
    }
  }, [_getCountry]);

  const onsubmit = (values: IPersonalSetting) => {
    const id = values.country?.id;
    const a = {
      email: values?.email.toLowerCase(),
      firstName: values?.firstName,
      lastName: values?.lastName,
      screenName: values?.screenName,
      status: values?.status,
      countryId: id,
      bio:values?.bio,
      isFollowNotificationEnabled:values?.isFollowNotificationEnabled,
      isClubNotificationEnabled:values?.isClubNotificationEnabled,
      isChatNotificationEnabled:values?.isChatNotificationEnabled,
      isEventNotificationEnabled:values?.isEventNotificationEnabled,
      
    };
    setLoader(true);
    $updateUser(a);
    };

  // get user profile response

  const getUserProfileRes = (res: IPersonalSettingRes) => {
   const countryId = res?.countryId;
    const userCountry = optionsIs?.filter(
      (el: IOptionsEl) => el.id === (countryId !== null ? countryId : 0)
    );
    const userData = getFormat(userCountry);
    const currentData = {
      email: res.email,
      firstName: res.firstName,
      lastName: res.lastName,
      screenName: res.screenName,
      bio: res.bio,
      status: res.status,
      isFollowNotificationEnabled:res.isFollowNotificationEnabled,
      isClubNotificationEnabled:res.isClubNotificationEnabled,
      isChatNotificationEnabled:res.isChatNotificationEnabled,
      isEventNotificationEnabled:res.isEventNotificationEnabled,
      country: userData[0]?.label !== "Select country" ? userData[0] : false,
    };
    setUser(currentData);
  };

  const getUserProfileErr = () => {
    history.push({
      pathname: "/login",
    });
    localStorage.removeItem("token");
  };

  useEffect(() => {
    const userProfile:any = _userProfile.userDataIs.data;
    const userProfileErr = _userProfile.userDataErrIs;
    if (userProfile && optionsIs) {
      getUserProfileRes(userProfile);
    } else if (userProfileErr) {
      getUserProfileErr();
    }
  }, [_userProfile]);

  // update user profile response

  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  const getScreen = () => {
    if (props.location.state?.stateIs) {
      const invitedemail = props.location.state?.stateIs.split("/")[2];
      const invitedCode = props.location.state?.stateIs.split("/")[3];
      history.push(`/clublist/${invitedemail}/${invitedCode}`);
    } else if (history?.location?.state?.signUpIs) {
      history.push("/howtoplay");
    }
  };

  useEffect(() => {
    const userIs = _updateUser.updateUserIs;
    const userErrIs = _updateUser.updateUserErr;
    if (userIs?.status === 1 && LoadingIs) {
      getAlert(userIs?.message, "alert-success");
      setLoader(false);
      personalDetails();
      getScreen();
    } else if (userErrIs && LoadingIs) {
      getAlert(userErrIs?.message, "alert-danger");
      setLoader(false);
    }
  }, [_updateUser]);

  useEffect(() => {
    if (alert.activeIs) {
      setTimeout(() => {
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 3000);
    }
  }, [alert]);

  const _userData = _userProfile.userDataIs.data;

  return (
    <Layout options="fill_Header" uservisible="visible">
      <section className="main-content">
        <div className="account_pages d-flex">
          <div className="container pt-10">
            <div className="row">
              <div>
                <div className="w-sm-600px mx-auto card mb-5 mb-xl-10 shadow-sm">
                  <div className="card-header border-0 cursor-pointer">
                    <div className="card-title m-0">
                      <h3 className="fw-bolder m-0">Personal Details</h3>
                    </div>
                    <div className="card-title m-0">
                      {/* <h3 className="fw-bolder m-0 fs-4">{_userData?.email}</h3> */}
                    </div>
                  </div>
                  <div
                    id="kt_account_profile_details"
                    className="collapse show"
                  >
                    {alert.activeIs && (
                      <Alert
                        statusIs={`alert ${alert.statusIs}`}
                        message={alert.msgIs}
                      />
                    )}
                    <Formik
                      initialValues={user ? user : initialValues_prsnlSetting}
                      validationSchema={validationSchema_prsnlSetting}
                      onSubmit={onsubmit}
                      validateOnMount
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form
                          id="kt_account_profile_details_form"
                          className="form"
                        >
                          <div className="card-body border-top p-9">
                            <div className="row mb-6">
                              <div className="col-lg-12 text-center">
                                <div
                                  className="image-input image-input-outline"
                                  data-kt-image-input="true"
                                  // style={{backgroundImage:`url("${EmptyImage}")`}}
                                >
                                  {/* {imageLoader ? 
                                  <ButtonSpinner color="text-dark  spinner-border-sm " />:
                                   <> */}
                                    <div
                                    className="image-input-wrapper w-125px h-125px"
                                    style={{
                                      backgroundImage: `url("${
                                        _userData?.profilePic
                                          ? ImageUrl + _userData?.profilePic
                                           : EmptyImage
                                      }")`,
                                    }}
                                  />
                                  <label
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="Change avatar"
                                  >
                                    <i className="bi bi-camera-fill fs-7" />
                                    <input
                                      type="file"
                                      name="avatar"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={(e) => {
                                        handleProfilePic(e);
                                        e.target.value = ''
                                      }}
                                    />
                                    <input type="hidden" name="avatar_remove" />
                                  </label>
                                  {/* <span
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="cancel"
                                                        data-bs-toggle="tooltip"
                                                        title="Cancel avatar"
                                                        >
                                                        <i className="bi bi-x fs-2" />
                                                        </span> */}
                                  <span
                                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="remove"
                                    data-bs-toggle="tooltip"
                                    title="Remove avatar"
                                    onClick={handleRemovePic}
                                  >
                                    <i className="bi bi-x fs-2" />
                                  </span>
                                  {/* </>} */}
                                </div>
                                <label className="col-form-label fw-bolder fs-3 text-capitalize d-block">
                                  {_userData?.firstName 
                                  ?
                                  _userData?.firstName && stringNameLength(_userData?.firstName ? _userData?.firstName : "" ,_userData?.lastName ? _userData?.lastName : '' , 20, 20) || ""
                                  :
                                  _userData?.screenName
                                }
                                  {/* {stringWithValidLength(_userData?.lastName , 15, 15) || ""} */}
                                </label>
                              </div>
                            </div>
                            
                            <div className="row mb-6">
                              <label className="col-lg-12 form-label fs-6 fw-bolder text-dark">
                                <span className="required">Email</span>
                              </label>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-12 fv-row">
                                    <Field
                                      type="text"
                                      name="email"
                                      id="email"
                                      className={`form-control form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                        formik.touched.email &&
                                        formik.errors.email &&
                                        `border border-danger`
                                      }`}
                                      placeholder="Email"
                                      // defaultValue="Amit"
                                    />
                                    <ErrorMessage
                                      name="email"
                                      render={(msg) => (
                                        <FormError message={msg}></FormError>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="text-danger">{emailSame}</p>

                            <div className="row mb-6">
                              <label className="col-lg-12 form-label fs-6 fw-bolder text-dark">
                                <span className="required">First Name</span>
                              </label>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-12 fv-row">
                                    <Field
                                      type="text"
                                      name="firstName"
                                      id="firstName"
                                      className={`form-control form-control-lg form-control-solid mb-3 mb-lg-0 ${
                                        formik.touched.firstName &&
                                        formik.errors.firstName &&
                                        `border border-danger`
                                      }`}
                                      placeholder="First Name"
                                      // defaultValue="Amit"
                                    />
                                    <ErrorMessage
                                      name="firstName"
                                      render={(msg) => (
                                        <FormError message={msg}></FormError>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-6">
                              <label className="col-lg-12 form-label fs-6 fw-bolder text-dark">
                                <span className = "required">Last Name</span>
                              </label>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-12 fv-row">
                                    <Field
                                      type="text"
                                      name="lastName"
                                      id="lastName"
                                      className={`form-control form-control-lg form-control-solid ${
                                        formik.touched.lastName &&
                                        formik.errors.lastName &&
                                        `border border-danger`
                                      }`}
                                      placeholder="Last name"
                                      // defaultValue="Kumar"
                                    />
                                    <ErrorMessage
                                      name="lastName"
                                      render={(msg) => (
                                        <FormError message={msg}></FormError>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-6">
                              <label className="col-lg-12 form-label fs-6 fw-bolder text-dark">
                                Screen Name
                              </label>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-12 fv-row">
                                    <Field
                                      type="text"
                                      name="screenName"
                                      id="screenName"
                                      className={`form-control form-control-lg form-control-solid`}
                                      placeholder="Screen Name"
                                      style={{cursor: 'not-allowed'}}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-6">
                              <label className="col-lg-12 form-label fs-6 fw-bolder text-dark">
                                Tagline
                              </label>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-12 fv-row">
                                    <Field
                                      type="text"
                                      name="status"
                                      id="status"
                                      className={`form-control form-control-lg form-control-solid ${
                                        formik.touched.status &&
                                        formik.errors.status &&
                                        `border border-danger`
                                      }`}
                                      placeholder="Tagline"
                                      // defaultValue="Kumar"
                                    />
                                    <ErrorMessage
                                      name="status"
                                      render={(msg) => (
                                        <FormError message={msg}></FormError>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-6">
                              <label className="col-lg-12 form-label fs-6 fw-bolder text-dark">
                                Bio
                              </label>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-12 fv-row">
                                    <Field as="textarea"
                                      type="text"
                                      name="bio"
                                      id="bio"
                                     
                                      className={`form-control form-control-lg form-control-solid ${
                                        formik.touched.bio &&
                                        formik.errors.bio &&
                                        `border border-danger`
                                      }`}
                                      placeholder="Bio"
                                     
                                    />
                                    <ErrorMessage
                                      name="bio"
                                      render={(msg) => (
                                        <FormError message={msg}></FormError>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-6">
                              <label className="col-lg-12 form-label fs-6 fw-bolder text-dark">
                                <span>Country</span>
                              </label>
                              <div className="col-lg-12 fv-row">
                                <Select
                                  className={`form-select-solid fw-bold ${
                                    formik.touched.country &&
                                    formik.errors.country &&
                                    `border border-danger`
                                  }`}
                                  value={formik?.values?.country}
                                  onChange={(values) =>
                                    values?.label !== "Select country" &&
                                    formik.setFieldValue(
                                      "country",
                                      values,
                                      true
                                    )
                                  }
                                  options={optionsIs}
                                  name="country"
                                  id="country"
                                  styles={customStyles}
                                />
                                <ErrorMessage
                                  name="country"
                                  render={(msg) => (
                                    <FormError message={msg}></FormError>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="mt-6 d-flex justify-content-between notification-switch">
                             <label className="form-check-label  form-label fs-6 fw-bolder text-dark " htmlFor="flexSwitchCheckChecked">Allow Follow notifications</label>
                             <div className="form-check form-switch">
                             <Field name="isFollowNotificationEnabled" className="form-check-input checkBoxColor" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={formik?.values?.isFollowNotificationEnabled}  />
                             { formik?.values?.isFollowNotificationEnabled ? "ON" : "OFF" }
                            </div>
                            </div>
                            <div className = "d-flex justify-content-between notification-switch">
                             <label className="form-check-label  form-label fs-6 fw-bolder text-dark " htmlFor="flexSwitchCheckChecked">Allow Club notifications</label>
                             <div className="form-check form-switch">
                             <Field  name="isClubNotificationEnabled" className="form-check-input checkBoxColor" type="checkbox"  role="switch"  id="flexSwitchCheckChecked"  checked={formik?.values?.isClubNotificationEnabled}  />
                             { formik?.values?.isClubNotificationEnabled ? "ON" : "OFF" }
                            </div>
                            </div>
                            <div className = "d-flex justify-content-between notification-switch">
                             <label className="form-check-label  form-label fs-6 fw-bolder text-dark " htmlFor="flexSwitchCheckChecked">Allow Chat notifications</label>
                             <div className="form-check form-switch">
                             <Field  name="isChatNotificationEnabled" className="form-check-input checkBoxColor" type="checkbox"  role="switch"  id="flexSwitchCheckChecked"  checked={formik?.values?.isChatNotificationEnabled}  />
                             { formik?.values?.isChatNotificationEnabled ? "ON" : "OFF" }
                            </div>
                            </div>
                            <div className = "d-flex justify-content-between notification-switch">
                             <label className="form-check-label  form-label fs-6 fw-bolder text-dark " htmlFor="flexSwitchCheckChecked">Allow Event notifications</label>
                             <div className="form-check form-switch">
                             <Field  name="isEventNotificationEnabled" className="form-check-input checkBoxColor" type="checkbox"  role="switch"  id="flexSwitchCheckChecked"  checked={formik?.values?.isEventNotificationEnabled}  />
                             { formik?.values?.isEventNotificationEnabled ? "ON" : "OFF" }
                            </div>
                            </div>
                          </div>
                          <div className="card-footer d-flex justify-content-center py-6 px-9">
                            {/* <button
                              type="reset"
                              className="btn btn-light btn-active-light-primary me-2"
                            >
                              Cancel
                            </button> */}
                            <button
                              type="submit"
                              disabled={!formik.isValid}
                              className="btn btn-primary"
                              id="kt_account_profile_details_submit"
                            >
                              {LoadingIs ? (
                                <ButtonSpinner color="text-light  spinner-border-sm " />
                              ) : (
                                `${user?.firstName ? `Update` : `Save`}`
                              )}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const mapState = ({ userProfile, getCountry, updateUser, profilePic }: any) => {
  return {
    _userProfile: userProfile,
    _getCountry: getCountry,
    _updateUser: updateUser,
    _profilePic: profilePic,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $userProfile: () => dispatchEvent(userProfile()),
  $getCountry: () => dispatchEvent(getCountry()),
  $updateUser: (values?: IPersonalSettingArgu) =>
    dispatchEvent(updateUser(values)),
  $profilePic: (values?: any) => dispatchEvent(profilePic(values)),
});
export default connect(mapState, mapDispatch)(PersonalSettings);
