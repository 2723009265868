
import EmptyScreen from '../../../../assets/images/avatars/blank.png';
import Layout from '../../../../Common/Layout';
import { useHistory } from "react-router-dom";
import { createClub, userProfile, getClubDetails, clubPic } from "../../../../Store/action/UserInfo.action";
import { getInviteCode } from "../../../../Store/action/Events.action"
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { initialValues_createClub, validationSchema_createClub } from "./Validation";
import FormError from "../../../../Common/FormError/FormError";
import { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import Alert from "../../../../Common/Alert/Alert";

const ClubDetails = (props ?: any) => {
    const { $userProfile, $createClub, _userProfile, _createClub, $getClubDetails } : ICreateClubDetailsProps = props;
    const history = useHistory();
    const [LoadingIs, setLoader] = useState<boolean>(false)
    const [fileUrl, setFilesUrl] = useState<any>(null)
    const [files, setFiles] = useState<any>(null)
    const [alert, setAlert] = useState<IAlertIs>({
        activeIs: false,
        statusIs: "",
        msgIs: ""
    })

    const onSubmit = (values : ICreateClubDetails) => {
        setLoader(true)
        $createClub(values)
    }

    const getAlert = (message: string, status: string) => {
        setAlert({
            activeIs: true,
            statusIs: status,
            msgIs: message
        });
    }

    const handleClubPic = (e ?: any) =>{
      const data = e.target.files[0]
      if(data){
        setFilesUrl(URL.createObjectURL(data))
        setFiles(data)
      }     
    }

    const getClubProfile = () =>{
        if(files){
            const formdata = new FormData();
            formdata.append("file", files)
            props?.$clubPic(formdata)
        }
    }

    const handleRemovePic = () =>{
        fetch(EmptyScreen)
          .then((e) => {
            return e.blob()
          })
          .then((blob) => {
            var b: any = blob
            b.lastModifiedDate = new Date()
            b.name = ''
            if(b){
                setFilesUrl(URL.createObjectURL(b))
                setFiles(b)
            } 
            return b as File
          })    
      }

    useEffect(() => {
        const clubDetailsIs = _createClub.createClubIs;
        const clubErr = _createClub.createErrIs;
        if (clubDetailsIs && LoadingIs) {
            // getAlert(clubDetailsIs?.message, "alert-success")
            history.push("/clublist")
            setLoader(false)
            getClubProfile()
        } else if (clubErr && LoadingIs) {
            getAlert(clubErr?.message, "alert-danger")
            setLoader(false)
        }
    }, [_createClub])

    useEffect(() => {
        if (alert.activeIs) {
            setTimeout(() => { 
                $userProfile()                           
                setAlert({ activeIs: false, statusIs: '', msgIs: '' });
            }, 3000);
        }
    }, [alert])

    const getUserProfileRes = (hasClub : boolean) => {
        if(hasClub){
            $getClubDetails()
            setTimeout(() =>{                
                history.push("/clublist")
            }, 1000)                      
        }
    }
    
    useEffect(() => {
        const userProfile = _userProfile.userDataIs;
        if(userProfile?.status === 1){
          getUserProfileRes(userProfile?.data?.hasClub)
        }
      },[_userProfile])

      const getInvitecode = () => {
          props.$getInviteCode()
      }

      useEffect(() =>{
        getInvitecode()
      },[])

      const inviteCode = props?._getInviteCode?.inviteCodeIs;

     

    return (
        <Layout options="fill_Header" uservisible="visible">
            <div className="main-content">
                <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className="container">
                        <div className="row">
                            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                                {alert.activeIs &&
                                    <Alert statusIs={`alert ${alert.statusIs}`} message={alert.msgIs} />
                                }
                                <Formik initialValues={initialValues_createClub}    
                                    validationSchema={validationSchema_createClub}
                                    onSubmit={onSubmit}
                                    validateOnMount
                                    enableReinitialize
                                >
                                    {
                                        formik => (
                                            <Form
                                                className="form w-100"
                                                action="#"
                                                id="kt_account_profile_details_form"
                                            >
                                                <div className="row mb-6">
                                                    <div className="col-lg-8 offset-lg-2 text-center">
                                                        <div
                                                            className="image-input image-input-outline"
                                                            data-kt-image-input="true"
                                                            style={{ backgroundImage: `url("${fileUrl ? fileUrl : EmptyScreen}")` }}
                                                        >
                                                            <div
                                                                className="image-input-wrapper w-125px h-125px"
                                                                style={{ backgroundImage: `url("${fileUrl ? fileUrl : EmptyScreen}")` }}
                                                            />
                                                            <label
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="change"
                                                                data-bs-toggle="tooltip"
                                                                title="Change avatar"
                                                            >
                                                                <i className="bi bi-pencil-fill fs-7" />
                                                                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleClubPic}/>
                                                                <input type="hidden" name="avatar_remove" />
                                                            </label>
                                                            {/* <span
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="cancel"
                                                                data-bs-toggle="tooltip"
                                                                title="Cancel avatar"
                                                                
                                                            >
                                                                <i className="bi bi-x fs-2" />
                                                            </span> */}
                                                            <span
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="remove"
                                                                data-bs-toggle="tooltip"
                                                                title="Remove avatar"
                                                                onClick={handleRemovePic}
                                                            >
                                                                <i className="bi bi-x fs-2" />
                                                            </span>
                                                        </div>
                                                        <label className="col-form-label fw-bolder fs-3 text-capitalize d-block">Add Club photo</label>
                                                    </div>
                                                </div>

                                                <div className="fv-row mb-10">
                                                    <label className="form-label fs-6 fw-bolder text-dark">Club Name</label>
                                                    <Field
                                                        className={`form-control form-control-lg form-control-solid ${(formik.touched.clubName && formik.errors.clubName) && `border border-danger`}`}
                                                        type="text"
                                                        name="clubName"
                                                        id="clubName"
                                                        autoComplete="off"
                                                        placeholder="Enter Club Name"
                                                        onKeyDown={(e:any) => e.target.value.length > 20 ? e.target.value = e.target.value.slice(0,19) : ''
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="clubName"
                                                        render={msg => <FormError message={msg}></FormError>}
                                                    />
                                                </div>

                                                <div className="fv-row mb-3">
                                                    <label className="form-label fs-6 fw-bolder text-dark">Invite Code</label>
                                                    <div className="text-muted">
                                                        We’ll never share your email with anyone else
                                                    </div>
                                                </div>
                                                <FieldArray
                                                    name="emails"
                                                    render={arrayHelpers => (
                                                        <React.Fragment>

                                                            <label className="form-label fs-6 fw-bolder text-dark">Invite Friends</label>
                                                            {
                                                                formik.values.emails.map((obj, index) => (
                                                                    <>
                                                                        <div className="fv-row mb-5" key={index}>
                                                                            <Field
                                                                                className={
                                                                                    `form-control form-control-lg form-control-solid 
                                                                                    ${formik.touched.emails?.length && formik.touched.emails[index] && formik.errors.emails?.length && formik.errors.emails[index] ? `border border-danger` : ''}
                                                                                    `}
                                                                                type="email"
                                                                                placeholder='Email Id'
                                                                                name={`emails.${index}.emailId`}
                                                                                id={`emails.${index}.emailId`}
                                                                                autoComplete="off"
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`emails.${index}.emailId`}
                                                                                render={msg => <FormError message={msg}></FormError>}
                                                                            />
                                                                        </div>
                                                                        {formik.values.emails.length - 1 === index ? <div className="fv-row mb-10 text-end">
                                                                            {formik.values.emails.length > 1 ?
                                                                            <label htmlFor="removelast" className="form-label fs-6 fw-bolder text-dark cursor-pointer mx-4" onClick={() => arrayHelpers.remove(index)}>
                                                                                <i id="removelast" className="bi bi-dash-circle fs-3 me-1 text-dark"></i> Remove last
                                                                            </label> : ''}
                                                                            {(index < 14) && <label className="form-label fs-6 fw-bolder text-dark cursor-pointer" onClick={() => arrayHelpers.push({ emailId: '' })}>
                                                                                <i id="addmore" className="bi bi-plus-circle fs-3 me-1 text-dark"></i> Add more
                                                                            </label>}
                                                                        </div> : ''}
                                                                    </>
                                                                ))
                                                            }

                                                        </React.Fragment>
                                                    )}
                                                />
                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        disabled={!formik.isValid}
                                                        id="kt_sign_in_submit"
                                                        className="btn btn-lg btn-orange mb-5 w-100 px-20"
                                                    >
                                                        <span className="indicator-label fw-bolder">
                                                            {LoadingIs ? (
                                                                <ButtonSpinner color="text-light  spinner-border-sm " />
                                                            ) : (
                                                                `Create Club`
                                                            )}
                                                        </span>
                                                    </button>
                                                </div>
                                            </Form>
                                        )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
const mapState = ({ createClub, userProfile, getClubDetails, getInviteCode }: any) => {
    return {
        _createClub: createClub,
        _userProfile: userProfile,
        _getClubDetails : getClubDetails,
        _getInviteCode: getInviteCode
    };
};
const mapDispatch = (dispatchEvent?: any) => ({
    $createClub: (values?: ICreateClubDetails) => dispatchEvent(createClub(values)),
    $userProfile: () => dispatchEvent(userProfile()),
    $getInviteCode: () => dispatchEvent(getInviteCode()),
    $getClubDetails: () => dispatchEvent(getClubDetails()),
    $clubPic: (values ?: any) => dispatchEvent(clubPic(values))
});
export default connect(
    mapState,
    mapDispatch
)(ClubDetails);
