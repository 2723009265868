import GamePlanner from "./AllTime/GamePlanner/GamePlanner";
import PoundForPound from "./AllTime/PoundForPound/PoundForPound";
import MakingChampionship from "./AllTime/Championship/MakingChampionship";
import GamePlannerEvent from "../Events/GamePlanner";
import GamePlannerMobTableEvent from "../Events/GamePlanner-mob-table";
import PoundForPoundEvent from "../Events/PoundForPound";
import PoundForPoundMobTableEvent from "../Events/PoundForPound-mob-table";
import CorrectBets from "../Events/CorrectBets";
import CorrectBetsMobTable from "../Events/CorrectBets-mob-table";
import Layout from "../../../Common/Layout";
import { customStyles } from "../../Authenticated/Club/AllEventCalculation/Calculation";
import Select from "react-select";
import { useEffect, useState } from "react";
import { getApi } from "../../../Store/api-interface/api-interface";
import GamePlannerMobTable from "./AllTime/GamePlanner/GamePlanner-mob-table";
import PoundForPoundMobTable from "./AllTime/PoundForPound/PoundForPound-mob-table";
import MakingChampionshipMobTable from "./AllTime/Championship/MakingChampionship-mob-table";

const Leaderboard = (props?: any) => {
  const [optionsIs, setOptions] = useState<any>(null)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [allCompletedEvents, setAllCompletedEvents] = useState<any>([])

  const handleChange = (data : any) =>{
    setSelectedOption(data)
  }
  
  useEffect(() => {
    getApi(`/leaderboardChart/getEventswithAllFightsCompleted`).then(res => {
      if(res?.data?.status === 1){
        setAllCompletedEvents(res?.data?.data?.reverse())
      }
    })
  }, [])

  useEffect(() => {
    if(allCompletedEvents && allCompletedEvents?.length){
          setOptions(allCompletedEvents)
          const ObjIs = allCompletedEvents[0]
          const defaultValueIs = { value: ObjIs?.date_event,label: ObjIs?.event_name, ...ObjIs }
          setSelectedOption(defaultValueIs)
    }
  }, [allCompletedEvents])

  const optionNew = optionsIs?.map((obj: any) => {
    let data = { value: obj?.date_event, label: obj?.event_name,  ...obj };
    return data;
  });
  
  return (

    <Layout options="fill_Header" uservisible="visible">
      <section className="main-content">
        <div className="container py-10 leaderboard_table_wrapper">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="d-flex align-items-center justify-content-between pt-10 leaderboard-header">
                <div>
                  <h2 className="text-gray-900 fs-2x fw-bolder mb-0">
                    Leaderboard
                  </h2>
                </div>
                <div className="leaderboard_tabs">
                  <div className="card-xl-stretch right_tabs">
                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 px-2">
                      <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                        <ul className="nav">
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-color-muted btn-active btn-active-light-primary active fw-bold fs-6 px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_3_tab_1"
                            >
                              All Time
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 fs-6"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_3_tab_2"
                            >
                              Events
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/*end::Header*/}
                  </div>
                  {/*end::Tables Widget 4*/}
                </div>
              </div>
            </div>
            <div className="col-sm-12 px-0">
              {/*begin::Body*/}
              <div className="card-body py-3 px-2">
                <div className="tab-content">
                  {/*begin::Tap pane*/}
                  <div
                    className="tab-pane fade show active"
                    id="kt_table_widget_3_tab_1"
                  >
                    {/*begin::Tables Widget 4*/}
                    <div className="card card-xl-stretch p-5">
                      {/*begin::Header*/}
                      <div className="d-flex align-items-center justify-content-between flex-wrap border-0 px-2">
                        <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                          <ul className="nav tabButtons">
                            <li className="nav-item">
                              <a
                                className="nav-link buttonMarginPadding btn btn-light btn-color-muted btn-active btn-active-light-primary active fw-bold mb-2 px-4 me-1 mr-3"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_4_tab_1"
                              >
                                ROI
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link buttonMarginPadding btn btn-light btn-color-muted btn-active btn-active-light-primary fw-bold mb-2 px-4 me-1 ml-3"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_4_tab_2"
                              >
                                Pick Win %
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link buttonMarginPadding btn btn-light btn-color-muted btn-active btn-active-light-primary fw-bold mb-2 px-4 me-1 ml-3"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_4_tab_3"
                              >
                                Win Streak
                              </a>
                            </li>
                            <li className="text-muted p-3">* Minimum of 15 points to qualify</li>
                          </ul>
                        </div>
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="card-body py-3 px-2">
                        <div className="tab-content table_wrapper">
                          {/*begin::Tap pane*/}
                          <div
                            className="tab-pane fade show active"
                            id="kt_table_widget_4_tab_1"
                          >
                            <div className="d-none d-md-inline">
                             <GamePlanner />
                            </div>
                            <div className="d-md-none d-inline">
                              <GamePlannerMobTable/>
                            </div>
                          </div>
                          {/*end::Tap pane*/}
                          {/*begin::Tap pane*/}
                          <div
                            className="tab-pane fade"
                            id="kt_table_widget_4_tab_2"
                          >
                            <div className="d-none d-md-inline"><PoundForPound /></div>
                            <div className="d-md-none d-inline"><PoundForPoundMobTable/></div>
                            
                          </div>
                          {/*end::Tap pane*/}
                          {/*begin::Tap pane*/}
                          <div
                            className="tab-pane fade"
                            id="kt_table_widget_4_tab_3"
                          >
                            <div className="d-none d-md-inline"><MakingChampionship /></div>
                            <div className="d-md-none d-inline"><MakingChampionshipMobTable/></div>
                          </div>
                          {/*end::Tap pane*/}
                        </div>
                      </div>
                      {/*end::Body*/}
                    </div>
                    {/*end::Tables Widget 4*/}
                  </div>
                  {/*end::Tap pane*/}
                  {/*begin::Tap pane*/}
                  <div className="tab-pane fade" id="kt_table_widget_3_tab_2">
                    {/*begin::Tables Widget 4*/}
                    <div className="card card-xl-stretch p-5">
                      {/*begin::Header*/}
                      <div className="d-flex align-items-center justify-content-between flex-wrap border-0 px-2">
                        <div className="card-toolbar order-2 order-sm-0 mob-w-100 d-flex w-100 flex-wrap justify-space-between">
                          <ul className="nav tabButtons">
                            <li className="nav-item">
                              <a
                                className="nav-link btn buttonMarginPadding btn-light btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_1"
                              >
                                ROI
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link btn buttonMarginPadding btn-light btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_2"
                              >
                                Pick Win %
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link btn buttonMarginPadding btn-light btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_3"
                              >
                                Correct Bets
                              </a>
                            </li>
                            <li className="text-muted p-3">* Minimum of 3 points to qualify</li>
                          </ul>
                          <span style={{ right: "11px", marginLeft:"auto"}} className="flex-wrap text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center dropDownSelect" >
                              {/* <span>{selectedOption?.event_name?.split(":")[0]}</span>  */}
                              <span
                                style={{ minWidth: 400 }}
                                className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3"
                              >
                               <Select options={optionNew} onChange={handleChange} value={selectedOption} menuPortalTarget={document.body} styles={customStyles}/>
                              </span>
                            </span>
                        </div>
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="card-body py-3 px-2">
                        <div className="tab-content table_wrapper">
                          {/*begin::Tap pane*/}
                          <div
                            className="tab-pane fade show active"
                            id="kt_table_widget_5_tab_1"
                          >
                            <div className="d-none d-md-inline"><GamePlannerEvent selectedOption={selectedOption} /></div> 
                            <div className="d-md-none d-inline"><GamePlannerMobTableEvent selectOption={selectedOption} /></div>
                          </div>
                          {/*end::Tap pane*/}
                          {/*begin::Tap pane*/}
                          <div
                            className="tab-pane fade"
                            id="kt_table_widget_5_tab_2"
                          >
                            <div className="d-none d-md-inline"><PoundForPoundEvent selectedOption={selectedOption} /></div>
                            <div className="d-md-none d-inline"><PoundForPoundMobTableEvent selectedOption={selectedOption} /></div>
                            
                          </div>

                          <div
                            className="tab-pane fade"
                            id="kt_table_widget_5_tab_3"
                          >
                            <div className="d-none d-md-inline"><CorrectBets selectedOption={selectedOption} /></div>
                             <div className="d-md-none d-inline"><CorrectBetsMobTable selectedOption={selectedOption} /></div>
                          </div>
                          {/*end::Tap pane*/}
                        </div>
                      </div>
                      {/*end::Body*/}
                    </div>
                    {/*end::Tables Widget 4*/}
                  </div>
                  {/*end::Tap pane*/}
                </div>
              </div>
              {/*end::Body*/}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};



export default Leaderboard
