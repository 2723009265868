import * as yup from "yup";
export const initielValues_signUp = {
  email: "",
  password: "",
  inviteCode: "",
  screenName: "",
  firstName:"",
  lastName:"",
  agree: []
};

export const validationSchema_signUp = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    .required("Required"),
  firstName:yup
  .string()
  .required('Required'),
  lastName:yup
  .string()
  .required('Required'),
  screenName: yup
    .string()
    .required("Required")
    .matches(/^\S*$/, 'Screen name cannot have whitespace.')
    .matches(/^.{8,20}$/, "Should be 8 to 20 character long."),
  password: yup
    .string()
    .min(8, "Minimum 8 Characters Needed")
    .matches(
      /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/,
      "Need at least one special character"
    )
    .matches(/^(?=.{0,100}$)\D*\d/, "Need at least a number")
    .matches(/[a-z]/i, "Need at least a character") 
    .required("Required"),
  inviteCode: yup
    .string()
    .length(6, 'This field has to be exactly 6 characters!'),

    agree: yup.array().length(1, "You must agree with the our terms and privacy")
});
