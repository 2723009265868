import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Spinner from "../../../Common/Spinner/Spinner"
import { eventGamePlanner } from "../../../Store/action/Leaderboard.action"
import { ImageUrl } from "../../../Utils/BaseUrl"
import { getSorting, stringNameLength } from "../Club/AllEventCalculation/Calculation"
import LeftRightScroll from "../Club/LeftRightScroll"


const GamePlanner = (props?: any) => {

    const [data, setData] = useState([])
    const [eventName, setEventName] = useState("")
    const [activeSortName , setActiveSort] = useState<string>("roi")
    const [sortingIs , setSorting] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>

    useEffect(() =>{
        if(props?.selectedOption){
            setIsLoading(true)
            const eventName = props?.selectedOption?.event_name
            const eventDate = props?.selectedOption?.date_event
            props?.$eventGamePlanner(eventName, eventDate)
        }
    },[props?.selectedOption])

    useEffect(() =>{
        if(props._eventGamePlanner && props._eventGamePlanner.results && props._eventGamePlanner.results.length){
            setIsLoading(false)
            setData(props._eventGamePlanner.results)
            setEventName(props._eventGamePlanner.latestEvent.event_name)
        }
    }, [props._eventGamePlanner])


    return (
        <>
        <div ref={slideRef} className="table-responsive h-500">
            <span className="fs-6 fw-bolder text-dark mt-6 d-inline-block" style={{position: "sticky", left: 0}}>{eventName}</span>
            {   
                isLoading
                ?
                <Spinner color="text-dark  spinner-border-sm text-center" />
                :
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_subscriptions_table_one" role="grid" >
                    {/*begin::Table head*/}
                    <thead className="sticky-top" style={{background:"white"}}>
                    {/*begin::Table row*/}
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-1" role="row" > 
                    <th 
                            className={`textLeft min-w-150px sorting ${activeSortName === "fullName" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}
                            onClick={() => getSorting("fullName", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Player Name
                        </th>
                        
                        <th 
                            className={`sorting ${activeSortName === "roi" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("roi", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            ROI %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "totalMargin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            // onClick={() => getSorting("totalMargin", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Margin
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "points" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("points", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Point Total
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "betWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("betWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Bet Win %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "totalParleyWinPercentage" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            // onClick={() => getSorting("totalParleyWinPercentage", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Parlay win
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "secondLastBetWin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            // onClick={() => getSorting("secondLastBetWin", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last Event win %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "lastEventParlayWin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            // onClick={() => getSorting("lastEventParlayWin", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last Event Parlay Win
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "last5BetWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("last5BetWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last 5 Events Win %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "last5ParleyWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("last5ParleyWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last 5 Events Parlay %
                        </th>
                    </tr>
                    {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    {
                        data && data.length > 0
                        ?
                        data.map((obj: any, index: number) => (
                            <tbody className="text-gray-600 fw-bold" key={obj.id}>
                                <tr className="bg-table-row-1">  
                                    <td className="text-gray-800 text-start " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                                        {
                                            obj.screenName
                                            ?
                                            <Link to={`/player/${obj.screenName}`} className="text-dark">
                                                <div className="row">
                                                    { 
                                                        obj?.profilePic && !obj.profilePic.includes("-blob")
                                                        ?
                                                        <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                            <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.firstName ? obj.firstName : ""}/>
                                                        </div> 
                                                        :
                                                        <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                            <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.firstName ? obj.firstName : ""}>
                                                                {obj?.firstName ? obj.firstName[0].toUpperCase() : ""}
                                                            </span>
                                                        </div>
                                                    }
                                                    <div className="col-9">
                                                        {index+1}.&nbsp;{obj?.firstName + " " + obj?.lastName}
                                                        {
                                                            obj?.status 
                                                            ? 
                                                            <span className="text-muted fw-bold d-block fs-8" data-toggle="tooltip" data-placement="bottom" title={obj?.status?.length > 25 ? obj?.status : ""}>
                                                                {stringNameLength(obj?.status, "", 25, 25)}
                                                            </span> 
                                                            :
                                                            "" 
                                                        }
                                                        <span className="text-muted fw-bold d-block fs-8">{obj.weightClass}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            :
                                            <div className="row">
                                                { 
                                                    obj?.profilePic && !obj.profilePic.includes("-blob")
                                                    ?
                                                    <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                        <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.firstName ? obj.firstName : ""}/>
                                                    </div> 
                                                    :
                                                    <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                        <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.firstName ? obj.firstName : ""}>
                                                            {obj?.firstName ? obj.firstName[0].toUpperCase() : ""}
                                                        </span>
                                                    </div>
                                                }
                                                <div className="col-9">
                                                    {index+1}.&nbsp;{obj?.firstName + " " + obj?.lastName}
                                                    {
                                                        obj?.status 
                                                        ? 
                                                        <span className="text-muted fw-bold d-block fs-8" data-toggle="tooltip" data-placement="bottom" title={obj?.status?.length > 25 ? obj?.status : ""}>
                                                            {stringNameLength(obj?.status, "", 25, 25)}
                                                        </span> 
                                                        :
                                                        "" 
                                                    }
                                                    <span className="text-muted fw-bold d-block fs-8">{obj.weightClass}</span>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td>{obj.roi + (!isNaN(obj.roi) ? "%" : "")}</td>
                                    <td>{!isNaN(obj.betWin + obj.parleyWin) ? 
                                      new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(obj.betWin + obj.parleyWin) : ""}</td>
                                    <td>{obj.points}</td> 
                                    <td>{obj.betWinPercent + "%"}</td>
                                    <td>{obj.isParleyWin == false ? "No": "Yes"}</td> 
                                    <td>{obj.secondLastBetWin !== undefined ? obj.secondLastBetWin+"%" : "-" }</td> 
                                    <td>{String(obj.secondLastParleyWin!== undefined ? obj.secondLastParleyWin == false ? "No" : "Yes" : "-" )}</td> 
                                    <td>{obj.last5BetWinPercent + "%"}</td>
                                    <td>{obj.last5ParleyWinPercent==="-" ? "-" : obj.last5ParleyWinPercent+"%"}</td>
                                </tr>  
                            </tbody>
                        )) 
                        :
                        ""
                    } 
                    {/*end::Table body*/}
                </table>
            }
        
    </div> 
    <LeftRightScroll slideRef={slideRef}/>
    </>
    )

}

const mapState = ({ leaderboardReducer }: any) => {
    return {
        _eventGamePlanner: leaderboardReducer.eventGamePlanner
    };
};

const mapDispatch = (dispatchEvent?: any) => ({
    $eventGamePlanner: (eventName:any, eventDate:any) => dispatchEvent(eventGamePlanner(eventName, eventDate)),
});

export default connect(mapState, mapDispatch)(GamePlanner)
