import React from "react"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Spinner from "../../../Common/Spinner/Spinner"
import {eventPoundForPound } from "../../../Store/action/Leaderboard.action"
import { ImageUrl } from "../../../Utils/BaseUrl"



const PoundForPoundMob = (props?: any) => {

    const [data, setData] = useState([])
    // const [eventName, setEventName] = useState("")
    // const [activeSortName , setActiveSort] = useState<string>("betWinPercent")
    // const [sortingIs , setSorting] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>
    
    useEffect(() =>{
       
        if(props?.selectedOption){
            setIsLoading(true)
            // const eventName = props?.selectedOption?.event_name
            // const eventDate = props?.selectedOption?.date_event
            // props?.$eventPoundForPound(eventName, eventDate)
        }
    },[props?.selectedOption])

    useEffect(() =>{
        if(props._eventPoundForPound && props._eventPoundForPound.results && props._eventPoundForPound.results.length){
            setIsLoading(false)
            setData(props._eventPoundForPound.results)
            // setEventName(props._eventPoundForPound.latestEvent.event_name)
            // if(props?.correctBets){
            //     getSorting("betWinCount", props._eventPoundForPound.results, setData, sortingIs, setSorting, activeSortName, setActiveSort)
            // }
        }
    }, [props._eventPoundForPound])

    return (
        <>   
         <div className="container">
           <div className="row">
            <table>
            {
                        data && data.length > 0
                        ?
                        data.map((obj: any, index: number) => (
                            <tbody className="text-gray-600 fw-bold" key={obj.id}>
                                <tr className="bg-table-row-1">  
                                    <td className="text-gray-800 text-start " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                                        {
                                            obj.screenName
                                            ?
                                            <Link to={`/player/${obj.screenName}`} className="text-dark">
                                                <div className="row mt-3">
                                                    { 
                                                        obj?.profilePic && !obj.profilePic.includes("-blob")
                                                        ?
                                                        <div className="col-2 symbol symbol-35px symbol-circle">
                                                            <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.firstName ? obj.firstName : ""}/>
                                                        </div> 
                                                        :
                                                        <div className="col-2 symbol symbol-35px symbol-circle">
                                                            <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.firstName ? obj.firstName : ""}>
                                                                {obj?.firstName ? obj.firstName[0].toUpperCase() : ""}
                                                            </span>
                                                        </div>
                                                    }
                                                    <div className="col-5">
                                                    <span className="leaderPosition">{index+1}</span>{obj?.firstName + " " + obj?.lastName}
                                                        <span className="text-muted fw-bold d-block fs-base">Margin</span>
                                                    </div>
                                                    <div className="col-5 mt-8 table-ml text-muted">
                                                    {!isNaN(obj.betWin + obj.parleyWin) ? 
                                                     new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(obj.betWin + obj.parleyWin) : ""}

                                                    </div>
                                                </div>
                                            </Link>
                                            :""
                                        }
                                </td>
                        </tr>
                        <tr>
                            <td> 
                                <div className="row mt-3">
                                     <div className="col-8 table-ml1">
                                        ROI %
                                     </div>
                                     <div className="col-4 table-ml">
                                      {obj.roi + (!isNaN(obj.roi) ? "%" : "")}
                                     </div>
                                    </div>
                            </td> 
                        </tr>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-8 table-ml1">Bet Win %</div>
                                    <div className="col-4 table-ml">{obj.betWinPercent + "%"}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <div className="row">
                                        <div className="col-8 table-ml1">Parlay win</div>
                                        <div className="col-4 table-ml">{obj.isParleyWin == false ? "No": "Yes"}</div>
                                    </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-8 table-ml1">Last Event win %</div>
                                    <div className="col-4 table-ml">{obj.secondLastBetWin !== undefined ? obj.secondLastBetWin+"%" : "-" }</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-8 table-ml1">
                                    Last Event Parlay Win
                                </div>
                                <div className=" col-4 table-ml">
                                {String(obj.secondLastParleyWin!== undefined ? obj.secondLastParleyWin == false ? "No" : "Yes" : "-" )}
                                </div>
                            </div>
                        </tr>
                    </tbody>
                )) 
                :
                <Spinner />               
            } 
            </table>
        </div>
     </div>
    </>
    )
}

const mapState = ({ leaderboardReducer }: any) => {
    return {
        _eventPoundForPound: leaderboardReducer.eventPoundForPound
    };
};

const mapDispatch = (dispatchEvent?: any) => ({
    $eventPoundForPound: (eventName:any, eventDate:any) => dispatchEvent(eventPoundForPound(eventName,eventDate)),
});

export default connect(mapState, mapDispatch)(PoundForPoundMob)






