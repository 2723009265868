import { takeLatest, all } from "redux-saga/effects";

import {
  LOGIN_DATA,
  SIGNUP_DATA,
  FORGOT_PWD,
  RESET_PWD,
  USER_PROFILE,
  COUNTRY_DETAILS,
  UPDATE_USER,
  CREATE_CLUB,
  CHANGE_PWD,
  GET_CLUB_DETAILS,
  INVITE_FRND,
  JOIN_CLUB,
  PROFILE_PIC,
  JOINING_CLUB_DETAILS,
  CLUB_PIC,
  SHEDULE_BY_YEAR,
  TEAM_DATA_BY_DATE,
  JOIN_EVENT,
  CLUB_BY_ID,
  PREVIOUS_SHEDULE_BY_YEAR,
  PREVOIUS_TEAM_DATA,
  CLUB_MEMBER_LIST,
  REMOVE_CLUB_MEMBER,
  LEAVE_CLUB,
  EVENT_PARLAY_TEAM_BY_DATE,
  JOIN_PARLAY_EVENT,
  SUMMARY_DATA,
  PROFILE_BETS,
  USER_BET_BY_DATE,
  USER_SUMMARY_BY_DATE,
  USER_PARLAY_BY_DATE,
  USER_PREVIOUS_BET_BY_DATE,
  USER_PREVIOUS_PARLAY_BY_DATE,
  CLUB_PREVIOUS_PARLAY_BY_DATE,
  GET_INVITE_CODE,
  CLUB_PRE_SUMMARY,
  PROFILE_PRE_SUMMARY,
  STATS_EVENTS_SCORE,
  CLUB_STANDINGS,
  ALLTIME_GAME_PLANNER,
  ALLTIME_POUND_FOR_POUND,
  ALLTIME_MAKING_CHAMPIONSHIP_RUN,
  EVENT_GAME_PLANNER,
  EVENT_POUND_FOR_POUND,
  CHAT_GET_ALL_MESSAGE,
  CHAT_NEW_MESSAGE,
  PUBLIC_STATS_EVENTS_SCORE,
  EVENT_CORRECT_BETS,
} from "../type.action";
import {
  onloginData,
  onSignUpData,
  onforgetPwd,
  onResetPwd,
} from "./Login.saga";
import {
  onUserProfile,
  onGetCountry,
  onUpdateUser,
  onCreateClub,
  onChangePwd,
  onGetClubDetails,
  onInviteFrnd,
  onJoinClub,
  onProfilePic,
  onJoiningDetails,
  onclubPic
} from "./UserInfo.saga";
import {
  onScheduleByYear,
  onTeamData,
  onJoinEvent,
  onClubById,
  onPreviousSchedule,
  onPreviousTeamData,
  onClubMemberList,
  onRemoveClubMember,
  onLeaveClub,
  onParlayTeamEvent,
  onJoinParlay,
  onSummaryData,
  onProfileBets,
  onUserEvent,
  onUserSummary,
  onUserParlay,
  onUserPrebet,
  onUserPreviousParlay,
  onClubPreParlay,
  onGetInviteCode,
  onClubPreSummary,
  onProfilePreSummary,
  onStatsEventsScore,
  onClubStandings,
  onPublicStatsEventsScore
} from "./Events.saga";
import { 
  onGetAllTimeGamePlanner, 
  onGetAllTimePoundForPound, 
  onGetAllTimeMakingChampionshipRun,
  onGetEventGamePlanner, 
  onGetEventPoundForPound,
  onGetEventCorrectBets, 
} from "./Leaderboard.saga"
import { onGetAllMessages, onPostNewMessage } from "./Chat.saga"
function* sagas() {
  
  yield all([
    takeLatest(LOGIN_DATA, onloginData),
    takeLatest(SIGNUP_DATA, onSignUpData),
    takeLatest(FORGOT_PWD, onforgetPwd),
    takeLatest(RESET_PWD, onResetPwd),
    takeLatest(USER_PROFILE, onUserProfile),
    takeLatest(COUNTRY_DETAILS, onGetCountry),
    takeLatest(UPDATE_USER, onUpdateUser),
    takeLatest(CREATE_CLUB, onCreateClub),
    takeLatest(CHANGE_PWD, onChangePwd),
    takeLatest(GET_CLUB_DETAILS, onGetClubDetails),
    takeLatest(INVITE_FRND, onInviteFrnd),
    takeLatest(JOIN_CLUB, onJoinClub),
    takeLatest(PROFILE_PIC, onProfilePic),  
    takeLatest(JOINING_CLUB_DETAILS, onJoiningDetails),
    takeLatest(CLUB_PIC, onclubPic),
    takeLatest(SHEDULE_BY_YEAR, onScheduleByYear),
    takeLatest(TEAM_DATA_BY_DATE, onTeamData),
    takeLatest(JOIN_EVENT, onJoinEvent),
    takeLatest(CLUB_BY_ID, onClubById),
    takeLatest(PREVIOUS_SHEDULE_BY_YEAR, onPreviousSchedule),
    takeLatest(PREVOIUS_TEAM_DATA, onPreviousTeamData),
    takeLatest(CLUB_MEMBER_LIST, onClubMemberList),
    takeLatest(REMOVE_CLUB_MEMBER, onRemoveClubMember),
    takeLatest(LEAVE_CLUB, onLeaveClub),
    takeLatest(EVENT_PARLAY_TEAM_BY_DATE, onParlayTeamEvent),
    takeLatest(JOIN_PARLAY_EVENT, onJoinParlay),
    takeLatest(SUMMARY_DATA, onSummaryData),
    takeLatest(PROFILE_BETS, onProfileBets),
    takeLatest(USER_BET_BY_DATE, onUserEvent),
    takeLatest(USER_SUMMARY_BY_DATE, onUserSummary),
    takeLatest(USER_PARLAY_BY_DATE, onUserParlay),
    takeLatest(USER_PREVIOUS_BET_BY_DATE, onUserPrebet),
    takeLatest(USER_PREVIOUS_PARLAY_BY_DATE, onUserPreviousParlay),
    takeLatest(CLUB_PREVIOUS_PARLAY_BY_DATE, onClubPreParlay),
    takeLatest(GET_INVITE_CODE, onGetInviteCode),
    takeLatest(CLUB_PRE_SUMMARY, onClubPreSummary),
    takeLatest(PROFILE_PRE_SUMMARY, onProfilePreSummary),
    takeLatest(STATS_EVENTS_SCORE, onStatsEventsScore),
    takeLatest(PUBLIC_STATS_EVENTS_SCORE, onPublicStatsEventsScore),
    takeLatest(CLUB_STANDINGS, onClubStandings),
    takeLatest(ALLTIME_GAME_PLANNER, onGetAllTimeGamePlanner),
    takeLatest(ALLTIME_POUND_FOR_POUND, onGetAllTimePoundForPound),
    takeLatest(ALLTIME_MAKING_CHAMPIONSHIP_RUN, onGetAllTimeMakingChampionshipRun),
    takeLatest(EVENT_GAME_PLANNER, onGetEventGamePlanner),
    takeLatest(EVENT_POUND_FOR_POUND, onGetEventPoundForPound),
    takeLatest(EVENT_CORRECT_BETS, onGetEventCorrectBets),
    takeLatest(CHAT_GET_ALL_MESSAGE, onGetAllMessages),
    takeLatest(CHAT_NEW_MESSAGE, onPostNewMessage)
  ]);
}

export default sagas;
