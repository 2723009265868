// import Dummy from '../../../../assets/images/avatars/150-26.jpg';
import { connect } from "react-redux";
import { userProfile } from "../../../../Store/action/UserInfo.action";
import EmptyImage from "../../../../assets/images/avatars/blank.png";
import { ImageUrl } from "../../../../Utils/BaseUrl";
import { addStickyToTHead, removeStickyToTHead, stringNameLength } from "../../Club/AllEventCalculation/Calculation";
import { useEffect, useState } from "react";
import FirstPlace from "../../../../assets/images/Badges/FirstPlace.png";
import Top5 from "../../../../assets/images/Badges/Top5.png";
import Top10 from "../../../../assets/images/Badges/Top10.png";
import Top25 from "../../../../assets/images/Badges/Top25.png";
import { postApi } from "../../../../Store/api-interface/api-interface";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import Spinner, { ButtonSpinner } from "../../../../Common/Spinner/Spinner";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    width: "100%",
    maxHeight: "70vh",
  },
};

const ProfileCard = (props?: any) => {
  const history = useHistory()
  const redirectTo = history.location.pathname

  const [user, setUser] = useState<any>({});
  const [followed, setFollowed] = useState<boolean>(false);
  const [followersModal, setFollowersModal] = useState<boolean>(false);
  const [followingModal, setFollowingModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [userRankings, setUserRankings] = useState<any>({});
  const [assignedBadges, setAssignedBadges] = useState<any>({
    firstRank: {},
    top5: [],
    top10: [],
    top25: [],
  });

  const badgeStyle = {
    bottom: 0,
    borderRadius: "20px",
  };
  useEffect(() => {
    if (props?.isPublicProfile && props?.user) {
      setUser(props?.user);
    } else {
      setUser(props?._userProfile?.userDataIs?.data);
      props?.setMyProfileUser(props?._userProfile?.userDataIs?.data);
    }
  }, [props]);

  useEffect(() => {
   
    if (user?.playerEventStats && user?.playerEventStats.length) {
      const rankData = user?.playerEventStats;
      setAssignedBadges({
        firstRank: rankData?.filter((el: any) => el.globalRankingOnThisEvent === 1) || [],
        top5: rankData?.filter((el: any) => el.globalRankingOnThisEvent > 1 && el.globalRankingOnThisEvent < 6) || [],
        top10: rankData?.filter((el: any) => el.globalRankingOnThisEvent > 5 && el.globalRankingOnThisEvent < 11) || [],
        top25: rankData?.filter((el: any) => el.globalRankingOnThisEvent > 10 && el.globalRankingOnThisEvent < 26) || [],
      });
    }
  }, [user]);

  const isFollowed = () => {
   postApi("/follow/isFollowed", { data: { followTo: user?.id, followBy:props?._userProfile?.userDataIs?.data?.id} }).then((res) => {
      if (res?.data?.status === 1) {
        setFollowed(true);  
      } else if (res?.data?.status === 0) {
        setFollowed(false);
      }
    });
  };

  const unfollowRequest = async (type:any, id:any, email:any, firstName:any) =>{
    setLoader(true)
    await postApi("/follow/followUser", { data: { followTo: id, type, screenName:user?.screenName,userEmail:email,firstName} }).then((res) => {
      setLoader(false);
      props?.$userProfile();
    })
  }
  useEffect(() => {
    props?.isPublicProfile && isFollowed();
  }, [user]);

  const followRequest = async (type: any) => {
       setLoader(true);
    await postApi("/follow/followUser", { data: { followTo: user?.id, followBy: props?._userProfile?.userDataIs?.data?.id, type, screenName:props?._userProfile?.userDataIs?.data?.screenName, userEmail:user?.email,firstName:user?.firstName} }).then((res) => {
      setLoader(false);
      isFollowed();
    })
  };
  const followMessage = () =>[
    history.push(`/login?r=${redirectTo}`)
  ]

  const getBadges = () => {
    return (
      <>
      <div className="d-flex row justify-content-center">
        {assignedBadges?.firstRank?.length ? (
          <span className="d-flex flex-column align-items-center position-relative justify-content-center col-3 text-center">
            <img
              className="badgeImg w-100"
              src={FirstPlace}
              data-toggle="tooltip"
              data-placement="bottom"
              title={"First Rank" + assignedBadges?.firstRank.map((e: any) => `\n${e.event_name}`)}
              alt="First Rank"
            />
            {assignedBadges?.firstRank?.length > 1 ? <span className="badgeCount position-absolute">{assignedBadges?.firstRank?.length}</span> : ""}
          </span>
        ) : (
          ""
        )}
        {assignedBadges?.top5?.length ? (
          <span className="d-flex flex-column align-items-center position-relative justify-content-center col-3 text-center">
            <img className="badgeImg w-100" src={Top5} data-toggle="tooltip" data-placement="bottom" title={"Top 5" + assignedBadges?.top5.map((e: any) => `\n${e.event_name}`)} alt="Top 5" />
            {assignedBadges?.top5?.length > 1 ? <span className="badgeCount position-absolute">{assignedBadges?.top5?.length}</span> : ""}
          </span>
        ) : (
          ""
        )}
        {assignedBadges?.top10?.length ? (
          <span className="d-flex flex-column align-items-center position-relative justify-content-center col-3 text-center">
            <img className="badgeImg w-100" src={Top10} data-toggle="tooltip" data-placement="bottom" title={"Top 10" + assignedBadges?.top10.map((e: any) => `\n${e.event_name}`)} alt="Top 10" />
            {assignedBadges?.top10?.length > 1 ? <span className="badgeCount position-absolute">{assignedBadges?.top10?.length}</span> : ""}
          </span>
        ) : (
          ""
        )}
        {assignedBadges?.top25?.length ? (
          <span className="d-flex flex-column align-items-center position-relative justify-content-center col-3 text-center">
            <img className="badgeImg w-100" src={Top25} data-toggle="tooltip" data-placement="bottom" title={"Top 25" + assignedBadges?.top25.map((e: any) => `\n${e.event_name}`)} alt="Top 25" />
            {assignedBadges?.top25?.length > 1 ? <span className="badgeCount position-absolute">{assignedBadges?.top25?.length}</span> : ""}
          </span>
        ) : (
          ""
        )}
      </div>
      </>
    );
  };

  const openFollwerModal = (obj: any) => {
    setFollowersModal(true);
    removeStickyToTHead();
  };
  const openFollowingModal = (obj: any) => {
    setFollowingModal(true);
    removeStickyToTHead();
  };

  const closeModal = () => {
    setFollowersModal(false);
    setFollowingModal(false);
    addStickyToTHead();
  };

  return (
    <section className="profile_wrapper mb-lg-10 mb-sm-10 mb-0">
      <div className="card">
        <div className="card-body d-flex flex-center flex-column pt-12 p-9">
          <div className="symbol symbol-100px symbol-circle mb-5">
            {/* <img src={user?.profilePic ? ImageUrl + user?.profilePic : EmptyImage} alt="..." /> */}
            {user?.profilePic?.includes("-blob") && user?.firstName ? (
              <span className="symbol symbol-30px symbol-circle ">
                <span className="symbol-label bg-secondary text-black 40px" style={{ fontSize: "70px", textAlign: "center" }}>
                  {user?.firstName?.charAt(0)}
                </span>
              </span>
            ) : (
              <img src={user?.profilePic ? ImageUrl + user?.profilePic : EmptyImage} alt="metronic" />
            )}
            <div className="bg-success position-absolute border border-4 border-white h-20px w-20px rounded-circle translate-middle start-100 top-100 ms-n7 mt-n2" />
          </div>
          <span className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{stringNameLength(user?.firstName ? user?.firstName : "", user?.lastName ? user?.lastName : "", 20, 20)}</span>
          <span className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{user?.screenName ? user?.screenName : ""}</span>
          <div className="fw-bold text-center mb-2 mt-2" style={{ color: "#707070" }}>
            {user?.status ? `" ` + user?.status + ` "` : ""}
          </div>
          {
            props?.isPublicProfile 
            ?
              (followed
              ?
              <button onClick={() => followRequest(0)} className="btn btn-sm btn-dark fw-bold w-25">
                {loader ? <div className="spinner-border spinner-border-sm" role="status" /> : "Unfollow"}
              </button>
              :
                <button 
                  onClick={ props?._userProfile?.userDataIs?.data?.id  ? () => followRequest(1) : followMessage} 
                  className="btn btn-sm btn-orange fw-bold w-25">
                  {loader ? <div className="spinner-border spinner-border-sm" role="status" /> : "Follow"}
                </button>
              )
            :
            <div className="followersCount">
              <span className="fs-5 text-gray-800 text-hover-primary fw-bolder mb-0 cursor-pointer" onClick={openFollwerModal}>
                Followers:{user?.userFollowers?.length}{" "}
              </span>
              &nbsp;&nbsp;
              <span className="fs-5 text-gray-800 text-hover-primary fw-bolder mb-0 cursor-pointer" onClick={openFollowingModal}>
                Following:{user?.userFollowings?.length}
              </span>
            </div>
          }
          {/* <span className="btn btn-orange fw-bolder" onClick = {openModal}>
                     Followers
                    </span>&nbsp;&nbsp; */}
          <div className="fw-bold text-gray-400 mb-2 mt-2">
            {getBadges()}
            {/* {user?.email}  */}
            {/* {getWeightClass(props?.statsData?.weightClass)?.image ?  <img style={{width:32}} src={`${getWeightClass(props?.statsData?.weightClass)?.image}`} alt="..."/>  :  ""  }                   */}
          </div>
          <Modal isOpen={followersModal} style={customStyles} onRequestClose={closeModal} contentLabel="Example Modal">
            <div className="text-center mb-6">
              <div className=" fs-6 fw-bold text-gray-900 text-hover-dark">Followers</div>
              <hr />
              {
              loader 
              ? 
              <div className="d-flex align-items-center justify-content-center">
                <div className="m-1 spinner-border-sm spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
              </div>
              : 
              ""
            }
            </div>
           {user?.userFollowers?.map((obj: any, index: number) => (
              <>
              <div className="row my-3">
                <div className="d-flex justify-content-between">
                <Link to={`/player/${obj.screenName}`} className="text-dark">
                  {index + 1}.&nbsp;
                  {obj?.profilePic && !obj.profilePic.includes("-blob") ? (
                    <div className="symbol symbol-35px symbol-circle mx-3">
                      <img alt="Pic" src={ImageUrl + obj?.profilePic} title={obj?.firstName ? obj.firstName : ""} />
                    </div>
                  ) : (
                    <div className="symbol symbol-35px symbol-circle mx-3">
                      <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.firstName ? obj.firstName : ""}>
                        {obj?.firstName ? obj.firstName[0].toUpperCase() : ""}
                      </span>
                    </div>
                  )}
                  {(obj?.firstName ? obj?.firstName : "") + " " + (obj?.lastName ? obj?.lastName : "")}
                </Link>
                {obj?.isMyFollowerFollowedByMe ? 
                 <button className="btn btn-sm btn-dark fw-bold" disabled={loader} onClick={() => unfollowRequest(0, obj?.id,  obj?.email, obj.firstName)} >Unfollow</button>
                 :
                 <button className="btn btn-sm btn-orange fw-bold" disabled={loader} onClick={() => unfollowRequest(1, obj?.id, obj?.email, obj.firstName)} >follow</button>
                }
                </div>
                
              </div>
              </>
            ))}
          </Modal>

          <Modal isOpen={followingModal} style={customStyles} onRequestClose={closeModal} contentLabel="Example Modal">
            <div className="text-center mb-6">
              <h3>Following</h3>
              <hr />
            </div>
            {
              loader 
              ? 
              <div className="d-flex align-items-center justify-content-center">
                <div className="m-1 spinner-border-sm spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
              </div>
              : 
              ""
            }
            {user?.userFollowings?.map((obj: any, index: number) => (
              <>
              <div className="row my-3" >
                <div className="d-flex justify-content-between">
                  <Link to={`/player/${obj.screenName}`} className="text-dark">
                    {index + 1}.&nbsp;
                    {obj?.profilePic && !obj.profilePic.includes("-blob") ? (
                      <div className="symbol symbol-35px symbol-circle mx-3">
                        <img alt="Pic" src={ImageUrl + obj?.profilePic} title={obj?.firstName ? obj.firstName : ""} />
                      </div>
                    ) : (
                      <div className="symbol symbol-35px symbol-circle mx-3">
                        <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.firstName ? obj.firstName : ""}>
                          {obj?.firstName ? obj.firstName[0].toUpperCase() : ""}
                        </span>
                      </div>
                    )}
                    {(obj?.firstName ? obj?.firstName : "") + " " + (obj?.lastName ? obj?.lastName : "")}
                  </Link>
                  <button className="btn btn-sm btn-dark fw-bold" disabled={loader} onClick={() => unfollowRequest(0, obj?.id, obj?.email, obj.firstName)} >Unfollow</button>
                </div>
              </div>
              <div>
            
            </div>

            </>
            ))}
           
            
            
          </Modal>
        </div>
      </div>
    </section>
  );
};

const mapState = ({ userProfile }: any) => {
  return {
    _userProfile: userProfile,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $userProfile: () => dispatchEvent(userProfile()),
});
export default connect(mapState, mapDispatch)(ProfileCard);
