import * as yup from "yup";

export const initialValues_signIn = {
  email: "",
  password: ""
};

export const validationSchema_signIn = yup.object().shape({
  email: yup
    .string()
    .required("Required")
    .email("Invalid email"),
  password: yup
    .string()
    .min(8, "Minimum 8 Characters Needed")
    .matches(
      /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/,
      "Need at least one special character"
    )
    .matches(/[a-z]/i, "Need at least a character")
    .matches(/^(?=.{0,100}$)\D*\d/, "Need at least a number")
    .required("Required")
});
