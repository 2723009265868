import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "../../../Utils/BaseUrl";
import image from "../../../assets/images/logos/Red-Black.png";
import EmptyImage from "../../../assets/images/avatars/blank.png";
import { ImageUrl } from "../../../Utils/BaseUrl";
import { stringNameLength } from "../Club/AllEventCalculation/Calculation";
import { useParams } from "react-router-dom";
const UserPublicProfileStats = () => {
  const [statsData, setStatsData] = useState<any>();
  const [eventDataIs, setEventDataIs] = useState<any>();
  const [user, setUser] = useState<any>();
  const {screenName} = useParams<any>();
  
  useEffect(() => {
    axios.get(`${BaseUrl}/user/getPublicUserStatAndEventsScore?screenName=${screenName}`).then((res) => {
      const { data } = res.data;
      const { myStats, last_five_events_data, user } = data;
      setStatsData(myStats);
      setEventDataIs(last_five_events_data[0]);
      setUser(user);
    });
  }, []);
  const lastEventMargin = eventDataIs?.betWin+eventDataIs?.parleyWin;

  return (
    // <Layout options="fill_Header" uservisible="visible">
      <div className="main-content pt-0 " style = {{backgroundColor:'#f5f8fa'}}>
        <div className="card">
          <div className="card-body d-flex flex-center flex-column pt-12 p-9" style = {{backgroundColor:'#f5f8fa'}}>
            <div className="row logo">
              <div className="logo_img mb-4">
                <a href="https://mmabetclub.com" target="_blank"><img src={image} width='250px' /></a>
              </div>
            </div>
            <div className="mb-5">
            <span className="fs-1 text-gray-800 text-hover-primary fw-bolder">mmabetclub.com</span>
            </div>
            <div className="symbol symbol-150px symbol-circle mb-5" >
            
              {user?.profilePic.includes("-blob") && user?.firstName ? (
                <span className="symbol symbol-30px symbol-circle ">
                  <span className="symbol-label bg-secondary text-black 40px" style={{ fontSize: "90px", textAlign: "center" }}>
                    {user?.firstName?.charAt(0)}
                  </span>
                </span>
              ) : (
                <img src={user?.profilePic ? ImageUrl + user?.profilePic : EmptyImage} alt="metronic" style = {{objectFit:'cover'}} />
              )}
            </div>
            <span className="fs-1 text-gray-800 text-hover-primary fw-bolder mb-0">{stringNameLength(user?.firstName ? user?.firstName : "", user?.lastName ? user?.lastName : "", 20, 20)}</span>
            <span className="fs-1 text-gray-800 text-hover-primary fw-bolder mb-0">{user?.screenName ? user?.screenName : ""}</span>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center" style={{marginLeft: "calc(var(--bs-gutter-x) * -8)", marginRight:"calc(var(--bs-gutter-x) * -8)"}}>
            <div className="col-sm-12 col-md-4 mb-4">
              <div className="card mb-10 shadow" style={{height: "100%"}}>
                <div className="card-header">
                  <div className="card-title flex-column m-0">
                    <h3 className="fw-bolder mb-0">My Stats</h3>
                  </div>
                </div>
                <div className="card-body d-flex flex-column">
                  <React.Fragment>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Weight Class</span>
                      </div>
                      <div className="ms-auto">{statsData?.weightClass}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Total Points</span>
                      </div>
                      <div className="badge badge-light ms-auto">{statsData?.totalPoints ? statsData?.totalPoints : "-"}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Global Ranking</span>
                      </div>
                      <div className="badge badge-light ms-auto">{statsData?.global_ranking ? statsData?.global_ranking : "-"}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Change since last event</span>
                      </div>
                      <div
                        className={`badge 
                                    ${
                                      statsData?.change_since_last_event === "-" || statsData?.change_since_last_event === 0
                                        ? "badge-light"
                                        : statsData?.change_since_last_event > 0
                                        ? "badge-success"
                                        : "badge-orange"
                                    }
                                     ms-auto`}
                      >
                        {statsData?.change_since_last_event === "-" || statsData?.change_since_last_event === 0
                          ? statsData?.change_since_last_event
                          : statsData?.change_since_last_event > 0
                          ? "+" + statsData?.change_since_last_event
                          : statsData?.change_since_last_event}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Bet Win %</span>
                      </div>
                      <div className="badge badge-light ms-auto">{statsData?.betWinPercent !== "-" && !isNaN(statsData?.betWinPercent) ? statsData?.betWinPercent + "%" : "-"}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Parlay Win %</span>
                      </div>
                      <div className="badge badge-light ms-auto">{statsData?.parleyWinPercent !== "-" && !isNaN(statsData?.parleyWinPercent) ? statsData?.parleyWinPercent + "%" : "-"}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className={`fs-6 fw-bold text-gray-900 text-hover-dark`}>Margin</span>
                      </div>
                      <div
                        className={`badge ms-auto  ${!isNaN(statsData?.margin) ? (statsData?.margin > 0 ? "badge-success" : statsData?.margin < 0 ? "badge-orange" : "badge-light") : "badge-light"}`}
                      >
                        {!isNaN(statsData?.margin) ? "$" + statsData?.margin : "-"}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">ROI</span>
                      </div>
                      {parseFloat(statsData?.ROI) ? (
                        <div className={`badge ${parseFloat(statsData?.ROI) >= 0 ? `badge-success` : `badge-orange`} ms-auto`}>
                          {parseFloat(statsData?.ROI) >= 0 ? parseFloat(statsData?.ROI)?.toFixed(2) + "%" : parseFloat(statsData?.ROI)?.toFixed(2) + "%"}
                        </div>
                      ) : (
                        <div className={`badge badge-light ms-auto`}>{statsData?.ROI}</div>
                      )}
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
              <div className="card mb-10 shadow" style={{height: "100%"}}>
                <div className="card-header">
                  <div className="card-title flex-column m-0">
                    <h3 className="fw-bolder mb-0">Last Event</h3>
                  </div>
                </div>
                <div className="card-body d-flex flex-column">
                  <React.Fragment>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Total Points</span>
                      </div>
                      <div className="badge badge-light ms-auto">{isNaN(eventDataIs?.points) ? "-" : eventDataIs?.points}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Bet Win %</span>
                      </div>
                      <div className="badge badge-light ms-auto">{isNaN(eventDataIs?.betWinPercent) ? "-" : eventDataIs?.betWinPercent?.toFixed(2) + "%"}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">Parlay Win (Y/N)</span>
                      </div>
                      <div className="badge badge-light ms-auto">{typeof eventDataIs?.isParleyWin === "boolean" ? (eventDataIs?.isParleyWin ? "Y" : "N") : "-"}</div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span className={`fs-6 fw-bold text-gray-900 text-hover-dark`}  >
                            Margin
                        </span>
                    </div>
                    <div className={`badge ms-auto  ${ !isNaN(lastEventMargin) ? (lastEventMargin > 0 ? "badge-success" : lastEventMargin < 0 ? "badge-orange" : "badge-light") : "badge-light" }`}>{ !isNaN(lastEventMargin) ? "$"+lastEventMargin : "-" }</div>
                </div>
                    <div className="d-flex align-items-center mb-5">
                      <div className="fw-bold">
                        <span className="fs-6 fw-bold text-gray-900 text-hover-dark">ROI</span>
                      </div>
                      {!isNaN(eventDataIs?.roi) ? (
                        <div className={`badge ${eventDataIs?.roi > 0 ? `badge-success` : eventDataIs?.roi < 0 ? "badge-orange" : "badge-light"} ms-auto`}>{eventDataIs?.roi?.toFixed(2) + "%"}</div>
                      ) : (
                        <div className={`badge badge-light ms-auto`}>{eventDataIs?.roi ? eventDataIs?.roi : "-"}</div>
                      )}
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </Layout>
  );
};

export default UserPublicProfileStats;
