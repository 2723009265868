import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getClubDetails } from "../../../../Store/action/UserInfo.action";
import Spinner from "../../../../Common/Spinner/Spinner";
import ReactPaginate from 'react-paginate';
import { stringWithValidLength } from "../../Club/AllEventCalculation/Calculation"

const MyClubs = (props?: any) => {
  const userProfile = props?._userProfile?.userDataIs.data;
  const [Loading, setLoading] = useState<boolean>(false);
  const [ListIs, setListIs] = useState<any>(null);
  const [totalPages, setToatalPages] =  useState<number>(0);
  const [From , setFrom] = useState<number>(4);
  const [To, setTo] = useState<number>(0)
  const Limit = 4;

  const getClubData = () => {
    setLoading(true);
    props.$getClubDetails();
  };

  useEffect(() => {
    getClubData();
  }, []);

  const getclubList = (data: any) => {
    setListIs(data);
    setLoading(false);
  };

  useEffect(() => {
    const clubListIs = props._getClubDetails.getClubDetailsIs;
    if (clubListIs?.status === 1 && Loading) {
      getclubList(clubListIs?.data);
    }
  }, [props._getClubDetails]);

  const handlePageClick = (data : any) => {
    const selectedData = data?.selected + 1;
    const from = selectedData * Limit;
    const to =  from - Limit;
    setFrom(from)
    setTo(to)
  }

  useEffect(() => {
    setToatalPages(Math.ceil((ListIs?.length)/Limit))
  }, [ListIs])

  return (
    <div className="card myProfileCard mb-4 mb-sm-10 mb-lg-10">
      <div className="card-header">
        <div className="card-title w-100 d-flex align-items-center justify-content-between m-0">
          <div>
            <h3 className="fw-bolder mb-0">My Clubs</h3>
          </div>
          {!userProfile?.hasClub && (
            <div>
              <Link to="/clubdetails">
                <label className="form-label fs-6 fw-bold text-dark cursor-pointer">
                  <i className="bi bi-plus-circle fs-4 me-1 text-dark"></i> Create
                  club
                </label>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        {Loading ? (
          <Spinner color="text-black spinner-border-sm " />
        ) : ListIs?.length > 0 ? (
          ListIs?.slice(To , From).map((obj: any, index: number) => (
            <div className="d-flex align-items-center mb-5" key={index}>
              <div className="fw-bold">
                <Link
                  to={`/clubdetails/${obj?.clubId}`}
                  className="fs-6 fw-bold text-gray-900 text-hover-dark"
                >
                  {obj?.name && stringWithValidLength(obj?.name ,30, 30)}
                </Link>
              </div>
            </div>
          ))       
        ) : (
          <div className="d-flex align-items-center mb-5">
            <div className="fw-bold">No Clubs yet.</div>
          </div>
        )}
         {totalPages > 0 && 
         <div className="d-flex justify-content-end">
         <nav aria-label="Page navigation example">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(data) => handlePageClick(data)}
                containerClassName={"pagination"}
                // subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
          </nav> 
          </div>} 
      </div>
    </div>
  );
};
// export default MyClubs
const mapState = ({ userProfile, getClubDetails }: any) => {
  return {
    _userProfile: userProfile,
    _getClubDetails: getClubDetails,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $getClubDetails: () => dispatchEvent(getClubDetails()),
});
export default connect(mapState, mapDispatch)(MyClubs);
