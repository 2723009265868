import { 
    ALLTIME_GAME_PLANNER, 
    ALLTIME_GAME_PLANNER_SUCCESS,
    ALLTIME_POUND_FOR_POUND,
    ALLTIME_POUND_FOR_POUND_SUCCESS,
    ALLTIME_MAKING_CHAMPIONSHIP_RUN, 
    ALLTIME_MAKING_CHAMPIONSHIP_RUN_SUCCESS, 
    EVENT_GAME_PLANNER,
    EVENT_GAME_PLANNER_SUCCESS,
    EVENT_POUND_FOR_POUND,
    EVENT_POUND_FOR_POUND_SUCCESS,
    EVENT_CORRECT_BETS,
    EVENT_CORRECT_BETS_SUCCESS
} from "../type.action"

export const allTimeGamePlanner = () => ({
    type: ALLTIME_GAME_PLANNER,
})
export const allTimeGamePlannerSuccess = (values ?: any) => ({
    type: ALLTIME_GAME_PLANNER_SUCCESS,
    values
})

export const allTimePoundForPound = () => ({
    type: ALLTIME_POUND_FOR_POUND
})
export const allTimePoundForPoundSuccess = (values ?: any) => ({
    type: ALLTIME_POUND_FOR_POUND_SUCCESS,
    values
})

export const allTimeMakingChampionshipRun = () => ({
    type: ALLTIME_MAKING_CHAMPIONSHIP_RUN
})
export const allTimeMakingChampionshipRunSuccess = (values ?: any) => ({
    type: ALLTIME_MAKING_CHAMPIONSHIP_RUN_SUCCESS,
    values
})

export const eventGamePlanner = (eventName:any, eventDate:any) => ({
    type: EVENT_GAME_PLANNER,
    values: {eventName, eventDate}
})
export const eventGamePlannerSuccess = (values ?: any) => ({
    type: EVENT_GAME_PLANNER_SUCCESS,
    values
})

export const eventPoundForPound = (eventName:any, eventDate:any) => ({
    type: EVENT_POUND_FOR_POUND,
    values: {eventName, eventDate}
})
export const eventPoundForPoundSuccess = (values ?: any) => ({
    type: EVENT_POUND_FOR_POUND_SUCCESS,
    values
})

export const eventCorrectBets = (eventName:any, eventDate:any) => ({
    type: EVENT_CORRECT_BETS,
    values: {eventName, eventDate}
})

export const eventCorrectBetsSuccess = (values ?: any) => ({
    type: EVENT_CORRECT_BETS_SUCCESS,
    values
})