import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import {
  getResult,
  getTotalRisk,
  getTotalWin,
  getHomeBeters,
  getAwayBeters,
  getUser,
  getAllEv,
  getUniqueBeters,
  getMe,
  getitsMe,
  getUniqueMe,
  getTotalRiskMe,
  stringWithValidLength,
  getCurrentWin,
  getResultClass,
  getLastName,
  getResultNew,
} from "../../AllEventCalculation/Calculation";
import { getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import LeftRightScroll from "../../LeftRightScroll";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import { externalRedirect } from "../../../../../Utils/helperFunctions";
import Moment from "react-moment";
const moment = require("moment")

const EventBets = (props: any) => {
  const {
    teamList,
    betData,
    setBetData,
    IsLocked,
    eventBetTotal,
    setSelectedBet,
    handleSaveDraft,
    isAlertActive,
  } = props;
  const [users, setUsers] = useState<any>(null);
  const [allEv, setAllEv] = useState<any>(null);
  const [uniqueBeters, setUniqueBeters] = useState<any>(null);
  const [meData, setMeData] = useState<any>(null);
  const [itsMe, setItsMe] = useState<any>(null);
  const [uniqueMe, setUniqueMe] = useState<any>(null);
  var totalWin: number = 0;
  const [singleLock, setSingleLock] = useState<boolean>(false);
  const [showBetSaved, setShowBetSaved] = useState<any>({});
  const [timeDifference, setTimeDifference] = useState<any>();
  const [mainCard, setMainCard] = useState<any>();
  const [prelimsCard, setprelimsCard] = useState<any>();
  const [earlyPrelimsCard, setEarlyPrelimsCard] = useState<any>()

  const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>

  // console.log("unique", uniqueBeters)

  const handleOnchange = (
    e: any,
    pickedTeam: any,
    scheduleId: any,
    moneyLine: number
  ) => {
    const { value } = e.target;
    if (value <= 999999) {
      if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(value) || value === "") {
        const list = [
          ...betData.filter((k: any) => k.scheduleId !== scheduleId),
          {
            scheduleId: scheduleId,
            risk: value,
            pickedTeam: pickedTeam,
            win: getCurrentWin(value, moneyLine),
            moneyLine
          },
        ];

        setBetData([...list]);
      }
    }
  };

  const handleOnBlur = (
    e: any,
    pickedTeam: any,
    scheduleId: any,
    moneyLine: number,
    status?: any
  ) => {
    const { value } = e.target;
    if (value <= 999999) {
      if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(value) || value === "") {
        const singleSaveList = [
          {
            scheduleId: scheduleId,
            risk: value,
            pickedTeam: pickedTeam,
            win: getCurrentWin(value, moneyLine),
            moneyLine,
            status
          },
        ];
        setSelectedBet(singleSaveList);
        if (singleSaveList[0].risk > 0) {
          setShowBetSaved({
            msg: `Bet amount $${singleSaveList[0].risk} saved to draft.`,
            status: true,
          });
          setTimeout(() => {
            setShowBetSaved({ status: false });
          }, 2000);
        }
      }
    }
  };

  const debouncedChangeHandler = useCallback(debounce(handleOnBlur, 1000), []);

  const lockButtonClicked = (e:any, teamType:any, scheduleId:any, moneyLine:any, status:any) => {
    const ele:any = document.getElementById(e.target.id)
    if(ele){
      const inputValue:any = ele?.previousElementSibling?.value
      setShowBetSaved({ msg: `Bet locked.`, status: true, });
      setTimeout(() => setShowBetSaved({ status: false }), 2000);
      debouncedChangeHandler({target:{value:inputValue}}, teamType, scheduleId, moneyLine, "2")
      ele.innerHTML = ""
      ele.innerHTML = `<div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>`
    }
    
  }

  useEffect(() => {
    setUsers(getUser(teamList));
    setMeData(getMe(teamList));
  }, [teamList]);

  useEffect(() => {
    setAllEv(getAllEv(users));
  }, [users]);

  useEffect(() => {
    setUniqueBeters(getUniqueBeters(allEv));
  }, [allEv]);

  useEffect(() => {
    setItsMe(getitsMe(meData));
  }, [meData]);

  useEffect(() => {
    setUniqueMe(getUniqueMe(itsMe));
  }, [itsMe]);

  useEffect(() => {
   const mainCardFighter = teamList && teamList?.filter((ele:any) => ele?.event_card === 'Main Card')
   const prelimsFighter = teamList && teamList?.filter((ele:any) => ele?.event_card === 'Prelims')
   const earlyPrelimsFighter = teamList && teamList?.filter((ele:any) => ele?.event_card === 'Early Prelims')
   setMainCard(mainCardFighter)
   setprelimsCard(prelimsFighter)
   setEarlyPrelimsCard(earlyPrelimsFighter)
  }, [teamList]);

  const getIsMe = (
    teamType: number,
    scheduleId: string,
    moneyLine: any,
    IsLocked: boolean,
    eventStatus: String,
    winningStatus: any,
    isthisEventLocked: any,
    eventLockTime: string
  ) => {
    const isDisabled =
      betData?.find(
        (l: any) => l.scheduleId === scheduleId && l.pickedTeam !== teamType
      )?.risk || getDiffrenceOfTimeInMinutes(eventLockTime) < 0;
    const risk = betData?.find(
      (el: any) => el.scheduleId === scheduleId && el.pickedTeam === teamType
    )?.risk;

    const win = betData?.find(
      (l: any) => l.scheduleId === scheduleId && l.pickedTeam === teamType
    )?.win;

    totalWin = totalWin + Number(getCurrentWin(risk, moneyLine));

    return (
      <td className="table-column table_width">
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={`w-50 r  ${
              isthisEventLocked &&
              !isNaN(risk) &&
              eventStatus === "STATUS_FINAL"
                ? getResultClass(winningStatus, risk ? risk : "-")
                : ""
            }`}
          >
            {isthisEventLocked ? 
            (
              // check if number then add $ sign else show "-"
              !isNaN(risk) ? (
                <>
                  { new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(risk)}{" "}
                  {getDiffrenceOfTimeInMinutes(eventLockTime) > 0 ? (
                    <i className="bi bi-lock-fill mx-1"></i>
                  ) : (
                    ""
                  )}{" "}
                </>
              ) : (
                "-"
              )
            ) 
            : getDiffrenceOfTimeInMinutes(eventLockTime) > 0 ? (
              <span className="d-flex position-relative justify-content-center align-items-center">
                {risk ? (
                  <span className="m-2 position-absolute inputBoxDollarsign">
                    $
                  </span>
                ) : (
                  ""
                )}{" "}
                {/* if value then show $ */}
                <input
                  type="text"
                  name="risk"
                  className="form-control form-control-solid bg-white p-1"
                  placeholder={!isDisabled ? "Risk" : ""}
                  value={risk || ""}
                  // onChange={(e) => {handleOnchange(e, teamType, scheduleId, moneyLine);  handleOnBlur(e, teamType, scheduleId, moneyLine)}}
                  onChange={(e) => {
                    handleOnchange(e, teamType, scheduleId, moneyLine);
                    debouncedChangeHandler(e, teamType, scheduleId, moneyLine, "1");
                  }}
                  //onBlur = {(e) => handleOnBlur(e, teamType, scheduleId, moneyLine)}
                  disabled={isDisabled}
                />
                {
                  !isDisabled 
                  && 
                  <span 
                    className="btn-orange rounded m-l p-2 d-flex justify-content-center align-items-center" 
                    id={String(Math.ceil(Math.random()*100000000))} 
                    onClick={(e) => lockButtonClicked(e, teamType, scheduleId, moneyLine, "2") } 
                  >
                    <i className="bi bi-lock-fill p-0 text-white" style={{ pointerEvents:'none'}}/>
                  </span>
                }
                {/* {
              risk>0 && !isthisEventLocked 
              ?
              <i style={{color:"black"}} className="bi bi-lock-fill mt-2" /> 
              :
              ""
            } */}
              </span>
            ) : (
              <div
              className="d-flex position-relative justify-content-center align-items-center"
            >
              {
              !isthisEventLocked ?
              (
                !isNaN(risk) ? (
                  <>
                    {"$" + Number(risk)?.toFixed(2)}
                  
                  </>
                ) : (
                  "-"
                )
              )
              :
              ""
              }
              </div>
            )}
          </div>
          <div
            className={`w-50 w  ${
              isthisEventLocked &&
              !isNaN(risk) &&
              eventStatus === "STATUS_FINAL"
                ? getResultClass(winningStatus, risk ? risk : "-")
                : ""
            }`}
          >
            {isthisEventLocked
              ? !isNaN(win)
                ?  new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(win)
                : "-"
              : risk && moneyLine
              ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(getCurrentWin(risk, moneyLine))
              : "-"}
          </div>
        </div>
      </td>
    );
  };

  return (
    <>
      <div ref={slideRef} className="custom_table position-relative table-responsive table_wrapper h-500">
        {
          showBetSaved?.status 
          ?
          <div className="alert alert-success position-absolute w-100 alert-dismissible fade show d-flex align-items-center justify-content-center" style={{zIndex:9999999}} role="alert" >
            {showBetSaved?.msg}
          </div>
          :
          ""
        }
        <table
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer "
          id="kt_subscriptions_table_2"
          role="grid"
        >
          <thead
            className="sticky-top"
            sticky-top
            style={{ background: "white" }}
          >
            <tr
              className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-2 "
              role="row"
            >
              <th
                className="min-w-250px cu-12 sorting text-start ps-4"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Customer: activate to sort column ascending"
                style={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 1,
                }}
              >
                Fighters
              </th>
              {/* <th
              className="min-w-70px sorting position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 70, position: "sticky", left: 200, background: "white"}}
              // aria-sort="ascending"
            >
              Moneyline
            </th>
            <th
              className="min-w-70px position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Billing: activate to sort column ascending"
              style={{ width: 70, position: "sticky", left: 295, background: "white"}}
            >
              Result
            </th> */}
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Product: activate to sort column ascending"
                style={{ width: 220 }}
              >
                <Link to = {`/myprofile`}  className="text-muted">
                  Me
                </Link>
                <div className="d-flex align-items-center justify-content-between pt-2">
                  <div className="w-50">Risk</div>
                  <div className="w-50">Win</div>
                </div>
              </th>
              {uniqueBeters?.length > 0 &&
                uniqueBeters?.map((obj: any, index: any) => (
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="kt_subscriptions_table"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Created Date: activate to sort column ascending"
                    style={{ width: 220 }}
                  >
                    {
                      obj?.screenName 
                      ?
                      <Link to = {`/player/${obj?.screenName}`}  className="text-muted">
                        {obj?.firstName ? stringWithValidLength(obj?.firstName, 15, 15) : "-"}
                      </Link>
                      :
                      <>
                        {obj?.firstName ? stringWithValidLength(obj?.firstName, 15, 15) : "-"}
                      </>
                    }
                    <div className="d-flex align-items-center justify-content-between pt-2">
                      <div className="w-50">Risk</div>
                      <div className="w-50">Win</div>
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold cu-table-01">
           
            {
            mainCard?.length > 0 ? (
              <>
              <div className="badge badge-pill btn-orange" style = {{fontSize:'14px', marginTop:'2px', position:'sticky', left:0}}>Main Card: {moment(mainCard[0]?.eventCardTime).format("hh:mm a")}</div>
              {mainCard.map((obj: any, index: number) => (
                <>
                  <tr className="bg-table-row-transparent" key={index}>
                    <td
                      // role={obj?.awayTeamUrl && "button"}                     
                      className=" table-column text-gray-800 text-start ps-4"
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 1,
                      }}
                    >
                     <span className="image-avatar-wrapper"  onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                      </span>
                      {/* for desktop */}
                      <span className="d-none d-sm-inline-block">
                        <span   onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>{stringWithValidLength(obj?.away_team, 20, 15)}</span>
                        <span>
                          {obj?.moneyline_away
                            ? " (" + obj?.moneyline_away + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                      </span>
                      {/* for mobile */}
                      <span className="d-sm-none d-inline-block">
                        {getLastName(obj?.away_team)}
                        <span>
                          {obj?.moneyline_away
                            ? " (" + obj?.moneyline_away + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }

                       
                      </span>
                    </td>
                    {/* <td className="table-column scroll_sticky_white_second">{obj?.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column scroll_sticky_white_fourth">{getResult(obj?.result?.winner_away)}</td> */}

                    {/* ME */}
                    {obj?.moneyline_away ? (
                      getIsMe(
                        2,
                        obj?.id,
                        obj.moneyline_away,
                        IsLocked,
                        obj?.event_status,
                        obj?.result?.winner_away,
                        obj?.isLock,
                        obj?.eventCardTime,
                      )
                    ) : (
                      <td className="table-column table_width">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="w-50">-</div>
                          <div className="w-50">-</div>
                        </div>
                      </td>
                    )}

                    {/* AWAY TEAM BETERS */}
                    {uniqueBeters?.map((Ob: any) =>
                      getAwayBeters(Ob, obj, allEv)
                    )}
                  </tr>
                  <tr className="bg-table-row-2">


                    <td
                      // role={obj?.homeTeamUrl && "button"}                      
                      className="table-column text-gray-800 text-start ps-4"
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 1,
                      }}
                    >
                    <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3 ">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>
                      }
                    </span>
                      {/* for desktop */}
                      <span className="d-none d-sm-inline-block">
                      <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {stringWithValidLength(obj?.home_team, 20, 15)}
                        </span>
                        <span>
                          {obj?.moneyline_home
                            ? " (" + obj?.moneyline_home + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                      </span>
                      {/* for mobile */}
                      <span className="d-sm-none d-inline-block">
                      <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>
                          {obj?.moneyline_home
                            ? " (" + obj?.moneyline_home + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                        

                      </span>
                      
                    </td>

                    {/* <td className="table-column scroll_sticky_white_second">{obj?.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column scroll_sticky_white_fourth">{getResult(obj?.result?.winner_home)}</td> */}

                    {/* ME */}
                    {obj?.moneyline_home ? (
                      getIsMe(
                        1,
                        obj?.id,
                        obj.moneyline_home,
                        IsLocked,
                        obj?.event_status,
                        obj?.result?.winner_home,
                        obj?.isLock,
                        obj?.eventCardTime
                      )
                    ) : (
                      <td className="table-column column">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="w-50">-</div>
                          <div className="w-50">-</div>
                        </div>
                      </td>
                    )}

                    {/* HOME TEAM BETERS */}
                    {uniqueBeters?.map((Ob: any) =>
                      getHomeBeters(Ob, obj, allEv)
                    )}
                  </tr>
                </>
              ))}</>
            ) : (
              <tr className="bg-table-row-transparent">
                <td className="table-column text-gray-800 text-start ps-4">
                  Data Not found...
                </td>
              </tr>
            )}
      
              
            { prelimsCard && prelimsCard?.length > 0 ? (
              <>
              {/* <div className="badge badge-pill badge-warning">Warning</div> */}
              <div className="badge badge-pill btn-orange" style = {{fontSize:'14px',  marginTop:'2px', position:'sticky', left:0}}>Prelims: {moment(prelimsCard[0]?.eventCardTime).format("hh:mm a")}</div>
              {
              prelimsCard.map((obj: any, index: number) => (
                <>
                  <tr className="bg-table-row-transparent" key={index}>
                    <td
                      // role={obj?.awayTeamUrl && "button"}                     
                      className=" table-column text-gray-800 text-start ps-4"
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 1,
                      }}
                    >
                     <span className="image-avatar-wrapper"  onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                      </span>
                      {/* for desktop */}
                      <span className="d-none d-sm-inline-block">
                        <span   onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>{stringWithValidLength(obj?.away_team, 20, 15)}</span>
                        <span>
                          {obj?.moneyline_away
                            ? " (" + obj?.moneyline_away + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                      </span>
                      {/* for mobile */}
                      <span className="d-sm-none d-inline-block">
                        {getLastName(obj?.away_team)}
                        <span>
                          {obj?.moneyline_away
                            ? " (" + obj?.moneyline_away + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }

                       
                      </span>
                    </td>
                    {/* <td className="table-column scroll_sticky_white_second">{obj?.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column scroll_sticky_white_fourth">{getResult(obj?.result?.winner_away)}</td> */}

                    {/* ME */}
                    {obj?.moneyline_away ? (
                      getIsMe(
                        2,
                        obj?.id,
                        obj.moneyline_away,
                        IsLocked,
                        obj?.event_status,
                        obj?.result?.winner_away,
                        obj?.isLock,
                        obj?.eventCardTime,
                      )
                    ) : (
                      <td className="table-column table_width">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="w-50">-</div>
                          <div className="w-50">-</div>
                        </div>
                      </td>
                    )}

                    {/* AWAY TEAM BETERS */}
                    {uniqueBeters?.map((Ob: any) =>
                      getAwayBeters(Ob, obj, allEv)
                    )}
                  </tr>
                  <tr className="bg-table-row-2">


                    <td
                      // role={obj?.homeTeamUrl && "button"}                      
                      className="table-column text-gray-800 text-start ps-4"
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 1,
                      }}
                    >
                    <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3 ">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>
                      }
                    </span>
                      {/* for desktop */}
                      <span className="d-none d-sm-inline-block">
                      <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {stringWithValidLength(obj?.home_team, 20, 15)}
                        </span>
                        <span>
                          {obj?.moneyline_home
                            ? " (" + obj?.moneyline_home + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                      </span>
                      {/* for mobile */}
                      <span className="d-sm-none d-inline-block">
                      <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>
                          {obj?.moneyline_home
                            ? " (" + obj?.moneyline_home + ") "
                            : "-"}
                        </span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                        

                      </span>
                      
                    </td>

                    {/* <td className="table-column scroll_sticky_white_second">{obj?.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column scroll_sticky_white_fourth">{getResult(obj?.result?.winner_home)}</td> */}

                    {/* ME */}
                    {obj?.moneyline_home ? (
                      getIsMe(
                        1,
                        obj?.id,
                        obj.moneyline_home,
                        IsLocked,
                        obj?.event_status,
                        obj?.result?.winner_home,
                        obj?.isLock,
                        obj?.eventCardTime
                      )
                    ) : (
                      <td className="table-column column">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="w-50">-</div>
                          <div className="w-50">-</div>
                        </div>
                      </td>
                    )}

                    {/* HOME TEAM BETERS */}
                    {uniqueBeters?.map((Ob: any) =>
                      getHomeBeters(Ob, obj, allEv)
                    )}
                  </tr>
                </>
              ))}
              </>
            ) : (
              ""
              // <tr className="bg-table-row-transparent">
              //   <td className="table-column text-gray-800 text-start ps-4">
              //     Data Not found...
              //   </td>
              // </tr>
            )}
            
          {earlyPrelimsCard && earlyPrelimsCard.length > 0 ? 
            <>
            <div className="badge badge-pill btn-orange" style = {{fontSize:"14px",  marginTop:'2px', position:'sticky', left:0}}>Early Prelims: {moment(earlyPrelimsCard[0]?.eventCardTime).format("hh:mm a")} </div>
          
            {  earlyPrelimsCard?.map((obj:any, index:number) => (
              <>
              <tr className="bg-table-row-transparent" key={index}>
                <td
                  // role={obj?.awayTeamUrl && "button"}                     
                  className=" table-column text-gray-800 text-start ps-4"
                  style={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                 <span className="image-avatar-wrapper"  onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                  {!obj?.awayTeamPic?
                  <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                    <span
                      className={`symbol-label ${
                        obj.moneyline_away < 0
                          ? "bg-light-success text-success"
                          : obj.moneyline_away > 0
                          ? "bg-light-danger text-danger"
                          : "bg-light text-dark"
                      } 40px`}
                    >
                       {obj?.away_team.charAt(0)}
                    </span>
                  </span>
                  :
                  <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                  <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                  </div>}
                  </span>
                  {/* for desktop */}
                  <span className="d-none d-sm-inline-block">
                    <span   onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>{stringWithValidLength(obj?.away_team, 20, 15)}</span>
                    <span>
                      {obj?.moneyline_away
                        ? " (" + obj?.moneyline_away + ") "
                        : "-"}
                    </span>
                    <span>{getResultNew(obj?.result?.winner_away)}</span>
                    {
                      !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                      ? 
                      <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                      : 
                      ""
                    }
                  </span>
                  {/* for mobile */}
                  <span className="d-sm-none d-inline-block">
                    {getLastName(obj?.away_team)}
                    <span>
                      {obj?.moneyline_away
                        ? " (" + obj?.moneyline_away + ") "
                        : "-"}
                    </span>
                    <span>{getResultNew(obj?.result?.winner_away)}</span>
                    {
                      !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                      ? 
                      <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                      : 
                      ""
                    }

                   
                  </span>
                </td>
                {/* <td className="table-column scroll_sticky_white_second">{obj?.moneyline_away ? obj.moneyline_away : "-"}</td>
              <td className="table-column scroll_sticky_white_fourth">{getResult(obj?.result?.winner_away)}</td> */}

                {/* ME */}
                {obj?.moneyline_away ? (
                  getIsMe(
                    2,
                    obj?.id,
                    obj.moneyline_away,
                    IsLocked,
                    obj?.event_status,
                    obj?.result?.winner_away,
                    obj?.isLock,
                    obj?.eventCardTime,
                  )
                ) : (
                  <td className="table-column table_width">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-50">-</div>
                      <div className="w-50">-</div>
                    </div>
                  </td>
                )}

                {/* AWAY TEAM BETERS */}
                {uniqueBeters?.map((Ob: any) =>
                  getAwayBeters(Ob, obj, allEv)
                )}
              </tr>
              <tr className="bg-table-row-2">


                <td
                  // role={obj?.homeTeamUrl && "button"}                      
                  className="table-column text-gray-800 text-start ps-4"
                  style={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                  {!obj?.homeTeamPic?
                  <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                    <span
                      className={`symbol-label ${
                        obj.moneyline_home < 0
                          ? "bg-light-success text-success"
                          : obj.moneyline_home > 0
                          ? "bg-light-danger text-danger"
                          : "bg-light text-dark"
                      } 40px`}
                    >
                       {obj?.home_team.charAt(0)}
                    </span>
                  </span>
                  :
                  <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3 ">
                  <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                  </div>
                  }
                </span>
                  {/* for desktop */}
                  <span className="d-none d-sm-inline-block">
                  <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                    {stringWithValidLength(obj?.home_team, 20, 15)}
                    </span>
                    <span>
                      {obj?.moneyline_home
                        ? " (" + obj?.moneyline_home + ") "
                        : "-"}
                    </span>
                    <span>{getResultNew(obj?.result?.winner_home)}</span>
                    {
                      !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                      ? 
                      <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                      : 
                      ""
                    }
                  </span>
                  {/* for mobile */}
                  <span className="d-sm-none d-inline-block">
                  <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                    {getLastName(obj?.home_team)}
                    </span>
                    <span>
                      {obj?.moneyline_home
                        ? " (" + obj?.moneyline_home + ") "
                        : "-"}
                    </span>
                    <span>{getResultNew(obj?.result?.winner_home)}</span>
                    {
                      !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                      ? 
                      <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                      : 
                      ""
                    }
                    

                  </span>
                  
                </td>

                {/* <td className="table-column scroll_sticky_white_second">{obj?.moneyline_home ? obj.moneyline_home : "-"}</td>
              <td className="table-column scroll_sticky_white_fourth">{getResult(obj?.result?.winner_home)}</td> */}

                {/* ME */}
                {obj?.moneyline_home ? (
                  getIsMe(
                    1,
                    obj?.id,
                    obj.moneyline_home,
                    IsLocked,
                    obj?.event_status,
                    obj?.result?.winner_home,
                    obj?.isLock,
                    obj?.eventCardTime
                  )
                ) : (
                  <td className="table-column column">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-50">-</div>
                      <div className="w-50">-</div>
                    </div>
                  </td>
                )}

                {/* HOME TEAM BETERS */}
                {uniqueBeters?.map((Ob: any) =>
                  getHomeBeters(Ob, obj, allEv)
                )}
              </tr>
            </>
            ))}
          </> : ""
          //  <tr className="bg-table-row-transparent">
          //   <td className="table-column text-gray-800 text-start ps-4">
          //     Data Not found...
          //   </td>
          // </tr>
          }

            {/* Total Number Row  */}
            {teamList?.length > 0 && (
              <tr className="total_number">
                <td
                  style={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                  className=" ps-5 text-start scroll_sticky_black"
                >
                  Bet Amount and Margin
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="w-50">
                      {(getTotalRiskMe(betData))
                        ? getTotalRiskMe(betData)
                        : "$ 0.00"}
                    </div>
                    <div className="w-50">
                      {!isNaN(totalWin) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(totalWin): "$ 0.00"}
                    </div>
                  </div>
                </td>
                {uniqueBeters?.length > 0 &&
                  uniqueBeters.map((ob: any, index: any) => (
                    <td key={index}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">
                          {(getTotalRisk(ob, eventBetTotal))
                            ?  getTotalRisk(ob, eventBetTotal)
                            : "$ 0.00"}
                        </div>
                        <div className="w-50">
                          {(getTotalWin(ob, eventBetTotal))
                            ?  getTotalWin(ob, eventBetTotal)
                            : "$ 0.00"}
                        </div>
                      </div>
                    </td>
                  ))}
              </tr>
            )}
          </tbody>
        </table>
      </div >
      <LeftRightScroll slideRef={slideRef}/>
    </>
  );
};
export default EventBets;
