
import { connect } from "react-redux";
import { profileBets } from "../../../../Store/action/Events.action";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Spinner from "../../../../Common/Spinner/Spinner";
import { userClickedEventDetail } from "../../../../Store/action/UserInfo.action";

const MyBets = (props?: any) => {
  const [Bets, setBets] = useState<any>(null);
  const [Loading, setLoading] = useState<boolean>(false);
  const [totalPages, setToatalPages] = useState<number>(0);
  const [From, setFrom] = useState<number>(4);
  const [To, setTo] = useState<number>(0);
  const Limit = 4;
  const bets = props?._profileBets.profileBetsIs;

  const getBetsData = () => {
    setLoading(true);
    props.$profileBets();    
    setBets([])
  }

  useEffect(() => {
    getBetsData()
  },[]);


  const getList = (data : any) => {
    setBets(data);
    setLoading(false);
  }

  useEffect(() => {
    const bets = props._profileBets.profileBetsIs;
    if (bets?.status === 1 && Loading) {
      getList(bets?.data)
    }
  }, [props._profileBets]);

  const handlePageClick = (data: any) => {
    const selectedData = data?.selected + 1;
    const from = selectedData * Limit;
    const to = from - Limit;
    setFrom(from);
    setTo(to);
  };

  useEffect(() => {
    setToatalPages(Math.ceil(props._profileBets.profileBetsIs?.data?.length / Limit));
  }, [props._profileBets]);
  
  return (
    <div className="card myProfileCard mb-4 mb-sm-10 mb-lg-10">
      <div className="card-header">
        <div className="card-title flex-column m-0">
          <h3 className="fw-bolder mb-0">My Bets</h3>
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        {Loading ? (
          <Spinner color="text-black spinner-border-sm " />
        ) : (bets?.data?.length > 0 ) ? (
          props._profileBets.profileBetsIs?.data?.slice(To, From).map((obj: any, index: number) => (
            <div className="d-flex align-items-center mb-5" key={index}>
              <div className="fw-bold">
                <span                  
                  className="fs-6 fw-bold text-gray-900 text-hover-dark"
                >
                  {obj?.event_name}
                </span>
              </div>
              <div className="badge badge-light ms-auto btn" onClick={() => props.$userEventClick(obj)}>View bets</div>
            </div>
          ))
        ) : (
          <div className="d-flex align-items-center mb-5">
            <div className="fw-bold">Bets not Found..</div>
          </div>
        )}
        {totalPages > 0 && (
          <div className="d-flex justify-content-end">
          <nav aria-label="Page navigation example">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={(data) => handlePageClick(data)}
              containerClassName={"pagination"}
              // subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </nav>
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = ({ profileBets }: any) => {
  return {
    _profileBets: profileBets,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $profileBets: () => dispatchEvent(profileBets()),
  $userEventClick: (eventData:any) => dispatchEvent(userClickedEventDetail(eventData))
});
export default connect(mapState, mapDispatch)(MyBets);
