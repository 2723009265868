import { stringNameLength, getSummaryBets, getSorting, getSummaryBetsIsNumber, getSummaryBetsIsPercent } from "../../../Club/AllEventCalculation/Calculation";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
const SummaryEventBetslip = (props?: any) => {
  const { UsersummaryData , setSummaryData, sortingIs, setSorting, setActiveSort, activeSortName} = props;
 
  return (
    <div className="table-responsive">
      {UsersummaryData?.length > 0 ? <table
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        id="kt_subscriptions_table_3"
        role="grid"
      >
        <thead>
          <tr
            className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-1"
            role="row"
          >
            {/* <th
              className="min-w-50px sorting text-start text-center"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Customer: activate to sort column ascending"
              style={{ width: 50 }}
            >
              #
            </th> */}
            <th
              className={`min-w-150px sorting text-start ${activeSortName === "fullName" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Customer: activate to sort column ascending"
              style={{ width: 175, position: "sticky", left: 0, background: "white", zIndex: 1}}
              onClick={() => getSorting("fullName", UsersummaryData, setSummaryData, sortingIs, setSorting, activeSortName, setActiveSort)}
            >
              Player
            </th>
            <th
              className={`min-w-75px sorting ${activeSortName === "correctBets" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 75 }}
              aria-sort="ascending"
              onClick={() => getSorting("correctBets", UsersummaryData, setSummaryData, sortingIs, setSorting, activeSortName, setActiveSort)}
            >
              Correct Bets 
            </th>
            <th
              className={`min-w-125px sorting ${activeSortName === "betRisk" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 125 }}
              aria-sort="ascending"
              onClick={() => getSorting("betRisk", UsersummaryData, setSummaryData, sortingIs, setSorting, activeSortName, setActiveSort)}
            >
              Bets
            </th>
            <th
              className={`min-w-75px sorting ${activeSortName === "betROIPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 75 }}
              aria-sort="ascending"
              onClick={() => getSorting("betROIPercent", UsersummaryData, setSummaryData, sortingIs, setSorting, activeSortName, setActiveSort)}
            >
              Bet ROI %
            </th>
            <th
              className={`min-w-125px sorting ${activeSortName === "parleyRisk" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Product: activate to sort column ascending"
              style={{ width: 300 }}
              onClick={() => getSorting("parleyRisk", UsersummaryData, setSummaryData, sortingIs, setSorting, activeSortName, setActiveSort)}
            >
              Parlays
            </th>
            <th
              className={`min-w-125px sorting ${activeSortName === "win" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Created Date: activate to sort column ascending"
              style={{ width: 300 }}
              onClick={() => getSorting("win", UsersummaryData, setSummaryData, sortingIs, setSorting, activeSortName, setActiveSort)}
            >
              Total Margin
            </th>
            <th
              className={`min-w-75px sorting ${activeSortName === "averageROIPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Product: activate to sort column ascending"
              style={{ width: 75 }}
              onClick={() => getSorting("averageROIPercent", UsersummaryData, setSummaryData, sortingIs, setSorting, activeSortName, setActiveSort)}
            >
              ROI %
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold">
          {UsersummaryData.map((obj: any, index: number) => (
            <tr className="bg-table-row-1" key={index}>
              {/* <td className="text-gray-800 text-start ps-4 text-center">{index + 1}</td> */}
              {/* <td style={{width: 175, position: "sticky", left: 0, background: "white", zIndex: 1}} className="text-gray-800 text-start ps-4" >
                {obj?.firstName
                  ? stringNameLength(obj?.firstName, obj?.lastName, 15, 15)
                  : "-"}
               
              </td> */}
             <td  className="table-column text-gray-800 text-start" style={{ width:150, position: "sticky", left: 0, background: "white", zIndex: 1}}>
              {
                obj.screenName
                ?
                <>
                { 
                    obj?.profilePic && !obj.profilePic.includes("-blob")
                    ?
                    <div className="symbol symbol-35px symbol-circle me-3">
                        <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.fullName ? obj.fullName : ""}/>
                    </div> 
                    :
                    <div className="symbol symbol-35px symbol-circle me-3">
                        <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                            {obj?.fullName ? obj.fullName[0].toUpperCase() : ""}
                        </span>
                    </div>
                }
                  {obj?.fullName ? stringNameLength(obj?.fullName, "", 10, 10) : obj.screenName}
                </>
                :
                <>
                
                  {
                    obj?.profilePic && !obj.profilePic.includes("-blob")
                    ?
                    <div className="symbol symbol-35px symbol-circle me-3">
                        <img alt="Pic" src={ImageUrl+ obj.profilePic} title={obj?.fullName ? obj.fullName : ""}/>
                    </div> 
                    :
                    <div className="symbol symbol-35px symbol-circle me-3">
                        <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                            {obj.fullName ? obj.fullName[0].toUpperCase() : ""}
                        </span>
                    </div>
                  }
                  {obj?.fullName ? stringNameLength(obj?.fullName, "", 10, 10) : "-"}
                </>
              }
              </td>
              
           
              <td>{obj?.correctBets !== null && getSummaryBetsIsNumber( obj.correctBets)}</td>
              <td>
                {obj?.betRisk !== null && getSummaryBets(obj?.betRisk)} 
              </td>
             
              <td>{obj?.betROIPercent !== null && getSummaryBetsIsPercent(obj.betROIPercent)}</td>
              <td>{obj?.parleyRisk !== null && getSummaryBets(obj?.parleyRisk)}</td>
              <td>{obj?.win !== null && getSummaryBets(obj?.win)}</td>
            
              <td>{obj?.averageROIPercent !== null && getSummaryBetsIsPercent(obj.averageROIPercent )}</td>
            </tr>
          ))}
        </tbody>
      </table>
        :
        <div className="text-center no-data-found d-flex align-items-center justify-content-center">
          {
            props?.isPublicProfileUser
            ?
            "No picks are locked yet"
            :
            "Data Not found..."
          }
        </div>}
    </div>
  );
};
export default SummaryEventBetslip;
