import PreviousEventBets from "../PreviousBets/PreviousEventBets";
import PreviousEventParlay from "../PreviousParlays/PreviousEventParlay";
import PreviousSummary from "../PreviousSummary/PreviousSummary";
import Select from "react-select";
import { connect } from "react-redux";
import {
  previousSchedule,
  userPreviousBet,
  userPreviousParlay,
  userSummary,
  getProfilePreSummary
} from "../../../../../Store/action/Events.action";
import { useEffect, useRef, useState } from "react";
import Spinner from "../../../../../Common/Spinner/Spinner";
import { customStyles } from "../../../Club/AllEventCalculation/Calculation";
import { problity } from "../../../Club/EventBetsDetails/EventsParlays/ParlayCalculation/ParlayCalculation";
import { userClickedEventDetail } from "../../../../../Store/action/UserInfo.action";

const PreviewEvents = (props?: any) => {
  const [optionsIs, setOptions] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [betType, setBetType] = useState<any>("1");
  const [previousbetList, setpreviousbetList] = useState<any>(null);
  const [Loading, setLoading] = useState<boolean>(false);
  const [prebetLoader, setPrebetLoader] = useState<boolean>(false);
  const [preParayLoader, setPreParlayLoader] = useState<boolean>(false);
  const [previousParlayList, setPreviousParlayList] = useState<any>(null)
  const [previousBetTotal, setPreviousBetTotal] = useState<any>(null);
  const [parlayLock, setParlayLock] = useState<boolean>(false);
  const [parlayevents, setParlayEvents] = useState<Iparlayevents[]>([
    {
      scheduleId: 0, 
      pickedTeam: "", 
      riskId: ""
    }
  ]);
  const [userSummaryLoader, setUserSummaryLoader] = useState<boolean>(false)
  const [userSummaryData, setUserSummaryData] = useState<any>([])
  const [sortingIs , setSorting] = useState<boolean>(false)
  const [activeSortName , setActiveSort] = useState<string>("")

  const previousEventRef = useRef<HTMLDivElement>(null)

  const handleEventType = (type: string) => {
    setBetType(type);
  };

  useEffect(() => {
    getBetdata();

    // ----- getting summary data ---
    const payload = { date: selectedOption?.value, event_name : selectedOption?.event_name, betType:3 };
    getSummaryData(payload)
  }, [betType, selectedOption]);

  useEffect(() => {
    setLoading(true);
    props.$previousSchedule();
  }, []);

  const handleChange = (data: any) => {
    setSelectedOption(data);
  };


  const getParlayRiskData = () => {
    if (previousParlayList?.length) {
      const a = previousParlayList.map((w: any) => {
        const meData = w?.events?.find((l: any) => l?.isMe === true);
        return {
          scheduleId: w.id,
          pickedTeam: meData ? meData.pickedTeam : "",
          riskId: meData ? meData.riskId : "",
          problity : meData?.pickedTeam ? problity(w, meData.pickedTeam) : ""
        };
      });
      setParlayEvents(a);
    }
  };

  const checkParlayLocked = () => {
    if (previousParlayList?.length) {
      const isLocked = previousParlayList.some((s: any) => s.isLock === true);
      setParlayLock(isLocked);
    }
  };


  useEffect(() => {
    checkParlayLocked();
    getParlayRiskData();
  }, [previousParlayList]);

  useEffect(() => {
    const eventData = props._previousSchedule.previousSchedule;
    if (eventData?.status === 1 && Loading) {
      setOptions(eventData.data);
      setLoading(false);
      const ObjIs =
        eventData?.data && eventData?.data[0];
      const defaultValueIs = {
        value: ObjIs?.date_event,
        label: ObjIs?.event_name,
        ...ObjIs,
      };
      setSelectedOption(defaultValueIs);
    }
  }, [props._previousSchedule]);

  const getPreviousBet = (data :IAllBetForUser) =>{
      props.$userPreviousBet(data);
      setPrebetLoader(true);
  }

  const getParlayBet = (data : IAllBetForUser) => {
     props.$userPreviousParlay(data)
     setPreParlayLoader(true)
  }

  const getSummaryData = (data : any) => {
    setUserSummaryLoader(true)
    props.$getProfilePreSummary(data)
  }

  const getBetdata = () => {
    if(selectedOption?.value){
    const payload = { date: selectedOption?.value, event_name : selectedOption?.event_name, betType };

    if (payload &&  payload?.betType === "1") {   

      getPreviousBet(payload)
    }else if(payload &&  payload?.betType === "2"){

      getParlayBet(payload)
    }else if(payload && payload?.betType === "3"){
      getSummaryData(payload)
    }
  }
  };

  useEffect(() => {
    const data = props._userPreviousBet.userPreBetIs;
    if (data?.status === "1") {
      setpreviousbetList(data?.data);
      setPreviousBetTotal(data?.BetTotal)
      setPrebetLoader(false);
    }
  }, [props._userPreviousBet]);

  useEffect(() =>{
    const data = props._userPreviousParlay.userPreParlayIs;
    if (data?.status === "1") {
      setPreviousParlayList(data?.data);
      setPreParlayLoader(false);
    }
  },[props._userPreviousParlay])

  useEffect(() => {
    const summaryIs = props._getProfilePreSummary.ProfilePreSummary;
    if (summaryIs?.status === "1") {
      setUserSummaryLoader(false);
      setUserSummaryData(summaryIs?.data);
    }
  }, [props._getProfilePreSummary]);

  const options = optionsIs?.map((obj: any) => {
    let data = { value: obj?.date_event,
                 label: obj?.event_name,
                 ...obj 
               };
    return data;
  });

  // const newOptions = options.filter((value:any, index:any, self:any) =>
  //   index === self.findIndex((t:any) => (
  //     t.value === value.value && t.label === value.label
  //   ))
  // )

  useEffect(() => {
    const clickedEvent = props._userClickedEvent
    if(clickedEvent && options && options.length){
      const foundEvent = options.find((ele:any) => ele.label === clickedEvent.event_name)
      if(foundEvent){
        setSelectedOption(foundEvent)
        props.$userEventClick()
        if (!previousEventRef.current) throw Error("divRef is not assigned");
        setTimeout(() => {
        previousEventRef?.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        },20)
        // setTimeout(() => {
        //   window.scrollBy({top: -300, behavior: "smooth"})  
        // }, 200) 
      }
    }
  }, [props._userClickedEvent])


  return (
    <section className="mb-15 myProfileCard" >
      <div className="row" ref={previousEventRef}>
        <div className="col-sm-12">
          <div className="accordion" id="kt_accordion_3">
            <div className="accordion-item">
              <h2 className="accordion-header" id="kt_accordion_1_header_3">
                <button className="accordion-button accordion-button-cu bg-white text-dark fw-bolder fs-2">
                  Previous Events
                  <span style={{right: "11px"}} className="right_side flex-wrap text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
                    {/* <span>{selectedOption?.event_name}</span>{" "} */}
                    <span
                      style={{ minWidth: 400 }}
                      className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3"
                    >
                      <Select
                        options={options}
                        onChange={handleChange}
                        value={selectedOption}
                        menuPortalTarget={document.body}
                        styles={customStyles}
                      />
                    </span>
                  </span>
                  <span
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_1_body_3"
                    aria-expanded="true"
                    aria-controls="kt_accordion_1_body_3"
                  ></span>
                </button>
              </h2>
              <div
                id="kt_accordion_1_body_3"
                className="accordion-collapse collapse show"
                aria-labelledby="kt_accordion_1_header_3"
                data-bs-parent="#kt_accordion_3"
              >
                <div className="accordion-body">
                  <div className="card-xl-stretch">
                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 pt-5 px-2">
                      <div className="card-toolbar">
                        <ul className="nav">
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_4_tab_1"
                              onClick={() => handleEventType("1")}
                            >
                              Bets
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_4_tab_2"
                              onClick={() => handleEventType("2")}
                            >
                              Parlays
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_4_tab_3"
                              onClick={() => handleEventType("3")}
                            >
                              Summary
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body py-3 px-2">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="kt_table_widget_4_tab_1"
                        >
                          {prebetLoader ? 
                          <Spinner color="text-dark  spinner-border-sm text-center" />:
                          <PreviousEventBets userSummaryData={userSummaryData} previousbetList={previousbetList} previousBetTotal = {previousBetTotal}/>}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_4_tab_2"
                        >
                          {preParayLoader ? 
                          <Spinner color="text-dark  spinner-border-sm text-center" />:
                          <PreviousEventParlay previousParlayList = {previousParlayList}
                          userSummaryData={userSummaryData}
                          parlayevents={parlayevents}
                          setParlayEvents={setParlayEvents}
                          parlayLock={parlayLock}
                          />}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_4_tab_3"
                        >
                          {userSummaryLoader ? 
                          <Spinner color="text-dark  spinner-border-sm text-center" />:
                          <PreviousSummary userSummaryData = {userSummaryData} setUserSummaryData = {setUserSummaryData} sortingIs ={sortingIs} 
                          setSorting = {setSorting} activeSortName ={activeSortName} setActiveSort = {setActiveSort}/>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapState = ({ previousSchedule, userPreviousBet, userPreviousParlay, userSummary, getProfilePreSummary, userProfile }: any) => {
  return {
    _previousSchedule: previousSchedule,
    _userPreviousBet : userPreviousBet,
    _userPreviousParlay : userPreviousParlay,
    _userSummary : userSummary,
    _getProfilePreSummary: getProfilePreSummary,
    _userClickedEvent: userProfile.userClickedEvent
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $previousSchedule: () => dispatchEvent(previousSchedule()),
  $userPreviousBet: (values?: IAllBetForUser) => dispatchEvent(userPreviousBet(values)),
  $userPreviousParlay: (values?: IAllBetForUser) => dispatchEvent(userPreviousParlay(values)),
  $userSummary: (values?: IAllBetForUser) => dispatchEvent(userSummary(values)),
  $getProfilePreSummary: (values?: IAllBetForUser) => dispatchEvent(getProfilePreSummary(values)),
  $userEventClick: (eventData:any) => dispatchEvent(userClickedEventDetail(eventData))
});
export default connect(mapState, mapDispatch)(PreviewEvents);
