import EventBets from "../UserEventBets/EventBets";
import ParlaysEventBetslip from "../UserParlayEventBetslip/ParlaysEventBetslip";
import SummaryEventBetslip from "../UserSummaryEvent/SummaryEventBetslip";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  sheduleByYear,
  userSummary,
  userEventByDate,
  joinEvent,
  userParlayBydate,
  joinParlayEvent,
  userBetBydateSuccess,
  teamList
} from "../../../../../Store/action/Events.action";
import { problity } from "../../../Club/EventBetsDetails/EventsParlays/ParlayCalculation/ParlayCalculation";
import Spinner, { ButtonSpinner } from "../../../../../Common/Spinner/Spinner";
import { joiningClubDetails, userClickedEventDetail } from "../../../../../Store/action/UserInfo.action";
import moment from "moment";
import Countdown from 'react-countdown';
import { getCurrentWin } from "../../../Club/AllEventCalculation/Calculation";
import { getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import LiveGif from "../../../../../assets/images/live.gif"

const EventBetslip = (props?: any) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [Loading, setLoading] = useState<boolean>(false);
  const [betType, setBetType] = useState<string>("1");
  const [UserteamList, setTeamList] = useState<any>(null);
  const [UserbetData, setBetData] = useState<any>(null);
  const [UserteamLoader, setTeamLoader] = useState<boolean>(false);
  const [IsLocked, setLocked] = useState<boolean>(false);
  const [UserparlayLoader, setParlayLoader] = useState<boolean>(false);
  const [UserEventParlayTeam, setParlayTeam] = useState<any>(null);
  const [UsersummaryData, setSummaryData] = useState<any>(null);
  const [UsersummaryLoader, setSummaryLoader] = useState<boolean>(false);
  const [sortingIs , setSorting] = useState<boolean>(false)
  const [activeSortName , setActiveSort] = useState<string>("")
  const [Loader, setLoader] = useState<boolean>(false);
  const [statusIs, setStatus] = useState<string>("0");
  const [parlayevents, setParlayEvents] = useState<any>([]);
  const [risk, setrisk] = useState<number>(0);
  const [parlaybetLoader, setParlaybetLoader] = useState<boolean>(false);
  const [UserparlayLock, setParlayLock] = useState<boolean>(false);
  const [totalWinIs , setTotalWin] = useState<any>(0)
  const [ dateDiffIs , setDateDiff ] = useState<any>()
  const [selectedBet, setSelectedBet] = useState<any>()
  const eventBetslipRef = useRef<HTMLDivElement>(null)
 
  const liveDateTIme =  props?._userEventByDate?.userEventIs?.data?.slice(-1)[0]?.date_event
  const getRiskData = () => {
    if (UserteamList?.length) {
      const a = UserteamList.map((w: any) => {
        const meData = w.events.find((l: any) => l.isMe === true);
        return {
          scheduleId: w.id,
          risk: meData ? meData.risk : "",
          pickedTeam: meData ? meData.pickedTeam : "",
          win: meData ? meData.win : "",
          moneyLine: (meData?.pickedTeam==2 ? w?.moneyline_away : w?.moneyline_home)
        };
      });
      setBetData(a);
    }
  };

  const checkBetLocked = () => {
    if (UserteamList?.length) {
      const isLocked = UserteamList.some((s: any) => s.isLock === false) ? false : true;
      setLocked(isLocked);
    }
  };

  useEffect(() => {
    getRiskData();
    checkBetLocked();
    // data();
  }, [UserteamList]);

  useEffect(() => {
    const clickedEvent = props._userClickedEvent
    if(selectedOption && clickedEvent){
      if(selectedOption?.event_name === clickedEvent?.event_name  &&  selectedOption?.date_event === clickedEvent?.date_event){
        if (!eventBetslipRef.current) throw Error("divRef is not assigned");
        setTimeout(() => {
          eventBetslipRef?.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
          },20)
        // window.scrollTo({ top: 0, behavior: "smooth"})
        props.$userEventClick({})
        
      }
    }
  }, [props._userClickedEvent])

  const checkParlayLocked = () => {
    if (UserEventParlayTeam?.length) {
      const isLocked = UserEventParlayTeam.some((s: any) => s.isLock === true);
      setParlayLock(isLocked);
    }
  };

  const getParlayRiskData = () => {
    if (UserEventParlayTeam?.length) {
      const a = UserEventParlayTeam.map((w: any) => {
        const meData = w.events.find((l: any) => l.isMe === true);
        return {
          scheduleId: w.id,
          pickedTeam: meData ? meData.pickedTeam : "",
          riskId: meData ? meData.riskId : "",
          problity : meData?.pickedTeam ? problity(w, meData.pickedTeam) : "",
          moneyLine: (meData?.pickedTeam && !isNaN(meData?.pickedTeam)) ? (meData?.pickedTeam===2 ? w?.moneyline_away : w?.moneyline_home) : ""
        };
      });
      setParlayEvents(a);
    }
  };

  useEffect(() => {
    checkParlayLocked();
    getParlayRiskData();
    
  }, [UserEventParlayTeam]);

  const handleEventType = (type: string) => {
    setBetType(type);
  };

  useEffect(() => {
    setLoading(true);
    props.$sheduleByYear();
  }, []);

  useEffect(() => {
    const eventData = props._sheduleByYear.sheduleIs;
    if (eventData?.status === 1 && Loading) {
      setLoading(false);
      const ObjIs = eventData?.data && eventData?.data[0];
      const defaultValueIs = {
        label: ObjIs?.date_event,
        ...ObjIs,
      };
      setSelectedOption(defaultValueIs);
    }
  }, [props._sheduleByYear]);

  //save draft nd lock **************

  const getbets = (status: string, handleSaveDraft:boolean = true) => {
    // const events = UserbetData?.filter((el: any) => el.risk);
    const dataIs = { status, betType, events: UserbetData };
    if (dataIs?.events?.filter((b:any)=>(b.risk !== "" && b.risk !== null)).length > 0) {
      if(handleSaveDraft){
        setLoader(true);
      }      
      props.$joinEvent(dataIs);
    } else {
      alert("Atleast 1 risk is Required..");
    }
  };

  useEffect(() => {
    if(selectedBet){
      const dataIs = { status:selectedBet && selectedBet[0]?.status ? selectedBet[0]?.status : "2", betType, events : selectedBet };  
      if(dataIs?.status === "2"){
        setLoader(true)
      }
      props.$joinEvent(dataIs);
    }
  }, [selectedBet])

  const getParlays = (status: string) => {
    const mainCardFightTime = UserEventParlayTeam?.find((ele:any) => ele?.event_card)?.eventCardTime
    const lastCardDuration = getDiffrenceOfTimeInMinutes(mainCardFightTime)
    let eventsData: any = [];
    parlayevents?.forEach((el: any) => {
      const riskId = el?.riskId ? el.riskId : "";
      const a =
        !el.riskId && el.pickedTeam
          ? { ...el, action: 1, riskId }
          : el?.riskId && el?.pickedTeam
          ? { ...el, action: 2, riskId }
          : el?.riskId && !el?.pickedTeam
          ? { ...el, action: 3, riskId }
          : { ...el, action: "", riskId };
      eventsData.push(a);
    });
    const events = eventsData?.filter((el: any) => el.action);    
    const noParlayBetIs = events.some((obj : any) => (obj.action !== 3))
    if(!noParlayBetIs){
      setrisk(0)
    }
    const dataIs = { status, betType, risk:  noParlayBetIs ? risk : 0, events, totalWin: totalWinIs };

    if(lastCardDuration > 0){
      if (dataIs) {
        const {events} = dataIs
        const selectedData = UserEventParlayTeam.filter((ele:any) => events.find((el:any) => el.scheduleId === ele.id))
        if(Boolean(selectedData?.length)){
          for(let i = 0; i < selectedData.length; i++){
            if(getDiffrenceOfTimeInMinutes(selectedData[i].eventCardTime) <= 0 ){

              alert("Some of the fighters you selected are no longer available. Please update your Parlay before locking in")
                getTeam() 
                return
            }
          }
        }
          if (risk) {
            if (dataIs?.events?.filter((e:any)=>e.action !== 3).length > 1) {
              setParlaybetLoader(true);
              props.$joinParlayEvent(dataIs);
            } else {
              alert("You must select at least 2 fighters for a Parlay");
            }
        } else {
          alert("You must enter your Risk amount to lock in Bets");
        }
      }
    }else{
      alert("Sorry, but the window to add a Parlay has passed")
      setParlaybetLoader(true); 
      getTeam()
    }
  };

  const handleSaveDraft = (status: string, toshowLoader:boolean) => {
    if (betType === "1") {
      getbets(status, toshowLoader);
    } else if (betType === "2") {
      getParlays(status);
    }
    setStatus(status);
  };
  //save draft nd lock*************/

  //   table Data of all type*****************

  const getSummaryData = (data: any) => {
    props.$userSummary(data);
    setSummaryLoader(true);
  };

  const getBetData = (data: any) => {
    props.$userEventByDate(data);
    setTeamLoader(true);
  };

  const getParlayData = (data: any) => {
    props.$userParlayBydate(data);
    setParlayLoader(true);
  };

  const getTeam = () => {
    if(selectedOption?.label){
    const payload = { date: selectedOption?.label, event_name: selectedOption?.event_name };
    if (payload && betType === "1") {
      getBetData(payload);
    } else if (payload && betType === "2") {
      getParlayData(payload);
      getSummaryData(payload);
    } else if (payload && betType === "3") {
      getSummaryData(payload);
    }
  }
  };

  useEffect(() => {
    getTeam();
  }, [betType, selectedOption]);


  useEffect(() => {
    const summaryIs = props._userSummary.userSummaryIs;
    if (summaryIs?.status === "1") {
      setSummaryLoader(false);
      setSummaryData(summaryIs?.data);
    }
  }, [props._userSummary]);

  useEffect(() => {
    const parlayData = props._userParlayBydate.userParlayIs;

    if (parlayData?.status === "1") {
      setParlayTeam(parlayData?.data);
      setParlayLoader(false);
    }
  }, [props._userParlayBydate]);

  useEffect(() => {
    const data = props._userEventByDate.userEventIs;
    if (data?.status === "1") {
      const teamDataOld = data?.data
      const teamDataWithWinAmount = teamDataOld.map((el:any) => {
        return {
          ...el,
          events: el?.events?.map((ev:any)=> ({
            ...ev,
            win: ev?.pickedTeam===1 ? getCurrentWin(ev?.risk, el?.moneyline_home) : (ev?.pickedTeam===2 ? getCurrentWin(ev?.risk, el?.moneyline_away) : "0")
          }))
        }
      })
      setTeamList(teamDataWithWinAmount);
      setTeamLoader(false);
    }
  }, [props._userEventByDate?.userEventIs]);

  useEffect(() => {
    const joinedData = props._joinEvent.joinEventData;
    if (joinedData && Loader) {
      setLoader(false);
      getTeam();
    }
  }, [props._joinEvent]);

  useEffect(() => {
    const joinedParlayData = props._joinParlayEvent.joinParlayIs;
    if (joinedParlayData?.status === 1 && parlaybetLoader) {
      setParlaybetLoader(false);
      getTeam();
    }
  }, [props._joinParlayEvent]);

  //   table Data of all type*****************/

  // const getdateDuration = () => {
  //   const now = moment(new Date()); //todays date
  //   const end = moment(selectedOption?.date_event); // another date
  //   const duration1 = moment.duration(end.diff(now));
  //   setDateDiff(Date.now() + duration1?.asSeconds()*1000)
  // }

  // useEffect(() =>{
  //   getdateDuration()
  //   setInterval(() =>{
  //     getdateDuration()
  //   },10000)
  // },[selectedOption?.date_event])    
  

  const renderer = ({days, hours, minutes, seconds, completed }:any) => {
    if(days===0 && hours===0 && (minutes===14 || minutes===15) && seconds===0){
      getTeam()
    }
    if (completed) {
      return <></>
    } else {
      // Render a countdown
      return <span className="text-danger" >{days + " Days " + hours + " Hrs " + minutes + " Min "}</span>
    }
  };


  return (
    <section className="myProfileCard mb-10" ref={eventBetslipRef} >  
      <div className="row">
        <div className="col-sm-12">
          <div className="accordion" id="kt_accordion_2">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button accordion-button-cu bg-white flex-wrap text-dark fw-bolder fs-2">
                  Event Betslip
                  {
                    getDiffrenceOfTimeInMinutes(liveDateTIme) <= 0
                    ?
                    <span className="mx-2 bold text-danger"><img style={{width: "30px"}} src={LiveGif} />LIVE</span>
                    :
                    ""
                  }
                  <span className="right_side right_side_unset text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
                    <span style={{marginRight:10}}>{selectedOption?.event_name}</span>{" "}
                    {/* <span
                      style={{ minWidth: 160 }}
                      className="text-muted cu-react-select-size-small ms-3"
                    >
                      <Select
                        options={options}
                        onChange={handleChange}
                        value={selectedOption}
                        styles={customStyles}
                      />
                    </span> */}
                  </span>
                  <span
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_1_body_2"
                    aria-expanded="true"
                    aria-controls="kt_accordion_1_body_2"
                  ></span>
                  <div className="col-12 mt-3 mb-0">
                  <small>
                     {
                      selectedOption?.date_event && ((!IsLocked && betType === "1") || (!UserparlayLock && betType === "2")) 
                      ?
                      <div className="row">
                        <div className="col right_side text-muted">
                        {getDiffrenceOfTimeInMinutes(selectedOption?.date_event) > 0 ? 
                       liveDateTIme && <p className="event-countdown text-danger">Event starts in: <Countdown date={liveDateTIme} renderer={renderer} /></p>
                        :
                        ""}

                        </div>                        
                      </div>
                      :
                      ""
                    }
                  </small>
                  </div>
                </button>
              </h2>
              <div
                id="kt_accordion_1_body_2"
                className="accordion-collapse collapse show"
                aria-labelledby="kt_accordion_1_header_2"
                data-bs-parent="#kt_accordion_2"
              >
                <div className="accordion-body pt-3">                  
                  <div className="card-xl-stretch">
                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 pt-3 px-2">
                      <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                        <ul className="nav">
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_1"
                              onClick={() => handleEventType("1")}
                            >
                              Bets
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_2"
                              onClick={() => handleEventType("2")}
                            >
                              Parlays
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_3"
                              onClick={() => handleEventType("3")}
                            >
                              Summary
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Save draft nd lock button */}
                      {betType !== "3" && getDiffrenceOfTimeInMinutes(selectedOption?.eventCardTime) > 0 && (
                        <div className="card-title flex-column text-end text-sm-start mob-w-100 mb-4 mb-sm-0 lockButtons">
                          {(
                          
                            (!UserparlayLock && betType === "2")
                          ) && (
                            <button
                              type="button"
                              className="btn btn-sm btn-light fw-bold"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Saving as a Draft will store your initial thoughts, but your picks will not be officially recorded and other users will not see them until you Lock In."
                              onClick={() => handleSaveDraft("1", true)}
                            >
                              {(Loader || parlaybetLoader) &&
                              statusIs === "1" ? (
                                <ButtonSpinner color="text-light  spinner-border-sm " />
                              ) : (
                                "Save Draft"
                              )}
                            </button>
                          )}
                          <button
                            type="button"
                            className={`btn btn-sm btn-orange fw-bold ms-4 ${
                              (IsLocked && betType === "1") ||
                              (UserparlayLock && betType === "2")
                                ? "not-allowed"
                                : ""
                            }`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Once you select Lock In, other users can see your picks and you will not be able to change or add to them"
                            onClick={
                              !(
                                (IsLocked && betType === "1") ||
                                (UserparlayLock && betType === "2")
                              )
                                ? () => handleSaveDraft("2", true)
                                : () => {}
                            }
                          >
                            {
                              !( (IsLocked && betType === "1") || (UserparlayLock && betType === "2") ) 
                              ? 
                              ( (Loader || parlaybetLoader) && statusIs === "2" 
                                  ?  
                                  <ButtonSpinner color="text-light  spinner-border-sm " />
                                  :
                                  betType === "1" ? "Lock All" : "Lock In"
                              )
                              :
                              "Locked"
                            }
                          </button>
                        </div>
                      )}
                      {/* Save draft nd lock button */}
                    </div>
                    <div className="card-body py-3 px-2 table_wrapper">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="kt_table_widget_1_tab_1"
                        >
                          {UserteamLoader ? (
                            <Spinner color="text-black  spinner-border-sm " />
                          ) : (
                            <EventBets
                              UserteamList={UserteamList}
                              UserbetData={UserbetData}
                              setBetData={setBetData}
                              IsLocked = {IsLocked}
                              handleSaveDraft={handleSaveDraft}    
                              setSelectedBet={setSelectedBet}    
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_2"
                        >
                          {UserparlayLoader ? (
                            <Spinner color="text-black  spinner-border-sm " />
                          ) : (
                            <ParlaysEventBetslip
                              UserEventParlayTeam={UserEventParlayTeam}
                              betType={betType}
                              risk={risk}
                              setrisk={setrisk}
                              parlayevents={parlayevents}
                              setParlayEvents={setParlayEvents}
                              UserparlayLock = {UserparlayLock}
                              setTotalWin={setTotalWin}
                              UsersummaryData={UsersummaryData}
                              setSummaryData = {setSummaryData}
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_3"
                        >
                          {UsersummaryLoader ? (
                            <ButtonSpinner color="text-black  spinner-border-sm " />
                          ) : (
                            <SummaryEventBetslip
                              UsersummaryData={UsersummaryData} setSummaryData = {setSummaryData} sortingIs ={sortingIs} 
                              setSorting = {setSorting} activeSortName ={activeSortName} setActiveSort = {setActiveSort}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
// export default EventBetslip;
const mapState = ({
  sheduleByYear,
  userSummary,
  userEventByDate,
  joinEvent,
  userParlayBydate,
  joinParlayEvent,
  userProfile,
  userEventIs,
  prelimsCard
}: any) => {
  
  return {
    _sheduleByYear: sheduleByYear,
    _userSummary: userSummary,
    _userEventByDate: userEventByDate,
    _joinEvent: joinEvent,
    _userParlayBydate: userParlayBydate,
    _joinParlayEvent: joinParlayEvent,
    _userClickedEvent: userProfile.userClickedEvent,
    _userEventIs:userEventIs
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $sheduleByYear: () => dispatchEvent(sheduleByYear()),
  $userSummary: (values?: IAllBetForUser) => dispatchEvent(userSummary(values)),
  $userEventByDate: (values?: IAllBetForUser) => dispatchEvent(userEventByDate(values)),
  $joinEvent: (values?: IDraftForbets) => dispatchEvent(joinEvent(values)),
  $userParlayBydate: (values?: IAllBetForUser) => dispatchEvent(userParlayBydate(values)),
  $joinParlayEvent: (values?: IDraftForParlay) => dispatchEvent(joinParlayEvent(values)),
  $userEventClick: (eventData:any) => dispatchEvent(userClickedEventDetail(eventData)),
  $userEventIs:(values ?: any)=>dispatchEvent(userBetBydateSuccess(values))
});
export default connect(mapState, mapDispatch)(EventBetslip);
