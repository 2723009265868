import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import  {firebaseConfig}  from "../../firebaseConfig"
import { put, call } from "redux-saga/effects";
import { postApiWithoutToken, putApiWithoutToken } from "../api-interface/api-interface";
import {
    loginDataSuccess,
    signupDataSuccess,
    signUpDataErr,
    loginDataErr,
    forgotPwdSuccess,
    forgotPwdErr,
    resetPwdSuccess,
    resetPwdError
} from "../action/Login.action";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Login *********
export function* onloginData(payload:ILoginPayload):Generator<ILoginGenerator |{}>{  
  let data = payload.values; 
  try {
    if (data) {
      const response : any | {} = yield call(postApiWithoutToken,`/user/signin`,data); 
      logEvent(analytics, "Sign In", {email:data?.email});      
      if (response?.status === 200) {        
        yield put(loginDataSuccess(response.data));
      }
    }
  } catch (error : any) {  
    yield put(loginDataErr(error.response.data));
  }
}

// sign up ************
export function* onSignUpData(payload:ISignUpPayload):any{  
    let data = payload.values; 
    try {
      if (data) {
        const response = yield call(postApiWithoutToken,'/user/signup',data);    
        if (response.status === 200) {         
          yield put(signupDataSuccess(response.data));
        }
      }
    } catch (error : any) {
      yield put(signUpDataErr(error.response.data));
    }
  }

  // forgot pwd **********

  export function* onforgetPwd(payload:IforgotPwdRes):any{  
    let data = payload.values; 
    try {
      if (data) {
        const response = yield call(postApiWithoutToken,'/user/forgotPassword',data);  
        if (response.status === 200) {          
          yield put(forgotPwdSuccess(response.data));
        }
      }
    } catch (error : any) {
      yield put(forgotPwdErr(error.response.data));
    }
  }

  // Reset Pwd ********
  export function* onResetPwd(payload:IResetPwdPayload):any{  
    let data = payload.values; 
    try {
      if (data) {
        const response = yield call(putApiWithoutToken,'/user/resetPassword',data);  
        if (response.status === 200) {
          yield put(resetPwdSuccess(response.data));
        }
      }
    } catch (error : any) {
      yield put(resetPwdError(error.response.data));
    }
  }