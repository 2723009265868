import EventBetslip from './ProfileEventBetslip/UserEventBetslip/EventBetslip';
import ProfileCard from './ProfileCard/ProfileCard';
import MyStats from './MyStats/MyStats';
import LastEvent from './LastEvents/LastEvent';
import LastFiveEvent from './LastEvents/LastFiveEvents';
import Layout from '../../../Common/Layout';
import { connect } from "react-redux";
import { publicStatsEventsScore } from "../../../Store/action/Events.action";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PublicProfileBets from './MyBets/PublicProfileBets';
import PublicProfileClubs from './MyClubs/PublicProfileClubs';
import UserPublicProfilePreviousEvents from './ProfilePreviousEvents/PreviousBetslip/UserPublicProfilePreviousEvents';
import UserPublicEventBetslip from './ProfileEventBetslip/UserEventBetslip/UserPublicEventBetslip';
import MyBio from './MyBio/MyBio';
import { useHistory } from 'react-router-dom';

const UserPublicProfile = (props ?: any) => {
    const[statsData , setStats] = useState<any>()
    const[eventsData ,setEvents] = useState<any>([])
    const[user, setUser] = useState<any>({})
    const[bets, setBets] = useState<any>([])
    const[clubs, setClubs] = useState<any>([])
    const[last5EventDataNew, setLast5Data] = useState({})
    const[pastSchedules, setPastSchedules] = useState<any>([])
    const[latestSchedule, setLatestSchedule] = useState<any>([])
    const[LoadingIs, setLoading] = useState<boolean>(false)

    const params = useParams<any>()
    const {screenName} = params
    const loggedUserScreenName = props?._userProfile?.userDataIs?.data?.screenName
    if(loggedUserScreenName === screenName){
      props?.history?.push("/myprofile")
    }  
      const history=useHistory()
    
    useEffect(() => {
      setLoading(true)
      props.$publicStatsEventsScore(screenName)
    }, [history.location.pathname])

    const getResponse = (data : any) => {
       setLoading(false)
       setStats(data?.myStats)
       setEvents(data?.last_five_events_data)
       setUser(data?.user)
       setBets(data?.bets)
       setClubs(data?.clubs)
       setPastSchedules(data?.pastScheduleData)
       setLatestSchedule(data?.latestScheduleData)
       setLast5Data(data?.last_five_event_data_new || {})
    }

    useEffect(() => {
      const data = props._statsEventsScore.publicScoreDataIs?.data;
      if(data){
        getResponse(data)
      }
    }, [props._statsEventsScore])

    return (
        <Layout options="fill_Header" uservisible="visible">
            <div className="main-content">
                <div className="container pt-15 publicProfileContainer">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <ProfileCard user = {user} statsData = {statsData} isPublicProfile={true}/>
                            <MyBio LoadingIs = {LoadingIs} bio={user?.bio} isPublicProfile={true} />
                            <MyStats LoadingIs = {LoadingIs} isPublicProfile={true} statsData = {statsData}/>
                            <LastEvent LoadingIs = {LoadingIs} eventsData = {eventsData}/>
                            <LastFiveEvent LoadingIs = {LoadingIs} eventsData = {eventsData} last5EventDataNew={last5EventDataNew} />
                            <PublicProfileClubs isPublicProfile={true} clubs={clubs}/>
                            <PublicProfileBets isPublicProfile={true} bets={bets} />
                        </div>
                        <div className="col-sm-12 col-lg-8 table_wrapper">
                            <UserPublicEventBetslip latestSchedule={latestSchedule}/>
                            <UserPublicProfilePreviousEvents pastSchedules={pastSchedules}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
// export default MyProfile
const mapState = ({ statsEventsScore, userProfile }: any) => {
    return {
      _statsEventsScore: statsEventsScore,
      _userProfile: userProfile
    };
  };
  const mapDispatch = (dispatchEvent?: any) => ({
    $publicStatsEventsScore: (screenName:any) => dispatchEvent(publicStatsEventsScore(screenName)),
  });
  export default connect(
    mapState,
    mapDispatch
  )(UserPublicProfile);
