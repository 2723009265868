import React, { useEffect, useState } from 'react';
import Facebook from '../../../assets/images/Icons/facebook.svg';
import Instagram from '../../../assets/images/Icons/instagram.svg';
import Twitter from '../../../assets/images/Icons/twitter.svg';
import Linkedin from '../../../assets/images/Icons/linkedin.png'; 
import AboutOne from '../../../assets/images/plays/h-1.png';
import AboutTwo from '../../../assets/images/plays/h-2.png';
import AboutThree from '../../../assets/images/plays/h-3.png';
import AboutFour from '../../../assets/images/plays/h-4.png';
import AboutFive from '../../../assets/images/plays/h-5.png';
import AboutSix from '../../../assets/images/plays/h-6.png';
import AboutSeven from '../../../assets/images/plays/h-7.png';
import Header from '../../../Common/Header';
import HTP1 from "../../../assets/images/plays/HTP-1.png"
import HTP2 from "../../../assets/images/plays/HTP-2.png"
import HTP3 from "../../../assets/images/plays/HTP-3.png"
import HTP4 from "../../../assets/images/plays/HTP-4.png"
import HTP5 from "../../../assets/images/plays/HTP-5.png"
import HTP6 from "../../../assets/images/plays/HTP-6.png"
import HTP7 from "../../../assets/images/plays/HTP-7.png"
import HTP8 from "../../../assets/images/plays/HTP-8.png"
import HTP9 from "../../../assets/images/plays/HTP-9.png"
import HTP10 from "../../../assets/images/plays/HTP-10.jpg"
import HTP11 from "../../../assets/images/plays/HTP-11.jpg"
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const HowToPlay = (props?:any) => {

    const [ userHasClub, setUserHasClub ] = useState(false) 
    const [emailExist, setEmailExist] = useState()

    useEffect(() => {
       const email = props?._userProfile?.userDataIs?.data?.email
       setEmailExist(email)
        const hasClub = props?._userProfile?.userDataIs?.data?.hasClub
        setUserHasClub(hasClub)
    }, [props?._userProfile])
    const { userProfile } = props?.userProfile || {}
    return (
        <React.Fragment>
            <section>
                {/* Header Component */}
                <Header options="fill_Header"/>


                {/* How To Play  */}
                <div className="main-content">

                <div className="how_to_play_top_btns">
                        <div className="d-flex align-items-center justify-content-between flex-wrap border-0 px-2">
                            <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                                <ul className="nav howtoplay_options">
                                <li className="nav-item">
                                    <a className="nav-link btn btn-color-muted btn-active btn-active-light-primary fw-bold fs-6 px-4 me-1"
                                    data-bs-toggle="tab"
                                    href="">
                                    Skip
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                    className="nav-link btn btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 fs-6"
                                    data-bs-toggle="tab"
                                    href="">
                                    Create Your Club
                                    </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="how_to_play_banner">
                        <h3 className="fs-4hx text-white">How To Play</h3>
                    </div>
                    <div className="container howtoplay_container py-15">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="card">
                        {/*begin::Body*/}
                        <div className="card-body p-lg-15">
                            {/*begin::Layout*/}
                            <div className="d-flex flex-column flex-lg-row"> 
                            {/*begin::Content*/}
                            <div className="flex-lg-row-fluid">
                                {/* Disclaimer */}
                                <div className="text-center mb-20">
                                    <h2 className="fs-2hx text-dark mb-5">New to MMA BetClub or need a refresher?</h2>
                                    <h3>The instructions below will help you get going.</h3>
                                    <h3>Not familiar with MMA betting/American odds? Check out <a href = "https://www.mmaodds.com/mma-betting/" target="_blank">this link</a> for more information before getting started.</h3>
                                    <h3>Think you have a gambling problem? <a href = 'https://www.ncpgambling.org/programs-resources/resources/' target="_blank">Click here </a> for resources to help.</h3>
                                </div>

                                {/* Demo-1 */}
                                {/* <div className="row mb-20 align-items-center">
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">1.</span>Login or Sign Up to begin.
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-5 text-center text-lg-start">
                                        <div className="text-center">
                                            <img src={AboutOne} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row mb-20">
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="col-12">
                                            <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold">
                                                <span className="number_steps mb-5">1.</span>Login or Sign Up to begin.
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className="text-center shadow rounded mb-5">
                                                <img src={HTP1} className="img-fluid rounded" alt="..."/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-5 mb-5 text-center text-lg-start">
                                        <div className="text-center shadow rounded mb-5">
                                            <img src={HTP2} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div>

                                {/* Demo-2 */}
                                {/* <div className="row mb-20 align-items-center"> 
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">2.</span>Join or create a private Club (maximum of 15 members each and are invite-only) or compete on your own. Players can join multiple Clubs, but only create one of their own.
                                        </div>
                                    </div> 
                                    <div className="col-sm-12 col-lg-5 text-center text-lg-start">
                                        <div className="">
                                            <img src={AboutTwo} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row mb-4 align-items-center"> 
                                    <div className="col-sm-12 col-lg-12 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">2.</span>Select "Go To My Profile" to accept an invite to join a private Club (maximum of 50 members each and invite-only), create your own Club, or participate as an individual. You can be a part of multiple Clubs, but only create one of your own.
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-20">
                                    <div className="col-sm-12 col-lg-5 mb-5 text-center text-lg-start">
                                        <div className="text-center shadow rounded mb-5">
                                            <img src={HTP3} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-7 mb-5 text-lg-start">
                                        <div className="shadow rounded mb-5">
                                            <img src={HTP4} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div>
                                    


                                {/* Demo-3 */}
                                {/* <div className="row mb-20 align-items-center">  
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">3.</span>Review the fight card for the upcoming event and determine the fights you want to bet on, which can be placed from Club pages or your profile. For each event, players get one entry for their set of individual bets and one parlay, which are applied to all Clubs that they participate in.
                                        </div>
                                    </div> 
                                    <div className="col-sm-12 col-lg-5 text-center text-lg-start">
                                        <div className="">
                                            <img src={AboutThree} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row mb-20 align-items-center">  
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">3.</span>Review the fight card for the upcoming event and determine the fights you want to bet on, which can be placed from Club pages or your Profile. For each event, you get one entry for your set of individual bets and one parlay, which are applied to all Clubs that you participate in. DO NOT LOCK IN YOUR BETS OR PARLAY UNTIL ALL OF YOUR PREDICTIONS ARE FINALIZED. Note that Parlays and your full set of Bets lock in separately. Once you click on "Lock in", you will not be able to change or add picks to them, so if you are still deciding, you can use Save Draft, or the site will auto-save.
                                        </div>
                                    </div> 
                                    <div className="col-sm-12 col-lg-5 text-center text-lg-start">
                                        <div className="shadow rounded mb-5">
                                            <img src={HTP5} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div>

                                {/* Demo-4 */}
                                {/* <div className="row mb-20 align-items-center">  
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">4.</span>DO NOT LOCK IN YOUR BETS UNTIL ALL OF YOUR PREDICTIONS ARE FINALIZED. If you are still deciding, you can use Save Draft, or the site will auto-save.
                                        </div>
                                    </div> 
                                    <div className="col-sm-12 col-lg-5 text-center text-sm-left">
                                        <div className="">
                                            <img src={AboutFour} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row mb-20 align-items-center">  
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">4.</span>In the Risk column of your Event Betslip when you are on the Bets tab, enter the amount you’d like to “bet” (used for confidence level) in the field of the row of the fighter(s) you believe will win. You can only select one fighter in each fight.
                                        </div>
                                    </div> 
                                    <div className="col-sm-12 col-lg-5 text-center text-sm-left">
                                        <div className="shadow rounded mb-5">
                                            <img src={HTP6} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div>


                                {/* Demo-5 */}
                                {/* <div className="row mb-20 align-items-center text-center text-lg-start"> 
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">5.</span>In the Risk column of your Event Betslip when you are on the Bets tab, enter the amount you’d like to “bet” (used for confidence level) in the field of the row of the fighter(s) you believe will win. You can only select one fighter in each fight.
                                        </div>
                                    </div>  
                                    <div className="col-sm-12 col-lg-5">
                                        <div className="">
                                            <img src={AboutFive} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>  
                                </div> */}
                                <div className="row mb-4"> 
                                    <div className="col-sm-12 col-lg-12 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">5.</span>From the Parlays tab of your Event Betslip, click on the “Pick” button in the row of each fighter that you would like to add to your parlay. You can only select one fighter in each fight, and you must select at least two fights to include in your parlay. At the bottom of the table in the Parlay Bet Amount/ROI row, enter the amount you would like to bet (used for confidence level, placing bets for actual money elsewhere not required) and see what your ROI is calculated to be.
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-20">
                                    <div className="col-sm-12 col-lg-6 mb-5 text-center text-lg-start">
                                        <div className="text-center shadow rounded mb-5">
                                            <img src={HTP7} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6 mb-5 text-lg-start">
                                        <div className="shadow rounded mb-5">
                                            <img src={HTP8} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div>

                                {/* Demo-6 */}
                                {/* <div className="row mb-20 align-items-center"> 
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">6.</span>From the Parlays tab of your Event Betslip, click on the “Pick” button in the row of each fighter that you would like to add to your parlay. At the bottom of the table in the Parlay Bet Amount row, enter the amount you would like to bet (used for confidence level, placing bets for actual money elsewhere not required) in the field. You can only select one fighter in each fight, and you must select at least two fights to include in your parlay.
                                        </div>
                                    </div>  
                                    <div className="col-sm-12 col-lg-5 text-center text-lg-start">
                                        <div className="">
                                            <img src={AboutSix} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div> 
                                </div> */}
                                <div className="row mb-20 align-items-center"> 
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">6.</span>Once you Lock In your predictions, they will officially post to the site and become visible to other MMA BetClub users. You will be unable to make changes to your predictions after Locking In. You are able to view the picks of other users who have already locked in their bets before placing your own.
                                        </div>
                                    </div>  
                                    <div className="col-sm-12 col-lg-5 text-center text-lg-start">
                                        <div className="shadow rounded mb-5">
                                            <img src={HTP9} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div> 
                                </div>

                                {/* Demo-7 */}
                                {/* <div className="row mb-20 align-items-center"> 
                                    <div className="col-sm-12 col-lg-7 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">7.</span>Once you Lock In your bets, they will officially post to the site and become visible to other MMA BetClub users. You will be unable to make changes to your predictions after Locking In. You are able to view the picks of other users who have already locked in their bets before placing your own.
                                        </div>
                                    </div>  
                                    <div className="col-sm-12 col-lg-5 text-center text-lg-start">
                                        <div className="">
                                            <img src={AboutSeven} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div> 
                                </div> */}
                                <div className="row mb-4"> 
                                    <div className="col-sm-12 col-lg-12 pe-35 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                                        <div className="mob-w-100 mx-auto fs-2 text-dark fw-bold"><span className="number_steps">7.</span>When the action starts or after the event completes, see how you stack up against others in your Clubs and the Global Leaderboard! Score 1 point for every correct Bet you place and 2 points for every fight picked in a winning Parlay.</div>
                                    </div>
                                </div>
                                <div className="row mb-20">
                                    <div className="col-sm-12 col-lg-6 mb-5 text-center text-lg-start">
                                        <div className="text-center shadow rounded mb-5">
                                            <img src={HTP10} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6 mb-5 text-lg-start">
                                        <div className="shadow rounded mb-5">
                                            <img src={HTP11} className="img-fluid rounded" alt="..."/>
                                        </div>
                                    </div>
                                </div>

                                {
                                    emailExist  
                                    ? 
                                    <div className="text-center">
                                        <Link to="/myprofile" className="btn btn-orange m-3 text-capitalize fw-bolder">
                                            Go to My Profile
                                        </Link>
                                        {
                                            !userHasClub 
                                            ?
                                            <Link to="/" className="btn btn-orange m-3 text-capitalize fw-bolder">
                                                Create your Club
                                            </Link>
                                            :
                                            <Link to="/" className="btn btn-orange m-3 text-capitalize fw-bolder">
                                                Go To My Clubs
                                            </Link>
                                        }
                                        <Link to="/leaderboard" className="btn btn-orange m-3 text-capitalize fw-bolder">
                                            Go To Leaderboards
                                        </Link>
                                    </div> 
                                    : 
                                    <div className="text-center">
                                        <Link to="/registration" className="btn btn-lg btn-orange m-3 px-19" >
                                            <span className="indicator-label fw-bolder">Sign Up</span>
                                        </Link>
                                        <Link to="/login" type="submit" className="btn btn-lg btn-orange m-3 px-20" >
                                            <span className="indicator-label fw-bolder">Log In</span>
                                        </Link>
                                    </div>
                                }

                                {/* Disclaimer */}
                                <div className="text-center mb-15">
                                    {/* <h3 className="fs-2hx text-dark mb-5">DISCLAIMER</h3> */}
                                    <div className="w-75 mob-w-100 mx-auto fs-4 text-dark fw-bold disclaimer-statement">DISCLAIMER :- MMA BetClub is a Fantasy MMA/fight prediction site, and is NOT A GAMBLING SITE. No money is paid out or collected for “bets” that are made on the site.
                                    </div>
                                </div> 
                            </div>
                            {/*end::Content*/}
                            </div>
                            {/*end::Layout*/}
                                {/*begin::Card*/}
                                <div className="card mb-4 bg-light text-center"> 
                                <div className="card-body py-12  social_icons"> 
                                    <a href="https://www.facebook.com/MMABetClub" target="_blank" rel="noreferrer" className="mx-4">
                                        <img
                                        src={Facebook}
                                        className="h-30px my-2"
                                        alt=""
                                        />
                                    </a> 
                                    <a href="https://www.instagram.com/mma_betclub/" target="_blank" rel="noreferrer" className="mx-4">
                                        <img
                                        src={Instagram}
                                        className="h-30px my-2"
                                        alt=""
                                        />
                                    </a> 
                                    <a href="https://twitter.com/MMA_Betclub" target="_blank" rel="noreferrer" className="mx-4">
                                        <img
                                        src={Twitter}
                                        className="h-30px my-2"
                                        alt=""
                                        />
                                    </a> 
                                    <a href="https://www.linkedin.com/company/mma-betclub" target="_blank" rel="noreferrer" className="mx-4">
                                        <img
                                        src={Linkedin}
                                        className="h-30px my-2"
                                        alt=""
                                        />
                                    </a> 
                                </div> 
                            </div> 
                        </div> 
                        </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section> 
        </React.Fragment>
    )
}

const mapState = ({ userProfile }: any) => ( { _userProfile: userProfile })
export default connect(mapState, {})(HowToPlay);
  