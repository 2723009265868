
import { Switch, withRouter } from 'react-router-dom';
import Home from '../Component/Authenticated/Home/Home';
import Registration from '../Component/Unauthenticated/SignUp/Registration';
import Login from '../Component/Unauthenticated/SignIn/Login';
import ResetPassword from '../Component/Unauthenticated/ForgetPwd/ResetPwd/ResetPassword';
import CreateNewPassword from '../Component/Unauthenticated/ForgetPwd/CreatePwd/CreateNewPassword';
import CreateYourClub from '../Component/Authenticated/Club/CreateClub/CreateYourClub';
import ClubDetails from '../Component/Authenticated/Club/ClubDetails/ClubDetails';
import ClubList from '../Component/Authenticated/Club/ClubList/ClubList';
import MemberListDetails from '../Component/Authenticated/Club/ClubListDetails/ClubListDetails';
import Leaderboard from '../Component/Authenticated/Leaderboard/Leaderboard';
import MyProfile from '../Component/Authenticated/MyProfile/MyProfile';
import PersonalSettings from '../Component/Authenticated/Settings/PersonalSetting/PersonalSettings';
import ChangePassword from '../Component/Authenticated/Settings/ChangePwd/ChangePassword';
import InviteFriends from '../Component/Authenticated/Settings/Invitefrnd/InviteFriends';
import PrivateRoute from './PrivateRoute';
import HowToPlay from '../Component/Unauthenticated/HowToPlay';
import AboutUs from '../Component/Unauthenticated/AboutUs';
import ufcContest from '../Pages/UfcContest/ufcContest';
import PublicRoute from './PublicRoute';
import JoinClub from "../Component/Authenticated/Club/JoinClub/JoinClub";
import ClubMemberList from "../Component/Authenticated/Club/PerticularClubMemberList/ClubMemberList";
import UserPublicProfile from '../Component/Authenticated/MyProfile/UserPublicProfile';
import News from '../Component/Unauthenticated/News/News';
import UserPublicProfileStats from '../Component/Authenticated/MyProfile/UserPublicProfileStats';

const RootRouting = (props:any) => {

    const RouteDetails = [
        { path: "/", componentIs: Home, private: false },
        { path: "/registration", componentIs: Registration, private: false },
        { path: "/login", componentIs: Login, private: false },
        { path: "/resetpassword", componentIs: ResetPassword, private: false },
        { path: "/createnewpassword/:email/:token", componentIs: CreateNewPassword, private: false },
        { path: "/createyourclub", componentIs: CreateYourClub, private: true },
        { path: "/clubdetails", componentIs: ClubDetails, private: true },
        { path: "/clublist", componentIs: ClubList, private: true },
        { path: "/clubMembersList", componentIs: ClubMemberList, private: true },
        { path: "/clublist/:email/:code", componentIs: ClubList, private: true },
        { path: "/clubdetails/:id", componentIs: MemberListDetails, private: true },
        { path: "/leaderboard", componentIs: Leaderboard, private: true },
        { path: "/myprofile", componentIs: MyProfile, private: true },
        { path: "/personalsettings", componentIs: PersonalSettings, private: true },
        { path: "/changepassword", componentIs: ChangePassword, private: true },
        { path: "/invitefriends", componentIs: InviteFriends, private: true },
        { path: "/howtoplay", componentIs: HowToPlay, private: false },
        { path: "/aboutus", componentIs: AboutUs, private: false },
        { path: "/joinclub/:code", componentIs: JoinClub, private: true },
        { path: "/player/:screenName", componentIs: UserPublicProfile, private: false },
        { path: "/player/:screenName/stats", componentIs: UserPublicProfileStats, private: false  },
        { path: "/news", componentIs: News, private: false },
        // { path: "/ufc-contest", componentIs: ufcContest, private: false }
    ]

    return (
        <Switch>
            {RouteDetails.map((obj, index) => {
                return (
                    obj.private
                    ?
                    <PrivateRoute exact path={obj.path} component={obj.componentIs} key={index} {...props}/>
                    :
                    <PublicRoute exact path={obj.path} component={obj.componentIs} key={index} {...props}/>
                )
            })}
        </Switch>
    )
}

export default withRouter(RootRouting);