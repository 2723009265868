import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {allTimeMakingChampionshipRun, allTimePoundForPound } from "../../../../../Store/action/Leaderboard.action";
import Spinner from "../../../../../Common/Spinner/Spinner"
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";



const MakingChampionshipMob = (props?: any) => {

    const [data, setData] = useState([])
    // const [activeSortName , setActiveSort] = useState<string>("maxWinStreak")
    // const [sortingIs , setSorting] = useState<boolean>(false)
    // const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>

    useEffect(() =>{
        props?.$allTimeMakingChampionshipRun()
    },[])

    useEffect(() =>{
        if(props._allTimeMakingChampionshipRun && props._allTimeMakingChampionshipRun.length){
            setData(props._allTimeMakingChampionshipRun)
        }
    }, [props._allTimeMakingChampionshipRun])

    return (
        <>   
         <div className="container">
           <div className="row">
            <table>
            {
               data && data.length > 0
                     ?
                    data.map((obj: any, index: number) => (
                      <tbody className="text-gray-600 fw-bold">
                            <tr className="bg-table-row-1">  
                                    <td className="text-gray-800 text-start " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                                        {
                                            obj.screenName
                                            ?
                                            <Link to={`/player/${obj.screenName}`} className="text-dark">
                                                <div className="row mt-3">
                                                    { 
                                                        obj?.profilePic && !obj.profilePic.includes("-blob")
                                                        ?
                                                        <div className="col-2 symbol symbol-35px symbol-circle">
                                                            <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.fullName ? obj.fullName : ""}/>
                                                        </div> 
                                                        :
                                                        <div className="col-2 symbol symbol-35px symbol-circle">
                                                            <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                                                                {obj?.fullName ? obj.fullName[0].toUpperCase() : ""}
                                                            </span>
                                                        </div>
                                                    }
                                                    <div className="col-5">
                                                    <span className="leaderPosition">{index+1}</span>{obj.fullName}
                                                        <span className="text-muted fw-bold d-block fs-base">Margin</span>
                                                    </div>
                                                     <div className="col-5 mt-8 table-ml text-muted">
                                                      { !isNaN(obj.totalMargin) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(obj.totalMargin)): obj.totalMargin}
                                                     </div>
                                                </div>
                                            </Link>
                                            : ""
                                            
                                        }
                                    </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="row mt-3">
                                    <div className="col-8 table-ml1">
                                        ROI %
                                     </div>
                                     <div className="col-4 table-ml">
                                      {obj.averageROI + (!isNaN(obj.averageROI) ? "%" : "")}
                                     </div>
                                </div>
                            </td> 
                        </tr>
                        <tr>
                            <td>
                              <div className="row">
                                 <div className="col-8 table-ml1">Bet Win %</div>
                                 <div className="col-4 table-ml">{obj.totalPoints}</div>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>  
                                 <div className="row">
                                    <div className="col-8 table-ml1">Parlay win</div>
                                    <div className="col-4 table-ml">{obj.totalParleyWinPercentage + "%"}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="row">
                                    <div className="col-8 table-ml1">Last Event win %</div>
                                    <div className="col-4 table-ml">{obj.lastEventBetWinPercent + "%"}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-8 table-ml1">
                                    Last Event Parlay Win
                                </div>
                                <div className=" col-4 table-ml">
                                {obj.lastEventParlayWin === "false" ? "No": obj.lastEventParlayWin === "true" ? "Yes" : obj.lastEventParlayWin}
                                </div>
                            </div>
                        </tr>
                    </tbody>
                )) 
                :
                <Spinner />    
            } 
            </table>
        </div>
     </div>
     
    </>
    )
}

const mapState = ({ leaderboardReducer }: any) => {
    return {
        _allTimeMakingChampionshipRun: leaderboardReducer.allTimeMakingChampionshipRun
    };
};

const mapDispatch = (dispatchEvent?: any) => ({
    $allTimeMakingChampionshipRun: () => dispatchEvent(allTimeMakingChampionshipRun()),
});

export default connect(mapState, mapDispatch)(MakingChampionshipMob)