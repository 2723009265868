import EventBets from "../UserEventBets/EventBets";
import ParlaysEventBetslip from "../UserParlayEventBetslip/ParlaysEventBetslip";
import SummaryEventBetslip from "../UserSummaryEvent/SummaryEventBetslip";
import { useEffect, useRef, useState } from "react";
import { problity } from "../../../Club/EventBetsDetails/EventsParlays/ParlayCalculation/ParlayCalculation";
import Spinner, { ButtonSpinner } from "../../../../../Common/Spinner/Spinner";
import moment from "moment";
import { getApi } from "../../../../../Store/api-interface/api-interface";
import { useParams } from "react-router";
import { getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import LiveGif from "../../../../../assets/images/live.gif"

const UserPublicEventBetslip = (props?: any) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [Loading, setLoading] = useState<boolean>(false);
  const [betType, setBetType] = useState<string>("1");
  const [UserteamList, setTeamList] = useState<any>(null);
  const [UserbetData, setBetData] = useState<any>(null);
  const [UserteamLoader, setTeamLoader] = useState<boolean>(false);
  const [IsLocked, setLocked] = useState<boolean>(false);
  const [UserparlayLoader, setParlayLoader] = useState<boolean>(false);
  const [UserEventParlayTeam, setParlayTeam] = useState<any>(null);
  const [UsersummaryData, setSummaryData] = useState<any>(null);
  const [UsersummaryLoader, setSummaryLoader] = useState<boolean>(false);
  const [sortingIs , setSorting] = useState<boolean>(false)
  const [activeSortName , setActiveSort] = useState<string>("")
  const [Loader, setLoader] = useState<boolean>(false);
  const [statusIs, setStatus] = useState<string>("0");
  const [parlayevents, setParlayEvents] = useState<any>([]);
  const [risk, setrisk] = useState<number>(0);
  const [parlaybetLoader, setParlaybetLoader] = useState<boolean>(false);
  const [UserparlayLock, setParlayLock] = useState<boolean>(false);
  const [totalWinIs , setTotalWin] = useState<any>(0)
  const [ dateDiffIs , setDateDiff ] = useState<any>()

  const eventBetslipRef = useRef<HTMLDivElement>(null)
  const params = useParams<any>()
  const { screenName } = params

  const getRiskData = () => {
    if (UserteamList?.length) {
      const a = UserteamList.map((w: any) => {
        const meData = w.events.find((l: any) => l.isMe === true);
        return {
          scheduleId: w.id,
          risk: meData ? meData.risk : "",
          pickedTeam: meData ? meData.pickedTeam : "",
          win: meData ? meData.win : "",
          isThisEventLocked: w?.isLock
        };
      });
      setBetData(a);
    }
  };

  const checkBetLocked = () => {
    if (UserteamList?.length) {
      const isLocked = UserteamList.some((s: any) => s.isLock === true);
      setLocked(isLocked);
    }
  };

  useEffect(() => {
    getRiskData();
    checkBetLocked();
  }, [UserteamList]);

  const checkParlayLocked = () => {
    if (UserEventParlayTeam?.length) {
      const isLocked = UserEventParlayTeam.some((s: any) => s.isLock === true);
      setParlayLock(isLocked);
    }
  };

  const getParlayRiskData = () => {
    if (UserEventParlayTeam?.length) {
      const a = UserEventParlayTeam.map((w: any) => {
        const meData = w.events.find((l: any) => l.isMe === true);
        return {
          scheduleId: w.id,
          pickedTeam: meData ? meData.pickedTeam : "",
          riskId: meData ? meData.riskId : "",
          problity : meData?.pickedTeam ? problity(w, meData.pickedTeam) : ""
        };
      });
      setParlayEvents(a);
    }
  };

  useEffect(() => {
    checkParlayLocked();
    getParlayRiskData();
  }, [UserEventParlayTeam]);

  const handleEventType = (type: string) => {
    setBetType(type);
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    const eventData = props?.latestSchedule;
    if (eventData ) {
      setLoading(false);
      const ObjIs = eventData && eventData[0];
      const defaultValueIs = {
        label: ObjIs?.date_event,
        ...ObjIs,
      };
      setSelectedOption(defaultValueIs);
    }
  }, [props?.latestSchedule]);

  const getBetData = (data: any) => {
    getApi(`/user/getPublicUserBetEventByDate?date=${data?.date}&eventName=${data.event_name}&screenName=${screenName}`)
    .then((res)=>{
        const sortedByAdminIndex = res?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        res.data.data = sortedByAdminIndex
        if(res?.data?.status === "1"){
            setTeamList(res?.data?.data);
            setTeamLoader(false);
        }
    })
    setTeamLoader(true)
  };

  const getParlayData = (data: any) => {
    getApi(`/user/getPublicUserParlayEventByDate?date=${data?.date}&eventName=${data.event_name}&screenName=${screenName}`)
    .then((res)=>{
        const sortedByAdminIndex = res?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        res.data.data = sortedByAdminIndex
        if(res?.data?.status === "1"){
            setParlayTeam(res?.data?.data);
            setParlayLoader(false);
        }
    })
    setParlayLoader(true)
  };

  const getSummaryData = (data: any) => {
    getApi(`/user/getPublicUserEventSummaryByDate?date=${data?.date}&eventName=${data.event_name}&screenName=${screenName}`)
    .then((res)=>{
        const { data } = res
        if(data?.status === "1"){
            setSummaryData(data?.data);
            setSummaryLoader(false);
        }
    })
    setSummaryLoader(true)
  };

  const getTeam = () => {
    if(selectedOption?.label){
    const payload = { date: selectedOption?.label, event_name: selectedOption?.event_name };
    if (payload && betType === "1") {
      getBetData(payload);
    } else if (payload && betType === "2") {
      getParlayData(payload);
      getSummaryData(payload);
    } else if (payload && betType === "3") {
      getSummaryData(payload);
    }
  }
  };

  useEffect(() => {
    getTeam();
  }, [betType, selectedOption]);

  //   table Data of all type*****************/

  useEffect(() =>{
    setInterval(() =>{
      const now = moment(new Date()); //todays date
      const end = moment(selectedOption?.date_event); // another date
      const duration1 = moment.duration(end.diff(now));
      setDateDiff(Date.now() + duration1?.asSeconds()*1000)
    },1000)
  },[selectedOption?.date_event])    

  return (
    <section className="mb-10" ref={eventBetslipRef} >  
      <div className="row">
        <div className="col-sm-12">
          <div className="accordion" id="kt_accordion_2">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button accordion-button-cu bg-white flex-wrap text-dark fw-bolder fs-2">
                  Event Betslip
                  {
                    getDiffrenceOfTimeInMinutes(selectedOption?.date_event) <= 0
                    ?
                    <span className="mx-2 bold text-danger"><img style={{width: "30px"}} src={LiveGif} />LIVE</span>
                    :
                    ""
                  }
                  <span className="right_side right_side_unset text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
                    <span style={{marginRight:10}}>{selectedOption?.event_name}</span>{" "}
                    {/* <span
                      style={{ minWidth: 160 }}
                      className="text-muted cu-react-select-size-small ms-3"
                    >
                      <Select
                        options={options}
                        onChange={handleChange}
                        value={selectedOption}
                        styles={customStyles}
                      />
                    </span> */}
                  </span>
                  <span
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_1_body_2"
                    aria-expanded="true"
                    aria-controls="kt_accordion_1_body_2"
                  ></span>
                </button>
              </h2>
              <div
                id="kt_accordion_1_body_2"
                className="accordion-collapse collapse show"
                aria-labelledby="kt_accordion_1_header_2"
                data-bs-parent="#kt_accordion_2"
              >
                <div className="accordion-body pt-3">
                  <div className="card-xl-stretch">
                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 pt-3 px-2">
                      <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                        <ul className="nav">
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_1"
                              onClick={() => handleEventType("1")}
                            >
                              Bets
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_2"
                              onClick={() => handleEventType("2")}
                            >
                              Parlays
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_3"
                              onClick={() => handleEventType("3")}
                            >
                              Summary
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Save draft nd lock button */}
                      {betType !== "3" && (
                        <div className="card-title flex-column text-end text-sm-start mob-w-100 mb-4 mb-sm-0 ">
                          {/* <button
                            type="button"
                            className={`btn btn-sm btn-orange fw-bold ms-4 ${
                              (IsLocked && betType === "1") ||
                              (UserparlayLock && betType === "2")
                                ? "not-allowed"
                                : ""
                            }`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Once you select Lock In, other users can see your picks and you will not be able to change or add to them"
                            onClick={
                              !(
                                (IsLocked && betType === "1") ||
                                (UserparlayLock && betType === "2")
                              )
                                ? () => {}
                                : () => {}
                            }
                          >
                            {! ? (
                              (Loader || parlaybetLoader) && statusIs === "2" 
                              ? 
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                              :
                              "Lock In"
                              ) : (
                              "Locked"
                            )}
                          </button> */}
                          {
                            (IsLocked && betType === "1") || (UserparlayLock && betType === "2")
                            ?
                            <button
                              type="button"
                              className="btn btn-sm btn-orange fw-bold ms-4"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                            >Locked
                            </button>
                            :
                            ""
                          }
                        </div>
                      )}
                      {/* Save draft nd lock button */}
                    </div>
                    <div className="card-body py-3 px-2 table_wrapper">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="kt_table_widget_1_tab_1"
                        >
                          {UserteamLoader ? (
                            <Spinner color="text-black  spinner-border-sm " />
                          ) : (
                            <EventBets
                              UserteamList={UserteamList}
                              UserbetData={UserbetData}
                              setBetData={setBetData}
                              IsLocked = {IsLocked}
                              isPublicProfileUser={true}
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_2"
                        >
                          {UserparlayLoader ? (
                            <Spinner color="text-black  spinner-border-sm " />
                          ) : (
                            <ParlaysEventBetslip
                              UserEventParlayTeam={UserEventParlayTeam}
                              betType={betType}
                              risk={risk}
                              setrisk={setrisk}
                              parlayevents={parlayevents}
                              setParlayEvents={setParlayEvents}
                              UserparlayLock = {UserparlayLock}
                              setTotalWin={setTotalWin}
                              isPublicProfileUser={true}
                              UsersummaryData={UsersummaryData}
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_3"
                        >
                          {UsersummaryLoader ? (
                            <Spinner color="text-black  spinner-border-sm " />
                          ) : (
                            <SummaryEventBetslip
                              UsersummaryData={UsersummaryData} setSummaryData = {setSummaryData} sortingIs ={sortingIs} 
                              setSorting = {setSorting} activeSortName ={activeSortName} setActiveSort = {setActiveSort}
                              isPublicProfileUser={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserPublicEventBetslip
