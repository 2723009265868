import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import  {firebaseConfig}  from "../../../firebaseConfig"
import { Link, useHistory } from "react-router-dom";
import Layout from "../../../Common/Layout";
import { Formik, ErrorMessage, Form, Field } from "formik";
import { connect } from "react-redux";
import queryString from 'query-string'
import { loginData } from "../../../Store/action/Login.action";
import { userProfile } from "../../../Store/action/UserInfo.action";
import { initialValues_signIn, validationSchema_signIn } from "./Validation";
import { useEffect, useState } from "react";
import { ButtonSpinner } from "../../../Common/Spinner/Spinner";
import Alert from "../../../Common/Alert/Alert";
import FormError from "../../../Common/FormError/FormError";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const Login = (props?: any) => {
  
  var validator = require("email-validator");
  const { $loginData, $userProfile, _loginData, _userProfile }: ILoginProps =
    props;
  const history = useHistory();
  // const invitedEmail = props.location.state?.split("/")[2];
  // const invitedcode = props.location.state?.split("/")[3];
  // const checkEmailIs = validator.validate(invitedEmail);
  // const user = { email: checkEmailIs ? invitedEmail : "", password: "" };
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [showPwdIs, setShowPwd] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });
  const [ user, setUser] = useState({ email: "", password: "", invitedEmail: "", invitedcode:"" })

  const qs = queryString.parse(props.location.search);

  useEffect(() => {
    if(qs?.email && qs?.clubCode){
      localStorage.removeItem("token")
      const checkEmailIs = validator.validate(qs?.email);
      setUser({
        email: checkEmailIs ? qs.email : "", 
        password:"",
        invitedEmail: qs.email,   
        invitedcode:qs.clubCode
      })
    }
  },[])
  
  
  const onSubmit = (values: ILogin) => {
    setLoader(true);
    $loginData(values);
  };

  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  const getUserProfile = () => {
    $userProfile();
  };

  const getUserResponse = (accessToken: string) => {
    localStorage.setItem("token", accessToken);
    accessToken && getUserProfile();
  };

  useEffect(() => {
    if (alert.activeIs) {
      setTimeout(() => {
        alert.statusIs === "alert-danger" && localStorage.removeItem("token");
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    const userData = _loginData?.loginDataIs;
    const userDataErr = _loginData?.loginDataErrIs;
    if (userData?.status === 1 && LoadingIs) {
      getUserResponse(userData?.data?.accessToken);
    } else if (userDataErr?.status === 0 && LoadingIs) {
      getAlert(userDataErr?.message, "alert-danger");
      setLoader(false);
    }
  }, [_loginData]);

  const getUserProfileRes = (clubCreatedIs?: boolean) => {
    if (user?.invitedEmail && user?.invitedcode) {
      history.push(`/clublist/${user?.invitedEmail}/${user?.invitedcode}`);
    } else if(qs?.r && qs?.r?.length) {
        history.push(qs.r)
    } else if (clubCreatedIs) {
      history.push({
        pathname: "/myprofile",
        state: {
          signUpIs: false,
        },
      });
    } else {
      history.push({
        pathname: "/createyourclub",
        state: {
          signUpIs: false,
          
        },
      });
    }
    setLoader(false);
  };

  const getUserProfileErr = () => {
    history.push({
      pathname: "/login",
    });
    localStorage.removeItem("token");
    setLoader(false);
  };

  useEffect(() => {
    const userProfile = _userProfile.userDataIs;
    const userProfileErr = _userProfile.userDataErrIs;
    if (userProfile.status === 1 && LoadingIs) {
      getUserProfileRes(userProfile?.data?.hasClub);
    } else if (userProfileErr && LoadingIs) {
      getUserProfileErr();
    }
  }, [_userProfile]);

  const setEmail = () => {
    history?.push({
      pathname: "/registration",
      state: props.location.state,
    });
  };

  const handleShowPwd = () => {
    setShowPwd(!showPwdIs);
  };
  

  return (
    <Layout options="fill_Header">
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="container">
            <div className="row">
              <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                {alert.activeIs && (
                  <Alert
                    statusIs={`alert ${alert.statusIs}`}
                    message={alert.msgIs}
                  />
                )}
                <Formik
                  initialValues={user.email ? user : initialValues_signIn}
                  validationSchema={validationSchema_signIn}
                  onSubmit={onSubmit}
                  validateOnMount
                  enableReinitialize
                >
                  {(formik) => (
                    <Form
                      className="form w-100"
                      noValidate
                      id="kt_sign_in_form"
                      action="#"
                    >
                      <div className="text-center mb-10">
                        <h1 className="text-dark mb-4 text-capitalize">
                          Welcome Back!
                        </h1>
                        <p className="text-gray-400 fw-bolder fs-4">
                          Sign in to MMA BetClub to get back in the cage.
                        </p>
                      </div>
                      <div className="fv-row mb-10">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Email
                        </label>
                        <Field
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.email &&
                            formik.errors.email &&
                            `border border-danger`
                          }`}
                          type="text"
                          name="email"
                          id="email"
                          autoComplete="off"
                          placeholder="Enter Email"
                          disabled={
                            user.email && user.email !== ":" ? true : false
                          }
                        />
                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="fv-row mb-10">
                        <div className="d-flex flex-stack mb-2">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            Password
                          </label>
                          
                        </div>
                        <div className="position-relative mb-3">
                          <Field
                            className={`form-control form-control-lg form-control-solid ${
                              formik.touched.password &&
                              formik.errors.password &&
                              `border border-danger`
                            }`}
                            type={showPwdIs ? `text` : `password`}
                            placeholder="Password"
                            name="password"
                            id="password"
                            autoComplete="off"
                            onChange={(e: any) =>
                              e.target.value.length < 41
                                ? formik.handleChange(e)
                                : null
                            }
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                            onClick={handleShowPwd}
                          >
                            <i
                              className={`bi ${
                                showPwdIs ? `bi-eye-slash` : `bi-eye`
                              } fs-2`}
                            />
                          </span>
                          <Link
                            to="/resetpassword"
                            className="link-primary fs-6 fw-bolder position-absolute end-0 forgotPassword"
                          >
                            Forgot Password ?
                          </Link>
                        </div>
                        <ErrorMessage
                          name="password"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          disabled={!formik.isValid || alert.activeIs}
                          type="submit"
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-5"
                        >
                          <span className="indicator-label fw-bolder">
                            {LoadingIs ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              `Log in`
                            )}
                          </span>
                        </button>
                      </div>
                      <div className="fv-row mb-10 ">
                        <div className="text-muted">
                          By signing up, you agree with our policies.
                        </div>
                      </div>
                      <div className="fv-row registerSection">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          New to MMA BetClub?
                        </label>
                        
                        {
                          Object.keys(qs)?.length && qs?.email
                          ?
                          <span
                            className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => history.push(`/registration?clubCode=${qs.clubCode}&inviteCode=${qs.inviteCode}&email=${qs.email}`)}
                          >
                            <span className="indicator-label fw-bolder">
                              Create An Account
                            </span>
                          </span>
                          :
                          Object.keys(qs)?.length && qs?.r
                          ?
                          <span
                            className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => history.push(`/registration?r=${qs.r}`)}
                          >
                            <span className="indicator-label fw-bolder">
                              Create An Account
                            </span>
                          </span>
                          :
                          <span
                            className="btn btn-flex registerBtn flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => history.push(`/registration`)}
                          >
                            <span className="indicator-label fw-bolder">
                              Create An Account
                            </span>
                          </span>
                        }
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapState = ({ loginData, userProfile }: any) => {
  return {
    _loginData: loginData,
    _userProfile: userProfile,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $loginData: (values?: ILogin) => dispatchEvent(loginData(values)),
  $userProfile: () => dispatchEvent(userProfile()),
});
export default connect(mapState, mapDispatch)(Login);
