import Layout from "../../../../Common/Layout";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  initialValues_createPwd,
  validationSchema_createPwd,
} from "./Validation";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { resetPwd } from "../../../../Store/action/Login.action";
import { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import Alert from "../../../../Common/Alert/Alert";
import FormError from "../../../../Common/FormError/FormError";
import PasswordStrengthBar from "../../../../Common/password-strength-bar";

const CreateNewPassword = (props?: any) => {
  const { $resetPwd, _resetPwd }: IResetPwdProps = props;
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [showPwdIs, setShowPwd] = useState<boolean>(false);
  const [showConfPwdIs, setShowConfPwd] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });

  const handleShowPwd = (status: string) => {
    if (status === "password") {
      setShowPwd(!showPwdIs);
    } else if (status === "confirmPassword") {
      setShowConfPwd(!showConfPwdIs);
    }
  };

  const onSubmit = (values: IcreatePwd) => {
    const emailIs: string = props?.match?.params?.email;
    const tokenIs: string = props?.match?.params?.token;
    const data = {
      email: emailIs,
      password: values.password,
      code: tokenIs,
    };
    setLoader(true);
    $resetPwd(data);
  };

  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  useEffect(() => {
    if (alert.activeIs) {
      setTimeout(() => {
        const token = props?.match?.params?.token;
        if (alert.statusIs === "alert-success") {
          localStorage.setItem("token", token);
          props?.history?.push("/clublist");
        }
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    const resetData = _resetPwd.resetPwdIs;
    const resetErr = _resetPwd.resetPwdErrIs;
    if (resetData?.status === 1 && LoadingIs) {
      getAlert(resetData?.message, "alert-success");
      setLoader(false);
    } else if (resetErr && LoadingIs) {
      getAlert(resetErr?.message, "alert-danger");
      setLoader(false);
    }
  }, [_resetPwd]);

  return (
    <Layout options="fill_Header">
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="container">
            <div className="row">
              <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                {alert.activeIs && (
                  <Alert
                    statusIs={`alert ${alert.statusIs}`}
                    message={alert.msgIs}
                  />
                )}
                <Formik
                  initialValues={initialValues_createPwd}
                  validationSchema={validationSchema_createPwd}
                  onSubmit={onSubmit}
                  validateOnMount
                >
                  {(formik) => (
                    <Form
                      className="form w-100"
                      noValidate
                      id="kt_sign_in_form"
                      action="#"
                    >
                      <div className="text-center mb-10">
                        <h1 className="text-dark mb-3">Create New Password</h1>
                      </div>
                      <div
                        className="mb-10 fv-row"
                        data-kt-password-meter="true"
                      >
                        <div className="mb-1">
                          <label className="form-label fw-bolder text-dark fs-6">
                            Password
                          </label>
                          <div className="position-relative mb-3">
                            <Field
                              className={`form-control form-control-lg form-control-solid ${
                                formik.touched.password &&
                                formik.errors.password &&
                                `border border-danger`
                              }`}
                              type={`${showPwdIs ? `text` : "password"}`}
                              placeholder=""
                              name="password"
                              id="password"
                              autoComplete="off"
                              onChange={(e: any) =>
                                e.target.value.length < 41
                                  ? formik.handleChange(e)
                                  : null
                              }
                            />
                            <span
                              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                              data-kt-password-meter-control="visibility"
                              onClick={() => handleShowPwd("password")}
                            >
                              <i
                                className={`bi ${
                                  showPwdIs ? `bi-eye-slash` : `bi-eye`
                                } fs-2`}
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            name="password"
                            render={(msg) => (
                              <FormError message={msg}></FormError>
                            )}
                          />
                          <PasswordStrengthBar
                            password={formik.values.password}
                          />
                        </div>
                        <div className="text-muted">
                          Use 8 or more characters with a mix of letters,
                          numbers &amp; symbols.
                        </div>
                      </div>

                      <div className="fv-row mb-10">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Confirm Password
                        </label>
                        <div className="position-relative mb-3">
                          <Field
                            className={`form-control form-control-lg form-control-solid ${
                              formik.touched.confirmPassword &&
                              formik.errors.confirmPassword &&
                              `border border-danger`
                            }`}
                            type={`${showConfPwdIs ? "text" : "password"}`}
                            name="confirmPassword"
                            id="confirmPassword"
                            autoComplete="off"
                            onChange={(e: any) =>
                              e.target.value.length < 41
                                ? formik.handleChange(e)
                                : null
                            }
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                            onClick={() => handleShowPwd("confirmPassword")}
                          >
                            <i
                              className={`bi ${
                                showConfPwdIs ? `bi-eye-slash` : `bi-eye`
                              } fs-2`}
                            />
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          disabled={!formik.isValid}
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-5"
                        >
                          <span className="indicator-label fw-bolder">
                            {LoadingIs ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              `Create`
                            )}
                          </span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapState = ({ resetPwd }: any) => {
  return {
    _resetPwd: resetPwd,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $resetPwd: (values?: IResetPwdArgu) => dispatchEvent(resetPwd(values)),
});
export default connect(mapState, mapDispatch)(CreateNewPassword);
