import React, { useEffect, useRef, useState } from "react";
import Spinner from "../../../../Common/Spinner/Spinner";
import fbImg from "../../../../../src/assets/images/Icons/facebook.svg"
import fbShareImg from "../../../../../src/assets/images/Icons/facebook-share-button.svg"
import twitterImg from "../../../../../src/assets/images/Icons/twitter.svg"
import whatsappImg from "../../../../../src/assets/images/Icons/whatsapp.svg"
import { getApi } from "../../../../Store/api-interface/api-interface";
import Modal from 'react-modal';
import { addStickyToTHead, removeStickyToTHead } from "../../Club/AllEventCalculation/Calculation";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlays: {
        background: "rgba(0, 0, 0, 0.7)"
    }
};



const MyStats = (props?: any) => {
    const { statsData, LoadingIs } = props;
    const [ noDataFound, setNoDataFound ] = useState(false)
    const [ loader, setLoader ] = useState<boolean>()
    const [ modal, setModal ] = useState<IShareModal>({show:false, imgUrl:"", caption: ""})

    useEffect(() => {
        setTimeout(() => setNoDataFound(true), 10000)
    }, [])

    const handleClick = async (type: string) => {
        setLoader(true)
        await getApi("/user/getUserStatsImage").then(res => {
            if(res.data.status){
                setModal({
                    show: true,
                    imgUrl: res.data.imgUrl,
                    caption: "Check out how I'm doing on MMA BetClub! - https://mmabetclub.com/"
                })
                removeStickyToTHead()
            }
            setLoader(false)
        })


        // const fbNavUrl = 'https://www.facebook.com/sharer/sharer.php?display=popup&u=' + url + "&quote=" +`\n Check out how I'm doing on MMA BetClub!`
        // const TwNavUrl = 'https://twitter.com/intent/tweet?text=' + url + `\n Check out how I'm doing on MMA BetClub!`
        // const WpNavUrl = "whatsapp://send?text=Check out how I'm doing on MMA BetClub! " + url
        // window.open( type === "fb" ? fbNavUrl : (type==="tw" ? TwNavUrl : WpNavUrl), '_blank');
    }

    const postToFB = () => {
        if(modal?.imgUrl) {
            const fbNavUrl = 'https://www.facebook.com/sharer/sharer.php?display=popup&u=' + modal.imgUrl + "&quote=" +`\n ${modal.caption}`
            window.open(fbNavUrl, '_blank');
            setModal({show:false, imgUrl:"", caption: ""})
        }
    }

    const closeModal = ()=> {
        setModal({show:false, imgUrl:"", caption: ""})
        addStickyToTHead()
    }
    

    return (
        <div className="card myProfileCard mb-4 mb-sm-10 mb-lg-10">
            <div className="card-header w-100" style={{paddingRight: "0"}}>
                <div className="card-title w-100 flex-column m-0">
                    <h3 className="fw-bolder w-100 mb-0 flex-row row">
                        <span className="d-flex align-items-center justify-content-between">
                            {props?.isPublicProfile ? "Player Stats" : "My Stats"}
                            {
                                loader
                                ?
                                <span>
                                    <div className="m-1 spinner-border-sm spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    {/* <div className="m-1 spinner-border-sm spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    <div className="m-1 spinner-border-sm spinner-border text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> */}
                                </span>
                                :
                                (
                                    props?.isPublicProfile
                                    ?
                                    ""
                                    :
                                    <>
                                    <img className="mx-1" width="90" onClick={() => handleClick("fb")} src={fbShareImg} /> 
                                    {/* <span className="">
                                        Share to Facebook
                                        <img className="mx-1" onClick={() => handleClick("fb")} src={fbImg} /> 
                                        <img className="mx-1" onClick={() => handleClick("tw")} src={twitterImg} width = "25px" />
                                        <img className="mx-1" onClick={() => handleClick("wa")} src={whatsappImg} width = "30px" /> 
                                    </span>*/}
                                    </>
                                )
                            }
                        </span>
                        
                    </h3>
                    
                </div>
            </div>
            <div className="card-body d-flex flex-column">
                {LoadingIs 
                ?
                ( noDataFound ? "No Data Found" : <Spinner color="text-black spinner-border-sm " />)
                :
                <React.Fragment>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-gray-900 text-hover-dark"
                        >
                            Weight Class
                        </span>
                    </div>
                    <div className="ms-auto">{statsData?.weightClass}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-gray-900 text-hover-dark"
                        >
                            Total Points
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{statsData?.totalPoints  ? statsData?.totalPoints : "-"}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-gray-900 text-hover-dark"
                        >
                            Global Ranking
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{statsData?.global_ranking  ? statsData?.global_ranking : "-"}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-gray-900 text-hover-dark"
                        >
                            Change since last event
                        </span>
                    </div>
                    <div className={`badge 
                                    ${ 
                                        (statsData?.change_since_last_event === "-" || statsData?.change_since_last_event === 0) 
                                        ?
                                        "badge-light"
                                        :
                                        (statsData?.change_since_last_event>0 ? "badge-success" : "badge-orange")
                                    }
                                     ms-auto`}>
                        {/* {statsData?.change_since_last_event ? (statsData?.change_since_last_event > 0 ? "+ " : "") + statsData?.change_since_last_event : '-'} */}
                        { 
                            (statsData?.change_since_last_event === "-" || statsData?.change_since_last_event === 0) 
                            ?
                            statsData?.change_since_last_event
                            :
                            (statsData?.change_since_last_event>0 ? "+"+statsData?.change_since_last_event : statsData?.change_since_last_event)
                        }
                    </div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-gray-900 text-hover-dark"
                        >
                            Bet Win %
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{statsData?.betWinPercent !== "-" ? statsData?.betWinPercent + "%" : "-"}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-gray-900 text-hover-dark"
                        >
                            Parlay Win %
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{statsData?.parleyWinPercent !== "-" ? statsData?.parleyWinPercent + "%" : "-"}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span className={`fs-6 fw-bold text-gray-900 text-hover-dark`}  >
                            Margin
                        </span>
                    </div>
                    <div className={`badge ms-auto  ${ !isNaN(statsData?.margin) ? (statsData?.margin > 0 ? "badge-success" : statsData?.margin < 0 ? "badge-orange" : "badge-light") : "badge-light" }`}>{ !isNaN(statsData?.margin) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format( statsData?.margin )): "-" }</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-gray-900 text-hover-dark"
                        >
                            ROI
                        </span>
                    </div>
                    {
                        parseFloat(statsData?.ROI)
                        ? 
                        <div className={`badge ${parseFloat(statsData?.ROI) >= 0 ? `badge-success` : `badge-orange`} ms-auto`}>
                            {parseFloat(statsData?.ROI) >= 0 ? parseFloat(statsData?.ROI)?.toFixed(2) + "%" : parseFloat(statsData?.ROI)?.toFixed(2)+"%"}
                        </div>
                        :
                        <div className={`badge badge-light ms-auto`}>
                            {statsData?.ROI}
                        </div>
                    }
                </div>
                </React.Fragment>}
            </div>

            <Modal isOpen={modal.show} onRequestClose={closeModal} style={customStyles} >
                <div className="d-flex flex-column align-items-center shareModalWidth">
                    <h2 className="d-flex align-items-center">Share to Facebook</h2>
                    <div><img className="shareModalWidth m-2" src={modal.imgUrl} /></div>
                    <h5 className="m-2 w-100">Your Caption</h5>
                    <input className="w-100 m-2 p-2" type="text" value={modal.caption} onChange={e=>setModal({...modal, caption:e.target.value})} />
                    <button className="w-100 m-2 rounded shadow h5 p-2 fbButton" onClick={postToFB}>Share to Facebook</button>
                </div>
            </Modal>
        </div>
    )
}
export default MyStats
