import Strawweight from "../../../../assets/images/badge_icons/Strawweight.png";
import Flyweight from "../../../../assets/images/badge_icons/Flyweight.png";
import Bantamweight from "../../../../assets/images/badge_icons/Bantamweight.png";
import Featherweight from "../../../../assets/images/badge_icons/Featherweight.png";
import Lightweight from "../../../../assets/images/badge_icons/Lightweight.png";
import Welterweight from "../../../../assets/images/badge_icons/Welterweight.png";
import Middleweight from "../../../../assets/images/badge_icons/Middleweight.png";
import Light_Heavyweight from "../../../../assets/images/badge_icons/Light Heavyweight.png";
import Heavyweight from "../../../../assets/images/badge_icons/Heavyweight.png";
import { string } from "yup/lib/locale";


export const getResultClass = (result: any, w:any) => {
  const resultIs =
    result == 0
      ? "TBD"
      : result == 1
      ? "Win"
      : result == 2
      ? "Lose"
      : result == 3
      ? "Draw"
      : result == 4
      ? "CANCELLED"
      : "-";
  return w !== "-" ? resultIs : "";
}

// result
export const getResult = (result: any) => {
  const resultIs =
    result == 0
      ? "TBD"
      : result == 1
      ? "Win"
      : result == 2
      ? "Loss"
      : result == 3
      ? "Draw"
      : result == 4
      ? "CANCELLED"
      : "-";
  return resultIs;
};

export const getResultNew = (result: any) => {
  const resultIs =
    result == 0
      ? <span className="text-muted">TBD</span>
      : result == 1
      ? <span className="text-success">W</span>
      : result == 2
      ? <span className="text-danger">L</span>
      : result == 3
      ? <span className="text-muted">DW</span>
      : result == 4
      ? <span className="text-muted">CAN</span>
      : "";
  return resultIs;
};

/// risk win total
export const getTotalRiskMe = (List: any) => {
  let a = List?.reduce(
    (accumulator: any, current: any) =>
      Number(accumulator) + Number(current?.risk),
    0
  );
  const n = a?.toFixed(2)
  const d = new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(n)
  return d;
};

export const getTotalRiskMePublic = (List: any) => {
  let a = 0
  List.map((e:any) => {
    if(e.isThisEventLocked){
      a = Number(a) + Number(e?.risk)
    }
  })
  const n = a
  return new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(n);
};

export const getTotalRisk = (el: any, List: any) => {
  const forTotal = List?.find((item : any) => item.userId === el.userId)
  const totalRisk = forTotal?.risk;

  return new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(totalRisk)

};

export const getTotalWin = (el: any, List: any) => {
  const forTotal = List?.find((item : any) => item.userId === el.userId)
  const totalWin = forTotal?.win;

  return new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(totalWin)
};

// for blank

export const getBlankHtmlBeters = () => {
  return (
    <td className="table-column ">
      <div className="d-flex align-items-center justify-content-between">
        <div className="w-50">-</div>
        <div className=" w-50">-</div>
      </div>
    </td>
  );
};

export const getTotalBlankHtml = () => {
  return (
    <td>
      <div className="d-flex align-items-center justify-content-between">
        <div className="w-50">$0</div>
        <div className="w-50">$0</div>
      </div>
    </td>
  );
};

// for beters (table body data)

export const getHomeBeters = (uniqueUser: any, obj: any, allEv: any) => {
  if (obj.events?.length > 0) {
    const a = allEv?.find((l: any) => l.home_team_id === obj.home_team_id);
    const b = a?.events?.filter(
      (j: any) => j.userId === uniqueUser.userId && j.pickedTeam === 1
    );
    const c = b?.length > 0 && b[0];
    return (
      <td className="table-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className={`w-50 r ${getResultClass(obj.result?.winner_home, c?.risk ? c.risk : "-") } btn-sm custom_padding`}>{!isNaN(c?.risk) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.risk)) : "-"}</div>
          <div className={`w-50 w ${getResultClass(obj.result?.winner_home, c?.win ? c.win : "-") } btn-sm custom_padding`}>{!isNaN(c?.win) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.win)) : "-"}</div>
        </div>
      </td>
    );
  } else {
    return (
      <td className="table-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-50">-</div>
          <div className="w-50">-</div>
        </div>
      </td>
    );
  }
};

export const getAwayBeters = (uniqueUser: any, obj: any, allEv: any) => {
  if (obj.events?.length > 0) {
    const a = allEv?.find((l: any) => l.away_team_id === obj.away_team_id);
    const b = a?.events?.filter(
      (j: any) => j.userId === uniqueUser.userId && j.pickedTeam === 2
    );
    const c = b?.length > 0 && b[0];

    return (
      <td className="table-column table_width">
        <div className="d-flex align-items-center justify-content-between">
          <div className={`w-50 r ${getResultClass(obj.result?.winner_away, c?.risk ? c.risk : "-") } btn-sm custom_padding`}>{!isNaN(c?.risk) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.risk)) : "-"}</div>
          <div className={`w-50 w ${getResultClass(obj.result?.winner_away, c?.win ? c.win : "-") } btn-sm custom_padding`}>{!isNaN(c?.win) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.win) : "-"}</div>
        </div>
      </td>
    );
  } else {
    return (
      <td className="table-column table_width">
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-50">-</div>
          <div className="w-50">-</div>
        </div>
      </td>
    );
  }
};

export const getUser = (List: any) => {
  const a = List?.map((obj: any) => {
    let b = obj?.events?.filter((el: any) => el.isMe === false);
    return {
      id: obj.id,
      away_team_id: obj.away_team_id,
      home_team_id: obj.home_team_id,
      events: b,
    };
  });
  return a;
};

export const getAllEv = (a: any) => {
  const allEv = a?.filter((el: any) => el?.events?.length > 0);
  return allEv;
};

export const getUniqueBeters = (allEv: any) => {
  const allMembers: any = [];
  allEv?.forEach((e: any) => {
    e.events.forEach((a: any) => {
      allMembers.push(a);
    });
  });
  const uniqueBeters = allMembers.filter(
    (a: any, i: any) =>
      allMembers.findIndex((s: any) => a.userId === s.userId) === i
  );
  return uniqueBeters;
};

export const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    boxShadow: "none",
    borderColor: "neutral10",
    "&:hover": {
      borderColor: "neutral10 !important",
    },
  }),
  option: (styles: any, { isFocused, isSelected }: any) => ({
    ...styles,
    background: isFocused ? "#ceced0" : isSelected ? "#fff" : undefined,
    color: "gray",
  }),
  menuPortal: (base:any) => ({ ...base, zIndex: 9999 })
};

export const stringWithValidLength = (
  name: string,
  from: number,
  to: number
) => {
  return <span className="cu-447 cursor-pointer">{name ? name?.substring(0, from) : ""} {
    name?.substring(to) ? "..." : ""
  }</span>;
};

export const stringNameLength = (
  firstName: string,
  lastName : string,
  from: number,
  to: number
) => {
  const name = firstName + " " + lastName;
  return name ? `${name?.substring(0, from)}${name?.length > from && name?.substring(0, to)? "..." : ""}` : "";
};

export const getSummaryBets = (data : any) => {  
  const currentBetRisk = Number(data)
  const myClass = currentBetRisk &&(currentBetRisk > 0) ? "pos-risk" : currentBetRisk < 0 ? "neg-risk" : "default-risk";

  return <div className="d-flex align-items-center justify-content-center">
            <div className={`w-78 btn-sm custom_padding ${myClass}`}>
            {(currentBetRisk < 0) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(Math.abs(data)): new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(data)}
            </div>   
        </div> 
}

export const getSummaryBetsIsPercent = (data : any) => {  
  const currentBetRisk = Number(data)
  const myClass = currentBetRisk &&(currentBetRisk > 0) ? "pos-risk" : currentBetRisk < 0 ? "neg-risk" : "default-risk";
  if(!isNaN(currentBetRisk)){
    return <div className="d-flex align-items-center justify-content-center">
    <div className={`w-75 btn-sm custom_padding ${myClass}`}>
    {(currentBetRisk < 0) ?  (Math.abs(data)).toFixed(2)+"%" : Number(data).toFixed(2)+'%'}
    </div>   
</div> 
  }else{
    return <div className="d-flex align-items-center justify-content-center">
    <div className={`w-75 btn-sm custom_padding ${myClass}`}>-</div>   
</div> 
  }
 
}
export const getSummaryBetsIsNumber = (data : any) => { 
  const currentBetRisk = Number(data) 
  const myClass = currentBetRisk &&(currentBetRisk > 0) ? "pos-risk" : currentBetRisk < 0 ? "neg-risk" : "default-risk";

  if(!isNaN(currentBetRisk)){
   
    return <div className="d-flex align-items-center justify-content-center">
            <div className={`w-75 btn-sm custom_padding ${myClass}`}>
            {(currentBetRisk < 0) ? (Math.abs(data)) : Number(data)}
            </div>   
        </div> 
  } else {
    return <div className="d-flex align-items-center justify-content-center">
            <div className={`w-75 btn-sm custom_padding ${myClass}`}>-</div>   
        </div> 
  }
  
  
 
}


// event me

export const getMe = (List: any) => {
  const IsMe = List?.map((obj: any) => {
    let b = obj?.events?.filter((el: any) => el.isMe);
    return {
      id: obj.id,
      away_team_id: obj.away_team_id,
      home_team_id: obj.home_team_id,
      events: b,
    };
  });
  return IsMe;
};

export const getitsMe = (IsMe: any) => {
  const ItsMe = IsMe?.filter((el: any) => el?.events?.length > 0);
  return ItsMe;
};

export const getUniqueMe = (ItsMe: any) => {
  const allItsMe: any = [];
  ItsMe?.forEach((e: any) => {
    e.events.forEach((a: any) => {
      allItsMe.push(a);
    });
  });
  const UniqueMe = allItsMe.filter(
    (a: any, i: any) =>
      allItsMe.findIndex((s: any) => a.userId === s.userId) === i
  );

  return UniqueMe;
};

// previous me

export const getMehome = (objIs: any, obj: any, ItsMe: any) => {
  if (obj.events?.length > 0) {
    const a = ItsMe?.find((l: any) => l.home_team_id === obj.home_team_id);
    const b = a?.events?.filter(
      (j: any) => j.userId === objIs.userId && j.pickedTeam === 1
    );
    const c = b?.length > 0 && b[0];
 
    return (
      <td className="table-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className={`w-50 r ${getResultClass(obj.result.winner_home, c?.risk ? c.risk : "-") } btn-sm custom_padding`}>{!isNaN(c?.risk) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.risk)) : "-"}</div>
          <div className={`w-50 w ${getResultClass(obj.result.winner_home, c?.win ? c.win : "-") } btn-sm custom_padding`}>{!isNaN(c?.win) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.win)) : "-"}</div>
        </div>
      </td>
    );
  } else {
    return (
      <td className="table-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-50">-</div>
          <div className="w-50">-</div>
        </div>
      </td>
    );
  }
};

export const getMeAway = (objIs: any, obj: any, ItsMe: any) => {
  if (obj.events?.length > 0) {
    const a = ItsMe?.find((l: any) => l.away_team_id === obj.away_team_id);
    const b = a?.events?.filter(
      (j: any) => j.userId === objIs.userId && j.pickedTeam === 2
    );
    const c = b?.length > 0 && b[0];
    return (
      <td className="table-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className={`w-50 r ${getResultClass(obj.result.winner_away,c?.risk ? c.risk : "-") } btn-sm custom_padding`}>{!isNaN(c?.risk) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.risk) : "-"}</div>
          <div className={`w-50 w ${getResultClass(obj.result.winner_away, c?.win ? c.win : "-") } btn-sm custom_padding`}>{!isNaN(c?.win) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(c.win)): "-"}</div>
        </div>
      </td>
    );
  } else {
    return (
      <td className="table-column">
        <div className=" d-flex align-items-center justify-content-between">
          <div className="w-50">-</div>
          <div className="w-50">-</div>
        </div>
      </td>
    );
  }
};

export const getCurrentWin = (risk: number, moneyLine: any) => {
  const win =
    (risk && moneyLine)
      ? moneyLine > 0
        ? (risk * moneyLine) / 100
        : (-risk * 100) / moneyLine
      : 0;
  // const currentWin = Number(win?.toFixed(2));
  const currentWin = Number(win?.toFixed(2));
  return currentWin
};

export const getCurrentWinPublic = (risk: number, moneyLine: any, isThisEventLocked: any) => {
  const win = (risk && moneyLine && isThisEventLocked) 
              ? (moneyLine>0  ?  (risk*moneyLine)/100  :  (-risk*100)/moneyLine)
              : 0;
  const currentWin = Number(win?.toFixed(2));
  return currentWin
};

// profile weight class

export const getWeightClass = (weightClass : number) =>{

  const weightClassIs = 
  weightClass === 0
  ? {name : "Strawweight", image: Strawweight} 
  : weightClass === 1
  ? {name : "Flyweight", image: Flyweight} 
  : weightClass === 2
  ? {name : "Bantamweight", image: Bantamweight} 
  : weightClass === 3
  ? {name : "Featherweight", image: Featherweight} 
  : weightClass === 4
  ? {name : "Lightweight", image: Lightweight} 
  : weightClass === 5
  ? {name : "Welterweight", image: Welterweight} 
  : weightClass === 6
  ? {name : "Middleweight", image: Middleweight} 
  : weightClass === 7
  ? {name : "Light_Heavyweight", image: Light_Heavyweight} 
  : weightClass === 8
  ? {name : "Heavyweight", image: Heavyweight} 
  : {name : "-", image: ""} 
 
  return weightClassIs
}

// last 5 events total Points 

export const getFiveEventsTotal = (data: any) =>{

  let totalPoints = data?.reduce(
    (accumulator: any, current: any) =>
      Number(accumulator) + Number(current?.points),
    0
  );

  const t = data?.length > 0 ? totalPoints : 0
  return t
}

// last 5 events bet win %
 
export const getFiveEventsBetWin = (data: any) => {

  let totalBetCount = 0
  let totalBetWinCount = 0
  let betWinPercentage = 0
  data.map((el:any) => !isNaN(el?.betCount) ? totalBetCount += el?.betCount : 0)
  data.map((el:any) => !isNaN(el?.betWinCount) ? totalBetWinCount += el?.betWinCount : 0)

  if(totalBetCount){
    betWinPercentage = totalBetWinCount/totalBetCount * 100
  }

  return betWinPercentage
//  let currentBetWin  = data?.reduce(
//   (accumulator: any, current: any) =>
//     Number(accumulator) + Number(current?.betWinPercent),
//   0
// );
// const winIs =  data?.length > 0 ? (currentBetWin / data?.length) : 0;
//  return winIs
}


// last 5 events parlay win %
export const getFiveEventsParlayWin = (data: any) => {
  let currentParlay = data?.some((el : any) => el.isParleyWin)
  return currentParlay ? "Y" : "N"
}


// get roi average for last five events
export const getRoiAverage = (data : any) => {
  let currentRoi =  data?.reduce((a: any , c: any) => a + (c?.roi), 0)
  const roiIs = data?.length > 0 ? (currentRoi / data?.length) : 0
  const ROI = roiIs?.toString();
  return roiIs
}


// standings last 5 events win

export const getStandingsWin = (data : any) => {
  
  const fightData = data?.map((obj : any) => {
    let d = { betWinPercent : obj?.fight_data?.betWinPercent }
    return d
  })
  const currentWin = fightData?.reduce((a : any, c: any) => a + (c?.betWinPercent), 0)

  const winPercent = fightData?.length > 0  ? (currentWin/ fightData.length) : 0;

  return winPercent
}

const sort = (nameIs : any , list : any, setList : any,  sortingIs : boolean, setSorting: any, activeSortName : string, setActiveSort : any) => {
  const currentList = list?.map((obj: any)=>{
     delete obj?.name
    let item = {
      name : nameIs === "fullName" ? obj[nameIs]?.toLowerCase() : Number(obj[nameIs]),
      ...obj
    }
    return item
  })
  var resultIs = null;
  if(sortingIs && currentList){
    resultIs = currentList.sort((a: any,b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }else if(currentList){
    resultIs = currentList.sort((a: any,b: any) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))
  }
  setList(resultIs)
  setSorting(!sortingIs)
  setActiveSort(nameIs)
}

const standingsSort = (nameIs : any , list : any, setList : any,  sortingIs : boolean, setSorting: any, activeSortName : string, setActiveSort : any) => {
  const currentList = list?.map((obj: any)=>{
     delete obj?.name
    let item = {
      name : nameIs === "fullName" ? obj[nameIs]?.toLowerCase() : Number(obj[nameIs]),
      ...obj
    }
    return item
  })
  var resultIs = null;
  if(sortingIs && currentList){
    resultIs = currentList.sort((a: any,b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }else if(currentList){
    resultIs = currentList.sort((a: any,b: any) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))
  }
  let resultSorted:any = []
  resultIs.map((el:any) => el[nameIs] !== "-" ? resultSorted.push(el): "")
  resultIs.map((el:any) => el[nameIs] === "-" ? resultSorted.push(el): "")
  setList(resultSorted)
  setSorting(!sortingIs)
  setActiveSort(nameIs)
}

// sorting
export const getSorting = (nameIs : any , list : any, setList : any,  sortingIs : boolean, setSorting: any, activeSortName : string, setActiveSort : any)=>{
  if(activeSortName !== nameIs){
    setSorting(false)
    setActiveSort(nameIs)
    setTimeout(() =>{
      sort(nameIs, list, setList , sortingIs, setSorting, activeSortName, setActiveSort)
    },500)
  }else{
    sort(nameIs, list, setList , sortingIs, setSorting, activeSortName, setActiveSort)
  }
}

export const getStandingsSorting = (nameIs : any , list : any, setList : any,  sortingIs : boolean, setSorting: any, activeSortName : string, setActiveSort : any)=>{
  if(activeSortName !== nameIs){
    setSorting(false)
    setActiveSort(nameIs)
    setTimeout(() =>{
      standingsSort(nameIs, list, setList , sortingIs, setSorting, activeSortName, setActiveSort)
    },500)
  }else{
    standingsSort(nameIs, list, setList , sortingIs, setSorting, activeSortName, setActiveSort)
  }
}

export const getLastName = (fullName:any) => {
  const nameWithNoExtraSpaces = fullName.replace(/\s+/g, ' ').trim()
  const lastName = nameWithNoExtraSpaces.split(" ").reverse()[0]
  const middleName = nameWithNoExtraSpaces.split(" ").reverse()[1]
  if(lastName.length > 3){
    return <span className="cu-447 cursor-pointer">{lastName}</span>
  } else {
    return <span className="cu-447 cursor-pointer">{middleName + " " + lastName}</span>
  }
  
}

export const addStickyToTHead = () => {
  const all = document.getElementsByTagName("thead");
  for (var i=0, max=all.length; i < max; i++) {
      all[i].classList.add("sticky-top")
  }
}

export const removeStickyToTHead = () => {
  const all = document.getElementsByTagName("thead");
  for (var i=0, max=all.length; i < max; i++) {
      all[i].classList.remove("sticky-top")
  }
}