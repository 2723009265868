import { Link, useHistory } from "react-router-dom";
import EmptyImage from "../../../../assets/images/avatars/blank.png";
import Standings from "../Standings/Standings";
import GroupChat from "../GroupChat";
import EventBetslip from "../EventBetsDetails/EventBetSlip/EventBetslip";
import PreviousEvents from "../PreviousEventsDetails/PreviousEvents/PreviousEvents";
import Layout from "../../../../Common/Layout";
import LeftArrow from "../../../../assets/images/left_arrow.svg";
import { clubPic, inviteFrnds } from "../../../../Store/action/UserInfo.action";
import { clubById, leaveClub } from "../../../../Store/action/Events.action";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ImageUrl } from "../../../../Utils/BaseUrl";
import Modal from "react-modal";
import Spinner, { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import { addStickyToTHead, removeStickyToTHead } from "../AllEventCalculation/Calculation";
import { getApi, postApi } from "../../../../Store/api-interface/api-interface";
import cameraIcon from "../../../../assets/images/avatars/cameraIcon.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
  },
};

const customStylesInvitation = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "70vh"
  },
};


const customChatStyles = {
  content: {
    top: "50%",
    left: "45%",
    bottom: "auto",
    margin: "20px",
    padding: "0",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "100%",
  },
};


const MemberListDetails = (props?: any) => {
  const [isAlertActive, setIsAlertActive]=useState<boolean>(false)
  const [clubIs, setclubIs] = useState<any>(null);
  const [ModalIs , setModal] = useState<boolean>(false)
  const [EditModalIs , setEditModal] = useState<boolean>(false)
  const [ChatModalIs , setChatModal] = useState<boolean>(false)
  const [DeleteModalIs , setDeleteModal] = useState<boolean>(false)
  const [pendingIviteModal, setPendingInviteModal] = useState<boolean>(false)
  const [pendengUser, setPendingUser] = useState<any>(null)
  const [Loader, setLoader] = useState<boolean>(false)
  const [clubDetailValidLoader, setCDVLoader] = useState<boolean>(true)
  const [clubName, setClubName]=useState<any>()
  const [lastSeen, setLastSeen]=useState();
  const [clubPicLoader, setClubPicLoader] = useState<boolean>(false)
  const [showInviteCode, setShowInviteCode] = useState<boolean>(false)
  const [editSuccess, setEditSuccess] = useState<boolean>(false)
  const [editError, setEditError] = useState<boolean>(false)
  const [invitationLoader, setInvitationLoader] = useState({loader:false, message:""})

  const history = useHistory();
  const clubId = props?.match?.params.id;
  const clipboardRef = useRef<any>(null)
  const copiedRef = useRef<any>(null)
  const handleInviteMemebers = () => {
    history.push({
      pathname: "/invitefriends",
      state: {
        invitationType: 1,
        logo: clubIs?.clubPic ? ImageUrl + clubIs?.clubPic : EmptyImage,
        clubId
      },
    });
  };

  const getConfimation = () =>{
    setModal(true)
    setIsAlertActive(true)
  }

  const handleCancel = () => {
    setModal(false)
    addStickyToTHead()
  }

  const pendingInvites = () => {
    setPendingInviteModal(true)
  }

  const closeModal = () => {
    setDeleteModal(false)
    setEditModal(false)
    setPendingInviteModal(false);
    addStickyToTHead();
  };
 
  const getPendingInvitation = async()=>{
    await getApi(`/user/getInvitation`).then((res) => {
      if(res?.data?.data){
        setPendingUser(res?.data?.data)     
      }
    })
  }

  useEffect(() => {
   setInvitationLoader({...invitationLoader, message: props?._inviteFrnds?.inviteFrndIs?.message})
    setTimeout(() => {
      // setPendingInviteModal(false)
      setInvitationLoader({loader:false, message:""})
    }, 2000)
  }, [props?._inviteFrnds])

  const sendInvitesAgain = (sendAgainId:any, invitationType:any) => {
    setInvitationLoader({loader:true, message:""})
    props?.$inviteFrnds({invitationType:1, emails:[{emailId: sendAgainId}]})
    setTimeout(() =>{
      setPendingInviteModal(false)
    },2000)
  }

  const withDrawInvitation = async(delId:any) => {
    setInvitationLoader({loader:true, message:""})
    await postApi(`/user/removeMember`, {data:delId}).then((res)=>{
      setInvitationLoader({loader:true, message:res.data.message})
      getPendingInvitation()
      setTimeout(() => {
        setPendingInviteModal(false)
        setInvitationLoader({loader:false, message:""})
      }, 2000)
    })
  }

  const copyToClipboard = (text: any) =>  {  
    clipboardRef.current.style.color = "var(--bg-orange)"
    clipboardRef.current.style.fontSize = "16px" 
    copiedRef.current.classList.remove("d-none")
    navigator?.clipboard?.writeText(text);
    setTimeout(() => {
      clipboardRef.current.style.color = ""; 
      clipboardRef.current.style.fontSize = "";
      copiedRef.current.classList.add("d-none")
    }, 1000)
    
  }

  const handleLeaveClub = () => {
    const data = {clubId}
    if(data){
      props.$leaveClub(data)
      setLoader(true)
    }
  }

  useEffect( ()=> {
    setCDVLoader(true)
    checkIsUserClubValid()
    getPendingInvitation()
  },[])

  const checkIsUserClubValid = async () => {
    const clubId = props.match.params.id
    const response = await getApi(`/user/checkIsUserClubValid?clubId=${clubId}`)    
    const status = response?.data?.status
    if(status){
      setCDVLoader(false)
    } else {
      history.push("/clublist")
    }
  }

  useEffect(() =>{
    const data = props._leaveClub.leaveClubIs;
    if(data.status === 1 && Loader){
      setLoader(false)
      handleCancel()
      props.history.push("/clublist")
    }
  },[props._leaveClub])

  const updatePictureClub = (e?: any) => {
    const files = e.target.files[0];
    setClubPicLoader(true)
    if (files) {
      const formdata = new FormData();
      formdata.append("file", files);
      props?.$clubPic(formdata);
    }
  };

  useEffect(() => {
    getClubData(clubId);
  }, [props._clubPic]);

  const getClubData = (clubId: number) => {
    const data = { clubId };
    props.$clubById(data);
  };

  useEffect(() => {
    getClubData(clubId);
  }, []);

  useEffect(() => {
    const clubData = props?._clubById?.clubBYId?.data;
    if (clubData) {      
      setclubIs(clubData);
      setClubPicLoader(false)
    }
  }, [props._clubById]);

  const parentCallBack=(lastChecked?:any)=>{
    setLastSeen(lastChecked)

  }
  const handleNameChange=async()=>{
    setLoader(true)
    await postApi("/user/updateClubName", {clubName:clubName})
  .then(res=>{
    
      if(res.status===200){
        setEditSuccess(true)
        getClubData(clubId);
        setTimeout(() => {
          setLoader(false)
          setEditModal(false)
          setEditSuccess(false)
          addStickyToTHead()
        }, 1500);
      }else{
        setEditError(true)
      }
    })

   }

   const handelDeleteClub=async()=>{
     setLoader(true);
     await postApi("/user/deleteClub", {clubId:clubIs.clubId})
     .then(res=>{
    
      if(res.status===200){
        setEditSuccess(true)
       
        setTimeout(() => {
          setLoader(false)
          setDeleteModal(false)
          setEditSuccess(false)
          history.push("/clublist")
          
        }, 1500);
      }else{
        setEditError(true)
      }
    })

   }

  return (
    <Layout options="fill_Header" uservisible="visible">
      <section className="main-content">
        {
          clubDetailValidLoader
        ?
          <Spinner color="text-black spinner-border-sm " />
        :
        <div className="container py-10">
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <div className="row">
                <div className="col-sm-12">
                  <Link to="/clublist">
                    <h1 className="text-dark fw-bolder fs-2 d-flex align-items-center cursor-pointer">
                      <img
                        src={LeftArrow}
                        alt=""
                        className="img-fluid me-3 w-10px"
                      />{" "}
                      Back to all Clubs
                    </h1>
                  </Link>
                </div>
                <div className="col-sm-12 py-10">
                  <div className="d-flex align-items-center justify-content-between flex-wrap clubProfileHeader">
                    <div className="d-flex justify-content-between align-items-center flex-wrap clubProfileHead">
                      <div className="me-7">
                          <div className="symbol symbol-60px symbol-sm-100px symbol-fixed position-relative">
                          <img src={ clubIs?.clubPic ? ImageUrl + clubIs?.clubPic : EmptyImage } alt="..." className="rounded-circle " />
                          {clubIs?.isOwner && (
                            <div>
                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" id="isImage" onChange={updatePictureClub} hidden />
                            <label htmlFor="isImage">
                            <div className="rounded-circle ">
                            {
                              clubPicLoader 
                              ? 
                              <span className="position-absolute translate-middle start-100  ms-n7 mt-n6" ><Spinner color="text-danger spinner" /> </span>
                              
                              :
                              <img src={cameraIcon} style={{width:"25%"}} alt="" className="cursorPointer position-absolute translate-middle start-100  ms-n7 mt-n6" />
                            }
                            </div>
                            </label>
                            
                            </div>
                          )}
                          
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2 profileImageIcons">
                          <a
                            href="#!"
                            className="text-gray-900 fs-2 fw-bolder me-1"
                          >
                            {`${clubIs ? clubIs?.name?.substring(0, 20) : ''}${clubIs?.name.substring(17) ? "..." : ""}`}
                          </a>
                          {
                            clubIs?.isOwner
                            ?
                            <div>
                              <i onClick={()=>setEditModal(true)} className="bi bi-pencil cursorPointer mx-1 h6 text-muted cursorPointer"></i>
                              <i onClick={()=>setDeleteModal(true)} className="bi bi-trash mx-1 h6 text-muted cursorPointer" style={{ }} ></i>
                            </div>
                            :
                            ""
                          }
                          <i onClick={()=>setShowInviteCode(!showInviteCode)} className={`mt-2 bi bi-eye${showInviteCode?"-slash":""} mx-1 h6 text-muted cursorPointer`} data-toggle="tooltip" data-placement="bottom" title={!showInviteCode ? "Show Invite code" : "Hide Invite code"}></i>
                        </div>
                        {  
                          showInviteCode 
                          ? 
                          <div className="d-flex flex-wrap fw-bold mb-2 fs-6 pe-2"> 
                            <span>Club Invite Code: {clubIs?.inviteCode}</span> 
                            <i className="mx-2 mt-1 bi bi-clipboard-check" ref={clipboardRef} onClick={()=> copyToClipboard(clubIs?.inviteCode)}/>
                            <span className="d-none" ref={copiedRef}>✓ Copied</span>
                          </div> 
                          : 
                          ""
                        }
                        <div className="d-flex flex-wrap fw-bold fs-6 pe-2">
                          <span className="d-flex align-items-center text-gray-400" >
                            <span className="svg-icon svg-icon-4 me-1">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" >
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                  <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                </g>
                              </svg>
                            </span>
                          </span>
                          <span className="totalMembers" style={{color: "#b5b5c3"}}>Total Members: {clubIs?.totalMember}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 mt-xs-20 clubActionBtns">
                      {
                        clubIs?.isOwner
                        ?
                        <>
                          <button className="btn btn-dark ms-2 mb-2 fw-bolder" onClick={handleInviteMemebers}>Invite Members</button>
                          <button className="btn btn-orange ms-2 mb-2 fw-bolder" onClick={pendingInvites}>Pending Invites</button>
                        </>
                        :
                        ""
                      }
                      <Link
                        to={`${clubIs?.isOwner ? "/clubMembersList" : "#!"}`}
                        className="btn btn-white ms-2 mb-2 fw-bolder shadow-sm"
                        onClick={clubIs?.isOwner  ? ()=>{} : getConfimation}
                      >
                        {clubIs?.isOwner ? "Remove Members" : "Quit Club"}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 table_wrapper">
                  <Standings isAlertActive={isAlertActive}  />
                  <EventBetslip clubId={clubId} isAlertActive={isAlertActive} />
                  <PreviousEvents clubId={clubId} isAlertActive={isAlertActive}  />
                </div>
              </div>
            </div>
            <div className="d-none d-sm-block col-lg-4">
             {window?.innerWidth > 575 ? <GroupChat clubId={clubId}/> : ''}
            </div>
            <span className={`${ChatModalIs ? "d-none" : ""} d-sm-none`}>
              <i style={{zIndex:9999}} className='bi bi-chat-square-text rounded-circle shadow-lg d-inline chatFloatingIcon' onClick={()=>setChatModal(!ChatModalIs)} />
            </span>
          </div>
        </div>
      }
      </section>

      {/* Confirmation Modal */}
      <Modal isOpen={ModalIs} onAfterOpen={removeStickyToTHead} style={customStyles} contentLabel="Example Modal">
        <div>
          <h3 className="text-center p-5">Do you want to Leave this club ?</h3>
          <div className="text-center d-flex">
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-orange m-2 w-100 mb-5"
              onClick={handleLeaveClub}
            >
              <span className="indicator-label fw-bolder">
                {Loader ? (
                  <ButtonSpinner color="text-black spinner-border-sm " />
                ) : (
                  "Confirm"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center m-2 btn-light btn-lg w-100 mb-5"
              onClick={handleCancel}
            >
              <span className="indicator-label fw-bolder">Cancel</span>
            </button>
          </div>
        </div>
      </Modal>


      {/* chat model */}
      <Modal isOpen={ChatModalIs} onAfterOpen={removeStickyToTHead} style={customChatStyles} contentLabel="Example Modal">
        <div className="chatBoxModal1">
         {window?.innerWidth < 576 ? <GroupChat clubId={clubId} isChatModal={true} setChatModal={setChatModal} parentCallBack={parentCallBack}/> : ''} 
        </div>
      </Modal>


      {/* edit model */}

      <Modal isOpen={EditModalIs} onRequestClose = {closeModal} onAfterOpen={removeStickyToTHead} style={customStyles} contentLabel="Example Modal">
        <div>
          <h3 className="text-center p-5">Please enter new name.</h3>
          <div className="form-group">
    <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter name" value={clubName} onChange={(e)=>setClubName(e.target.value)}/>
  </div>

          {editSuccess&&
          <div className="alert alert-success mt-5" role="alert">
          Club name updated successfully.
        </div>}

        {editError&&
          <div className="alert alert-danger mt-5" role="alert">
          Some error occured.
        </div>}
          <div className="text-center d-flex mt-5">
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-orange m-2 w-100 mb-5"
              onClick={handleNameChange}
              disabled={Loader}
            >
              <span className="indicator-label fw-bolder">
                {Loader ? (
                  <ButtonSpinner color="text-black spinner-border-sm " />
                ) : (
                  "Change"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center m-2 btn-light btn-lg w-100 mb-5"
              onClick={()=>{addStickyToTHead();setEditModal(false)}}
            >
              <span className="indicator-label fw-bolder">Cancel</span>
            </button>
          </div>
        </div>
      </Modal>

      {/*club delete model */}

      <Modal isOpen={DeleteModalIs} onRequestClose = {closeModal} onAfterOpen={removeStickyToTHead} style={customStyles} contentLabel="Example Modal">
        <div>
          <h3 className="text-center text-danger p-5">Are you sure want to delete this club. This action is irreversible, click "Confirm" to proceed.</h3>
          {editSuccess&&
          <div className="alert alert-success mt-5" role="alert">
          Club deleted successfully.
        </div>}
          {editError&&
          <div className="alert alert-danger mt-5" role="alert">
          Some error occured.
        </div>}
          <div className="text-center d-flex">
          
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-orange m-2 w-100 mb-5"
              disabled={Loader}
              onClick={handelDeleteClub}
            >
              <span className="indicator-label fw-bolder">
                {Loader ? (
                  <ButtonSpinner color="text-black spinner-border-sm " />
                ) : (
                  "Confirm"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center m-2 btn-light btn-lg w-100 mb-5"
              onClick={()=>{addStickyToTHead(); setDeleteModal(false)}}
            >
              <span  className="indicator-label fw-bolder">Cancel</span>
            </button>

            
          </div>
        </div>
       
      </Modal>
      <Modal 
        isOpen={pendingIviteModal} onAfterOpen={removeStickyToTHead} onRequestClose={closeModal} style={customStylesInvitation} contentLabel="Example Modal">
          <div className="text-center mb-6">
            <h3>Pending Invitations</h3>
            <hr />
            {
              invitationLoader?.loader
              ?
              <>
              <ButtonSpinner color="text-black spinner-border-sm " />
              <div className="text-success">{invitationLoader?.message}</div>
              </>
              
              :
              ''
              
            }
           
          </div>
          {
            pendengUser?.map((obj:any, index:number)=>(
              <div className="row m-3 p-3">
                <div className="card-label fs-3 my-1 col-md-6">{obj.email}</div>
                <div className="card-toolbar my-1 col-md-3">
                  <button  className="btn btn-orange btn-sm fw-small" disabled={invitationLoader?.loader} onClick = {(e)=>sendInvitesAgain(obj?.email, 1)}>Send Again</button>
                </div>
                <div className="card-toolbar my-1 col-md-3">
                  <button className="btn btn-orange btn-sm fw-small" disabled={invitationLoader?.loader} onClick = {(e)=> withDrawInvitation(obj?.id)}>Withdraw Invite</button>
                </div>
                {/* <hr className="my-1" /> */}
              </div>
            ))
          }
        </Modal>
    </Layout>
)}


const mapState = ({ clubPic, clubById, leaveClub, inviteFrnds }: any) => {
  return {
    _clubPic: clubPic,
    _clubById: clubById,
    _leaveClub: leaveClub,
    _inviteFrnds: inviteFrnds,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  
  $clubPic: (values?: any) => dispatchEvent(clubPic(values)),
  $clubById: (values?: any) => dispatchEvent(clubById(values)),
  $leaveClub: (values?: any) => dispatchEvent(leaveClub(values)),
  $inviteFrnds: (values?: IinviteFrnd) => dispatchEvent(inviteFrnds(values)),
});
export default connect(mapState, mapDispatch)(MemberListDetails);
