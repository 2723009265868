import { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useHistory } from "react-router-dom";
import queryString from 'query-string'
import Layout from "../../../Common/Layout";
import { connect } from "react-redux";
import { signUpData } from "../../../Store/action/Login.action";
import { initielValues_signUp, validationSchema_signUp } from "./Validation";
import { ButtonSpinner } from "../../../Common/Spinner/Spinner";
import Alert from "../../../Common/Alert/Alert";
import FormError from "../../../Common/FormError/FormError";
import { userProfile } from "../../../Store/action/UserInfo.action";
import PasswordStrengthBar from "../../../Common/password-strength-bar";

const Registration = (props?: any) => {
  const { $signUpData, $userProfile, _signUpData }: IsignupProps = props;
  const history = useHistory();
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [showPwdIs, setShowPwd] = useState<boolean>(false);
  const [isButtonDisable,setIsButtonDisable] = useState<boolean>(true)
  const getFocus = useRef<HTMLInputElement>(null);
  const [alertIs, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });

  let user = { email: "",firstName:"",lastName:'', screenName:"", password: "", inviteCode: "", clubCode:"", agree: [] }
  const qs = queryString.parse(props.location.search);
  localStorage.removeItem("token")
  if(Object.keys(qs)?.length){
    user = { email: qs.email, firstName:qs.firstName ,lastName:qs.lastName,screenName:"", password: "", clubCode:qs.clubCode, inviteCode: qs.inviteCode, agree: [] };
  }

  const handleShowPwd = () => {
    setShowPwd(!showPwdIs);
  };

  const onSubmit = (values: ISignUp) => {
    if(isButtonDisable){
      setIsButtonDisable(false)
      setLoader(true);
      $signUpData(values);
    }
   
  };

  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  useEffect(() => {
    getFocus.current?.focus();
  }, []);

  useEffect(() => {
    const response = _signUpData.signUpDataIs;
    const resErr = _signUpData.signUpErrIs;
    if (response?.status === 1 && LoadingIs) {
      localStorage.setItem("token", response.data.accessToken);
      // getAlert(response.message, "alert-success");
      getPageRedirection()
      setLoader(false);
    } else if (resErr?.status === 0 && LoadingIs) {
      getAlert(resErr.message, "alert-danger");
      setLoader(false);
      setIsButtonDisable(true)
    }
  }, [_signUpData]);


  const getPageRedirection = () => {
    const stateIs = qs?.clubCode && qs.email ? `/clublist/${qs.email}/${qs.clubCode}` : ""
    if(qs?.r){
      history.push({ pathname: qs.r })
    } else {
      history.push({ pathname: "/personalsettings", state: { signUpIs: true, stateIs } });
    }
    
    $userProfile();
  }

  useEffect(() => {
    if (alertIs.activeIs) {
      setTimeout(() => {
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 1000);
    }
  }, [alertIs]);

  // const email = props.history.location.state?.split("/")[2];
  // const inviteCode = props.history.location.state?.split("/")[3];
  

  return (
    <Layout options="fill_Header">
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="container">
            <div className="row">
              <div className="w-lg-500px bg-body rounded shadow-sm p-8 p-lg-15 mx-auto">
                {alertIs.activeIs && (
                  <Alert
                    statusIs={`alert ${alertIs.statusIs}`}
                    message={alertIs.msgIs}
                  />
                )}
                <Formik
                  initialValues={user ? user : initielValues_signUp}
                  validationSchema={validationSchema_signUp}
                  onSubmit={onSubmit}
                  validateOnMount
                  enableReinitialize
                >
                  {(formik) => (
                    <Form
                      className="form w-100"
                      noValidate
                      id="kt_sign_up_form"
                      action="#"
                    >
                      <div className="text-center mb-50">
                        <input
                          type="password"
                          style={{
                            position: "fixed",
                            maxHeight: 0,
                            maxWidth: 0,
                            opacity: 0,
                            top: 0,
                            left: 0,
                            zIndex: -1,
                          }}
                        />
                        <h1 className="text-dark mb-4 text-capitalize">
                          Welcome to MMA Bet Club
                        </h1>
                        <p className="text-gray-400 fw-bolder fs-4">
                          Welcome to MMA BetClub! Use your email to sign up and
                          get started today.
                        </p>
                      </div>
                      <div className="fv-row mb-5">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Email
                        </label>
                        <Field
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.email &&
                            formik.errors.email &&
                            `border border-danger`
                          }`}
                          type="email"
                          innerRef={getFocus}
                          name="email"
                          id="email"
                          autoComplete="off"
                          placeholder="Enter Email"
                          disabled={user.email ? true : false}
                        />
                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="fv-row mb-5">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          First Name
                        </label>
                        <Field
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.firstName &&
                            formik.errors.firstName &&
                            `border border-danger`
                          }`}
                          type="text"
                          name="firstName"
                          id="firstName"
                          autoComplete="off"
                          placeholder="Enter First Name"
                          disabled={user.screenName ? true : false}
                        />
                        <ErrorMessage
                          name="firstName"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="fv-row mb-5">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Last Name
                        </label>
                        <Field
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.screenName &&
                            formik.errors.screenName &&
                            `border border-danger`
                          }`}
                          type="text"
                          name="lastName"
                          id="lastName"
                          autoComplete="off"
                          placeholder="Enter Last Name"
                          disabled={user.screenName ? true : false}
                        />
                        <ErrorMessage
                          name="lastName"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="fv-row mb-5">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Screen Name
                        </label>
                        <Field
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.screenName &&
                            formik.errors.screenName &&
                            `border border-danger`
                          }`}
                          type="text"
                          name="screenName"
                          id="screenName"
                          autoComplete="off"
                          placeholder="Enter Screen Name"
                          disabled={user.screenName ? true : false}
                        />
                        <div className="text-muted">You will not be able to edit your screenname later.</div>
                        <ErrorMessage
                          name="screenName"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div
                        className="mb-5 fv-row"
                        data-kt-password-meter="true"
                      >
                        <div className="mb-1">
                          <label className="form-label fw-bolder text-dark fs-6">
                            Password
                          </label>
                          <div className="position-relative mb-3">
                            <Field
                              className={`form-control form-control-lg form-control-solid ${
                                formik.touched.password &&
                                formik.errors.password &&
                                `border border-danger`
                              }`}
                              type={showPwdIs ? `text` : `password`}
                              placeholder="Password"
                              name="password"
                              id="password"
                              autoComplete="off"
                              onChange={(e: any) =>
                                e.target.value.length < 41
                                  ? formik.handleChange(e)
                                  : null
                              }
                            />
                            <span
                              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                              data-kt-password-meter-control="visibility"
                              onClick={handleShowPwd}
                            >
                              <i
                                className={`bi ${
                                  showPwdIs ? `bi-eye-slash` : `bi-eye`
                                } fs-2`}
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            name="password"
                            render={(msg) => (
                              <FormError
                                classes="d-flex mb-3"
                                message={msg}
                              ></FormError>
                            )}
                          />
                          <PasswordStrengthBar
                            password={formik.values.password}
                          />
                        </div>
                        <div className="text-muted">
                          Use 8 or more characters with a mix of letters,
                          numbers &amp; symbols.
                        </div>
                      </div>
                      <div className="fv-row mb-5">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Invite code <span className="text-muted">(optional)</span>
                        </label>
                        <Field
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.inviteCode &&
                            formik.errors.inviteCode &&
                            `border border-danger`
                          }`}
                          type="text"
                          name="inviteCode"
                          id="inviteCode"
                          autoComplete="off"
                          placeholder="Invite Code"
                          onChange={(e: any) =>
                            e.target.value.length < 7
                              ? formik.handleChange(e)
                              : null
                          }
                          disabled={user.inviteCode ? true : false}
                        />
                        <ErrorMessage
                          name="inviteCode"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>

                      {/* if clubCode exists store & send in api - its a hidden field */}
                      <Field className="d-none" type="text" name="clubCode" id="clubCode"/>
                      
                      <div className="fv-row mb-5">
                        <div className="form-check form-check-custom form-check-solid form-check-inline align-items-start">
                          <Field
                            // style={{border:'0.01em solid red'}}
                            className={`form-check-input termsCheck ${
                              formik.touched.agree &&
                              formik.errors.agree &&
                              `border border-danger`
                            } ${formik.errors?.agree ? '' : 'checked'}`}
                            type="checkbox"
                            name="agree"
                            id="agree"
                            value={true}
                            required={true}
                          />

                          <label
                            className="form-check-label fw-bold text-gray-700 fs-6"
                            htmlFor="agree"
                          >
                            I Agree &amp;
                            <Link to="/" className="ms-1 link-primary">
                              Terms and conditions
                            </Link>{" "}
                            &amp; By signing up, you agree to comply with our
                            terms and conditions .
                          </label>
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          // disabled={!formik.isValid}
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-1"                        
                        >
                          <span className="indicator-label fw-bolder">
                            {LoadingIs ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              `Next`
                            )}
                          </span>
                        </button>
                      </div>
                      <div className="fv-row mb-8">
                        <div className="text-muted">
                          We’ll never share your email with anyone else
                        </div>
                      </div> 
                      <div className="fv-row registerSection">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Already have an account?
                        </label>
                        {
                          Object.keys(qs)?.length && qs?.email
                          ?
                          <span
                            className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => history.push(`/login?clubCode=${qs.clubCode}&inviteCode=${qs.inviteCode}&email=${qs.email}`)}
                          >
                            <span className="indicator-label fw-bolder">
                              Sign In
                            </span>
                          </span>
                          :
                          Object.keys(qs)?.length && qs?.r
                          ?
                          <span
                            className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => history.push(`/login?r=${qs.r}`)}
                          >
                            <span className="indicator-label fw-bolder">
                              Sign In
                            </span>
                          </span>
                          :
                          <span
                            className="btn btn-flex flex-center registerBtn  btn-light btn-lg w-100 mb-5"
                            onClick={() => history.push("/login")}
                          >
                            <span className="indicator-label fw-bolder">
                              Sign In
                            </span>
                          </span>
                        }
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapState = ({ signUpData, userProfile }: any) => {
  return {
    _signUpData: signUpData,
    _userProfile: userProfile,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $signUpData: (values?: ISignUp) => dispatchEvent(signUpData(values)),
  $userProfile: () => dispatchEvent(userProfile()),
});
export default connect(mapState, mapDispatch)(Registration);
