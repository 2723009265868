
import { Link } from "react-router-dom";
import Spinner from "../../../../Common/Spinner/Spinner";
import { ImageUrl } from "../../../../Utils/BaseUrl";
import { stringNameLength, getStandingsSorting } from "../../Club/AllEventCalculation/Calculation"


const StandindTable = (props ?:any) => {
    
  const { LoadingIs, standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort, isAlertActive } = props;

  return (
    <div className="h-500">
    
    {LoadingIs ?
     <Spinner color="text-dark  spinner-border-sm text-center" />:
    <table
      className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
      id="kt_subscriptions_table"
      role="grid"
    >
      <thead className={isAlertActive?"sticky-top":""} style={{background:"white"}}>
        <tr
          className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-1"
          role="row"
        >
          <th
            className={`min-w-150px sorting text-start  ${activeSortName === "fullName" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Customer: activate to sort column ascending"
            onClick={() => getStandingsSorting("fullName", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
            style={{width:150, position: "sticky", left: 0, background: "white", zIndex: 1 }} 
          >
            Player Name
          </th>
          <th
            className={`min-w-85px sorting ${activeSortName === "totalPoints" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Status: activate to sort column descending"
            style={{ width: 125 }}
            aria-sort="ascending"
            onClick={() => getStandingsSorting("totalPoints", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Total Points
          </th>
          <th
            className={`min-w-100px sorting ${activeSortName === "totalMargin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Status: activate to sort column descending"
            style={{ width: 125 }}
            aria-sort="ascending"
            onClick={() => getStandingsSorting("totalMargin", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Margin
          </th>
          <th
            className={`min-w-75px sorting ${activeSortName === "averageROI" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Billing: activate to sort column ascending"
            style={{ width: 300 }}
            onClick={() => getStandingsSorting("averageROI", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            ROI %
          </th>
          <th
            className={`min-w-65px sorting ${activeSortName === "totalBetWinPercentage" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Billing: activate to sort column ascending"
            style={{ width: 300 }}
            onClick={() => getStandingsSorting("totalBetWinPercentage", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Bet Win %
          </th>
          <th
            className={`min-w-85px sorting ${activeSortName === "totalParleyWinPercentage" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Product: activate to sort column ascending"
            style={{ width: 300 }}
            onClick={() => getStandingsSorting("totalParleyWinPercentage", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Parlay Win %
          </th>
          <th
            className={`min-w-100px sorting ${activeSortName === "lastEventBetWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Created Date: activate to sort column ascending"
            style={{ width: 300 }}
            onClick={() => getStandingsSorting("lastEventBetWinPercent", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Last Event Win %
          </th>
          <th
            className={`min-w-85px sorting`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Created Date: activate to sort column ascending"
            style={{ width: 300 }}
            // onClick={() => getStandingsSorting("lastEventBetWinPercent", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Last Event Parlay Win
          </th>
          <th
            className={`min-w-100px sorting ${activeSortName === "last5BetWinPercent  " && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Created Date: activate to sort column ascending"
            style={{ width: 300 }}
            onClick={() => getStandingsSorting("last5BetWinPercent ", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Last 5 Events Win %
          </th>
          <th
            className={`min-w-100px sorting ${activeSortName === "last5ParleyWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Created Date: activate to sort column ascending"
            style={{ width: 100 }}
            onClick={() => getStandingsSorting("last5ParleyWinPercent", standingList, setStandingList, sortingIs, setSorting, activeSortName, setActiveSort)}
          >
            Last 5 Events Parlay %
          </th>
          <th
            className="min-w-75px sorting"
            tabIndex={0}
            aria-controls="kt_subscriptions_table"
            rowSpan={1}
            colSpan={1}
            aria-label="Created Date: activate to sort column ascending"
            style={{ width: 75 }}
          >
            Win Streak
          </th>
        </tr>
      </thead>
      <tbody className="text-gray-600 fw-bold">
        {standingList?.map((obj: any, index: number) => (
            <tr className="bg-table-row-1" key={index}>
                <td  className="table-column text-gray-800 text-start" style={{ width:150, position: "sticky", left: 0, background: "white", zIndex: 1}}>
                  {
                    obj.screenName
                    ?
                    <Link to={`/player/${obj.screenName}`} className="text-dark">
                      {/* <span className="symbol symbol-30px symbol-circle me-3">
                        <span className="symbol-label bg-light-warning text-warning 40px">
                          {obj?.fullName?.charAt(0)}
                        </span>
                      </span> */}
                      {index+1}.&nbsp;
                      { 
                        obj?.profilePic && !obj.profilePic.includes("-blob")
                        ?
                        <div className="symbol symbol-35px symbol-circle me-3">
                            <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.fullName ? obj.fullName : ""}/>
                        </div> 
                        :
                        <div className="symbol symbol-35px symbol-circle me-3">
                            <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                                {obj?.fullName ? obj.fullName[0].toUpperCase() : ""}
                            </span>
                        </div>
                      }
                      {obj?.fullName ? stringNameLength(obj?.fullName, "", 10, 10) : "-"}
                    </Link>
                    :
                    <>
                      {/* <span className="symbol symbol-30px symbol-circle me-3">
                        <span className="symbol-label bg-light-warning text-warning 40px">
                          {obj?.fullName?.charAt(0)}
                        </span>
                      </span> */}
                      {
                        obj?.profilePic && !obj.profilePic.includes("-blob")
                        ?
                        <div className="symbol symbol-35px symbol-circle me-3">
                            <img alt="Pic" src={ImageUrl+ obj.profilePic} title={obj?.fullName ? obj.fullName : ""}/>
                        </div> 
                        :
                        <div className="symbol symbol-35px symbol-circle me-3">
                            <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                                {obj.fullName ? obj.fullName[0].toUpperCase() : ""}
                            </span>
                        </div>
                      }
                      {obj?.fullName ? stringNameLength(obj?.fullName, "", 10, 10) : "-"}
                    </>
                  }
                  
                </td>
                <td className="table-column">{obj?.totalPoints ? obj.totalPoints : "-"}</td>
                <td className="table-column">{!isNaN(obj?.totalMargin) ?  new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(obj.totalMargin) : "-"}</td>
                <td className="table-column">{obj?.averageROI ? obj.averageROI + (!isNaN(obj.averageROI) ? "%" : "") : "-"}</td>
                <td className="table-column">{obj?.totalBetWinPercentage ? obj.totalBetWinPercentage + (!isNaN(obj.totalBetWinPercentage) ? "%" : "") : "-"}</td>
                <td className="table-column">{obj?.totalParleyWinPercentage ? obj.totalParleyWinPercentage + (!isNaN(obj.totalParleyWinPercentage) ? "%" : "") : "-"}</td>
                <td className="table-column">{obj?.lastEventBetWinPercent ? obj.lastEventBetWinPercent + (!isNaN(obj.lastEventBetWinPercent) ? "%" : "") : '-'}</td>
                <td className="table-column">
                  {
                    obj?.lastEventParlayWin 
                    ? 
                      (obj.lastEventParlayWin === "true" ? "Yes" : (obj.lastEventParlayWin==="false" ? "No": "-")) 
                    : 
                    "-" 
                  }
                </td>
                <td className="table-column">{obj?.last5BetWinPercent ? obj.last5BetWinPercent + (!isNaN(obj.last5BetWinPercent) ? "%" : "") : "-"}</td>
                <td className="table-column">{obj?.last5ParleyWinPercent ? obj.last5ParleyWinPercent + (!isNaN(obj.last5ParleyWinPercent) ? "%" : "")  : "-"}</td>
                <td className="table-column">{obj?.maxWinStreak}</td>
            </tr> 
        ))}       
      </tbody>
    </table>}
    </div>
  );
};

export default StandindTable;
