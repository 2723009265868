import { Link, useHistory } from 'react-router-dom';
import Logo from '../../../../assets/images/logos/Red.png';
import Layout from '../../../../Common/Layout';
import {connect} from "react-redux";
import { useEffect, useState } from 'react';
import Spinner from "../../../../Common/Spinner/Spinner";

const CreateYourClub = ({ _userProfile } : ICreateClub) => {
    const [LoaderIs , setLoader] = useState<any>(null)
    const history = useHistory();

    const getUserProfileRes = (hasClub : boolean) => {
        if(hasClub){
            history.push("/clublist")            
        }
    }
    
    useEffect(() => {
        const userProfile = _userProfile.userDataIs;
        if(userProfile.status === 1){
          getUserProfileRes(userProfile?.data?.hasClub)     
          setLoader(userProfile?.data?.hasClub)       
        }
      },[_userProfile])

    return (
        <Layout options="fill_Header">
            <div className="main-content">
                <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className="container">
                        <div className="row">
                            {LoaderIs === null ?
                            <Spinner color="text-dark  spinner-border-sm text-center" />:
                            <div className="w-lg-700px text-center bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                                <div className="fv-row mb-10">
                                    <Link to="/"  className='logoLarge'>
                                        <img src={Logo} alt="Logo" className="img-fluid" />
                                    </Link>
                                </div>
                                <form
                                    className="form w-100"
                                    noValidate
                                    id="kt_sign_in_form"
                                    action="#"
                                >
                                    <div className="text-center mb-10">
                                    <h1 className="text-dark text-capitalize mb-5">Create your club</h1>
                                            <p className="text-gray-400 fw-bold fs-4">Create your own Club, then invite friends to join and compete, or go to My Profile to enter your bets.</p>
                                            <p className="text-gray-400 fw-bold fs-4">You can also accept invitations to join other Clubs, check out the Leaderboards, and compare your predictions with other members’ picks.</p>

                                    </div>
                                    <div className="text-center">
                                        <Link to="/clubdetails"
                                            type="submit"
                                            id="kt_sign_in_submit"
                                            className="btn btn-lg btn-orange m-3 px-20"
                                        >
                                            <span className="indicator-label fw-bolder">Create Club</span>
                                        </Link>
                                        <Link to="/myprofile"
                                            className="btn btn-lg btn-light m-3 px-15"
                                        >
                                            <span className="indicator-label fw-bolder">Go to My Profile</span>
                                        </Link>
                                    </div>
                                </form>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
const mapState = ({ userProfile }: any) => {
    return {
        _userProfile: userProfile
    };
};
export default connect(
    mapState,
)(CreateYourClub);




