import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { allTimePoundForPound } from "../../../../../Store/action/Leaderboard.action";
import Spinner from "../../../../../Common/Spinner/Spinner"
import { getSorting, stringNameLength } from "../../../Club/AllEventCalculation/Calculation";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import LeftRightScroll from "../../../Club/LeftRightScroll";

const PoundForPound = (props?: any) => {

    const [data, setData] = useState([])
    const [activeSortName , setActiveSort] = useState<string>("totalBetWinPercentage")
    const [sortingIs , setSorting] = useState<boolean>(false)
    const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>

    useEffect(() =>{
        props?.$allTimePoundForPound()
    },[])

    useEffect(() =>{
        if(props._allTimePoundForPound && props._allTimePoundForPound.length){
            setData(props._allTimePoundForPound)
        }
    }, [props._allTimePoundForPound])

    return (
        <>
        <div ref={slideRef} className="table-responsive h-500">
                <table
                    className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                    id="kt_subscriptions_table_one"
                    role="grid"
                >
                    {/*begin::Table head*/}
                    <thead className="sticky-top" style={{background:"white"}}>
                    {/*begin::Table row*/}
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-1" role="row" > 
                        <th 
                            className={`textLeft min-w-150px sorting  ${activeSortName === "fullName" && (sortingIs ? "sorting_asc" : "sorting_desc")}`}
                            style={{ position: "sticky", left: 0, background: "white", zIndex: 1}} 
                            onClick={() => getSorting("fullName", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Player Name
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "totalBetWinPercentage" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("totalBetWinPercentage", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Bet Win %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "totalPoints" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("totalPoints", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Point Total
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "totalParleyWinPercentage" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("totalParleyWinPercentage", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Parlay win %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "lastEventBetWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("lastEventBetWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last Event win %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "lastEventParlayWin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            // onClick={() => getSorting("lastEventParlayWin", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last Event Parlay Win
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "last5BetWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("last5BetWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last 5 Events Win %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "last5ParleyWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("last5ParleyWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Last 5 Events Parlay %
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "totalMargin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("totalMargin", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            Margin
                        </th>
                        <th 
                            className={`sorting ${activeSortName === "averageROI" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                            onClick={() => getSorting("averageROI", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                        >
                            ROI %
                        </th>
                    </tr>
                    {/*end::Table row*/}
                    </thead>
                    {/*end::Table head*/}
                    {/*begin::Table body*/}
                    {
                        data && data.length > 0
                        ?
                        data.map((obj: any, index: number) => (
                            <tbody className="text-gray-600 fw-bold">
                                <tr className="bg-table-row-1">  
                                    <td className="text-gray-800 text-start " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                                        {
                                            obj.screenName
                                            ?
                                            <Link to={`/player/${obj.screenName}`} className="text-dark">
                                                <div className="row">
                                                    { 
                                                        obj?.profilePic && !obj.profilePic.includes("-blob")
                                                        ?
                                                        <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                            <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.fullName ? obj.fullName : ""}/>
                                                        </div> 
                                                        :
                                                        <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                            <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                                                                {obj?.fullName ? obj.fullName[0].toUpperCase() : ""}
                                                            </span>
                                                        </div>
                                                    }
                                                    <div className="col-9">
                                                        {index+1}.&nbsp;{obj.fullName}
                                                        {
                                                            obj?.status 
                                                            ? 
                                                            <span className="text-muted fw-bold d-block fs-8" data-toggle="tooltip" data-placement="bottom" title={obj?.status?.length > 25 ? obj?.status : ""}>
                                                                {stringNameLength(obj?.status, "", 25, 25)}
                                                            </span> 
                                                            :
                                                            "" 
                                                        }
                                                        <span className="text-muted fw-bold d-block fs-8">{obj.weightClass}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            :
                                            <div className="row">
                                                { 
                                                    obj?.profilePic && !obj.profilePic.includes("-blob")
                                                    ?
                                                    <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                        <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.fullName ? obj.fullName : ""}/>
                                                    </div> 
                                                    :
                                                    <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                        <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                                                            {obj?.fullName ? obj.fullName[0].toUpperCase() : ""}
                                                        </span>
                                                    </div>
                                                }
                                                <div className="col-9">
                                                    {index+1}.&nbsp;{obj.fullName}
                                                    {
                                                        obj?.status 
                                                        ? 
                                                        <span className="text-muted fw-bold d-block fs-8" data-toggle="tooltip" data-placement="bottom" title={obj?.status?.length > 25 ? obj?.status : ""}>
                                                            {stringNameLength(obj?.status, "", 25, 25)}
                                                        </span> 
                                                        :
                                                        "" 
                                                    }
                                                    <span className="text-muted fw-bold d-block fs-8">{obj.weightClass}</span>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td>{obj.totalBetWinPercentage + "%"}</td> 
                                    <td>{obj.totalPoints}</td>
                                    <td>{obj.totalParleyWinPercentage + "%"}</td> 
                                    <td>{obj.lastEventBetWinPercent + "%"}</td> 
                                    <td>{obj.lastEventParlayWin === "false" ? "No": obj.lastEventParlayWin === "true" ? "Yes" : obj.lastEventParlayWin}</td> 
                                    <td>{obj.last5BetWinPercent + "%"}</td>
                                    <td>{obj.last5ParleyWinPercent==="-" ? "-" : obj.last5ParleyWinPercent+"%"}</td>
                                    <td>{ !isNaN(obj.totalMargin) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(obj.totalMargin)): obj.totalMargin}</td>
                                    <td>{obj.averageROI + (!isNaN(obj.averageROI) ? "%" : "")}</td>
                                </tr>
                            </tbody>
                        ))
                        :   
                        <Spinner />
                    }
                    {/*end::Table body*/}
                </table>
            </div>
            <LeftRightScroll slideRef={slideRef}/> 
            </>
    )
}

const mapState = ({ leaderboardReducer }: any) => {
    return {
        _allTimePoundForPound: leaderboardReducer.allTimePoundForPound
    };
};

const mapDispatch = (dispatchEvent?: any) => ({
    $allTimePoundForPound: () => dispatchEvent(allTimePoundForPound()),
});

export default connect(mapState, mapDispatch)(PoundForPound)
