import { put, call } from "redux-saga/effects";
import { getApi, postApi } from "../api-interface/api-interface";
import { EventYearIs } from "../../Utils/BaseUrl";
import {
  sheduleByYearSuccess,
  teamDataSuccess,
  joinEventSuccess,
  joinEventError,
  clubByIdSuccess,
  previousScheduleSuccess,
  previousTeamDataSuccess,
  clubMemberListSuccess,
  removeClubMemberSuccess,
  leaveClubSuccess,
  eventParlayTeamSuccess,
  joinParlayEventSuccess,
  summaryDataSuccess,
  profileBetsSuccess,
  userBetBydateSuccess,
  userSummarySuccess,
  userParlayBydateSuccess,
  userPreviousBetSuccess,
  userPreviousParlaySuccess,
  clubPreviousParlaySuccess,
  getInviteCodeSuccess,
  getClubPreSummarySuccess,
  getProfilePresummarySuccess,
  statsEventsScoreSuccess,
  clubStandingsSuccess,
  publicStatsEventsScoreSuccess
} from "../action/Events.action";

// schedule by year *********
export function* onScheduleByYear(payload: any): any {
  try {
    const response: any | {} = yield call(
      getApi,
      `/schedule/getScheduleByYear?year=${EventYearIs}`
    );
 
    
    if (response?.status === 200) {
      yield put(sheduleByYearSuccess(response.data));
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// team data by date

export function* onTeamData(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/event/getEventByDate?date=${data?.date}&eventName=${data.event_name}&club=${data?.club}&betType=${data?.betType}`
      );
      if (response?.status === 200) {
        const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        response.data.data = sortedByAdminIndex
        yield put(teamDataSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// join event

export function* onJoinEvent(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(postApi, `/event/joinEvent`, data);
      if (response?.status === 200) {
        yield put(joinEventSuccess(response.data));
      }
    }
  } catch (error: any) {
    yield put(joinEventError(error.response.data));
  }
}

// club by id
export function* onClubById(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getClubDetailsByClubId?clubId=${data?.clubId}`,
        data
      );
      if (response?.status === 200) {
        yield put(clubByIdSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(joinEventError(error.response.data));
  }
}

// previous schedule year
export function* onPreviousSchedule(payload: any): any {
  try {
    const response: any | {} = yield call(
      getApi,
      `/leaderboardChart/getEventswithAllFightsCompleted`
    );
    if (response?.status === 200) {
      const sortedByDateDesc = response?.data?.data?.reverse()
      response.data.data = sortedByDateDesc
      yield put(previousScheduleSuccess(response.data));
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// team data by date (for previous)
export function* onPreviousTeamData(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/event/getPastEventByDate?date=${data?.date}&eventName=${data.event_name}&club=${data?.club}&betType=${data?.betType}`
      );
      if (response?.status === 200) {
        const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        response.data.data = sortedByAdminIndex
        yield put(previousTeamDataSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// club Member List
export function* onClubMemberList(payload: any): any {
  try {
    const response: any | {} = yield call(getApi, `/user/totalClubMembers`);
    if (response?.status === 200) {
      yield put(clubMemberListSuccess(response.data));
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// Remove Club Member
export function* onRemoveClubMember(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        postApi,
        `/user/removeClubMember`,
        data
      );
      if (response?.status === 200) {
        yield put(removeClubMemberSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// leave club
export function* onLeaveClub(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(postApi, `/user/leaveClub`, data);
      if (response?.status === 200) {
        yield put(leaveClubSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get event parlay team by date

export function* onParlayTeamEvent(payload: any): any {
  let data = payload.values;
  try {
    const response: any | {} = yield call(
      getApi,
      `/event/getParlayEventByDate?date=${data?.date}&eventName=${data.event_name}&club=${data?.club}&betType=${data?.betType}`
    );
    if (response?.status === 200) {
      const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
      response.data.data = sortedByAdminIndex
      yield put(eventParlayTeamSuccess(response.data));
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// join Parlay events
export function* onJoinParlay(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        postApi,
        `/event/joinParlayEvent`,
        data
      );
      if (response?.status === 200) {
        yield put(joinParlayEventSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

//  get Summary data

export function* onSummaryData(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/event/getEventSummaryByDate?date=${data.date}&eventName=${data.event_name}&club=${data.club}`,
      );
      if (response?.status === 200) {
        yield put(summaryDataSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get profile  bets 
export function* onProfileBets(payload: any): any {
  try {
      const response: any | {} = yield call(
        getApi,
        `/event/getMyBets`,
      );
      if (response?.status === 200) {
        yield put(profileBetsSuccess(response.data));
      }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get user event by date 
export function* onUserEvent(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getUserBetEventByDate?date=${data?.date}&eventName=${data.event_name}`,
      );
      if (response?.status === 200) {
        const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        response.data.data = sortedByAdminIndex
        yield put(userBetBydateSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get user Parlay
export function* onUserParlay(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getUserParlayEventByDate?date=${data.date}&eventName=${data.event_name}`,
      );
      if (response?.status === 200) {
        const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        response.data.data = sortedByAdminIndex
        yield put(userParlayBydateSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get user summary by date 
export function* onUserSummary(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getUserEventSummaryByDate?date=${data.date}&eventName=${data.event_name}`,
      );
      if (response.status === 200) {
        yield put(userSummarySuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get user previous bet by date 

export function* onUserPrebet(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getUserPastBetEventByDate?date=${data.date}&eventName=${data.event_name}`,
      );
      if (response.status === 200) {
        const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        response.data.data = sortedByAdminIndex
        yield put(userPreviousBetSuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get user previous parlay by date

export function* onUserPreviousParlay(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getUserPastParlayEventByDate?date=${data.date}&eventName=${data.event_name}`,
      );
      if (response.status === 200) {
        const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        response.data.data = sortedByAdminIndex
        yield put(userPreviousParlaySuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get club previous parlay by date
export function* onClubPreParlay(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/event/getPastParlayEventByDate?date=${data.date}&eventName=${data.event_name}&club=${data.club}&betType=${data.betType}`,
      );
      if (response.status === 200) {
        const sortedByAdminIndex = response?.data?.data?.sort((a:any,b:any)=> a?.adminIndex - b?.adminIndex)
        response.data.data = sortedByAdminIndex
        yield put(clubPreviousParlaySuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get Invite code 
export function* onGetInviteCode(payload: any): any {
  // let data = payload.values;
  try {
      const response: any | {} = yield call(
        getApi,
        `/user/getInviteCodeByUserId`,
      );
      if (response.status === 200) {
        yield put(getInviteCodeSuccess(response.data));
      }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get club previous Summary

export function* onClubPreSummary(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/event/getEventSummaryByDate?date=${data.date}&eventName=${data.event_name}&club=${data.club}`,
      );
      if (response?.status === 200) {
        yield put(getClubPreSummarySuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// get profile Previous Summary

export function* onProfilePreSummary(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getUserEventSummaryByDate?date=${data.date}&eventName=${data.event_name}`,
      );
      if (response.status === 200) {
        yield put(getProfilePresummarySuccess(response.data));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// stats and events score
export function* onStatsEventsScore(payload: any): any {
  try {
      const response: any | {} = yield call(
        getApi,
        `/user/getUserStatAndEventsScore`,
      );
      if (response.status === 200) {
        yield put(statsEventsScoreSuccess(response.data));
      }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// stats and events score
export function* onPublicStatsEventsScore(payload: any): any {
  const screenName = payload.values
  try {
      const response: any | {} = yield call(
        getApi,
        `/user/getPublicUserStatAndEventsScore?screenName=${screenName}`,
      );
      if (response.status === 200) {
        yield put(publicStatsEventsScoreSuccess(response.data));
      }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}

// club standings

export function* onClubStandings(payload: any): any {
  let data = payload.values;
  try {
    if (data) {
      const response: any | {} = yield call(
        getApi,
        `/user/getClubMembersLeaderboard?clubId=${data.clubId}&year=${data.year}`,
      );
      const userStandings = response.data.data

      //sorting array according to totalPoints
      let sortedData:any = []
      userStandings.map((el:any) => el.totalPoints !=="-" ? sortedData.push(el) : "")
      const sortedDataWithPoint = sortedData.sort((a:any, b:any) => (parseInt(a.totalPoints) > parseInt(b.totalPoints)) ? -1 : ((parseInt(b.totalPoints) > parseInt(a.totalPoints)) ? 1 : 0))
      const fullSortedData = [...sortedDataWithPoint, ...(userStandings.filter((el:any) => el.totalPoints==="-"))]

      if (response.status === 200 ) {
        yield put(clubStandingsSuccess({status:1, data:fullSortedData}));
      }
    }
  } catch (error: any) {
    // yield put(loginDataErr(error.response.data));
  }
}