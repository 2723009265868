
import moment from "moment";
import { useEffect, useState } from "react";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import { externalRedirect, getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import {
    getUser,
    getAllEv,
    getUniqueBeters,
    getMe,
    getitsMe,
    getUniqueMe,
    getTotalWin,
    stringWithValidLength,
    getResult,
    getLastName,
    getResultNew
  } from "../../../Club/AllEventCalculation/Calculation";
  import {
    getParlayHomeMe,
    getBlankParlayHtml,
    getParlayAwayMe,
    getParlayWin,
  } from "../../../Club/EventBetsDetails/EventsParlays/ParlayCalculation/ParlayCalculation";
const ParlaysEventBetslip = (props ?: any) => {
  const { UserEventParlayTeam, risk, setrisk, parlayevents, setParlayEvents, UserparlayLock, setTotalWin } = props;
  const [users, setUsers] = useState<any>(null);
  const [allEv, setAllEv] = useState<any>(null);
  const [uniqueBeters, setUniqueBeters] = useState<any>(null);
  const [meData, setMeData] = useState<any>(null);
  const [itsMe, setItsMe] = useState<any>(null);
  const [uniqueMe, setUniqueMe] = useState<any>(null);
  const [mainCard, setMainCard] = useState<any>();
  const [prelimsCard, setprelimsCard] = useState<any>();
  const [earlyPrelimsCard, setEarlyPrelimsCard] = useState<any>()
  const [myData, setMyData] = useState<any>([])

  useEffect(() => {
    const data = props?.UsersummaryData
    let myDataIs = []
    const myDatas = data && data.find((el:any) => el?.isMe == true)
    myDataIs.push(myDatas)
    setMyData(myDataIs)
  }, [UserEventParlayTeam])

  const handleOnChange = (e: any) => {
    const { value } = e.target;
    if(value <= 999999){
      if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(value) || value === "") {    
        setrisk(value);
      }
    }
  };

  const getrisk = () => {
    const unique = uniqueMe && uniqueMe[0]
    const a = UserEventParlayTeam?.map((item: any) => {
      const x = item?.risks?.find((ob: any) => ob.riskId === unique?.riskId);
      return x
    });

    const risk = a?.find((el :any) => el?.risk)?.risk 
    setrisk(risk);
  }

  useEffect(() =>{
    getrisk();
  },[uniqueMe])

  useEffect(() => {
    setUsers(getUser(UserEventParlayTeam));
    setMeData(getMe(UserEventParlayTeam));
  }, [UserEventParlayTeam]);

  useEffect(() => {
    setAllEv(getAllEv(users));
  }, [users]);

  useEffect(() => {
    setUniqueBeters(getUniqueBeters(allEv));
  }, [allEv]);

  useEffect(() => {
    setItsMe(getitsMe(meData));
  }, [meData]);

  useEffect(() => {
    setUniqueMe(getUniqueMe(itsMe));
  }, [itsMe]);
  useEffect(() => {
    const mainCardFighter = UserEventParlayTeam && UserEventParlayTeam?.filter((ele:any) => ele?.event_card === 'Main Card')
    const prelimsFighter = UserEventParlayTeam && UserEventParlayTeam?.filter((ele:any) => ele?.event_card === 'Prelims')
    const earlyPrelimsFighter = UserEventParlayTeam && UserEventParlayTeam?.filter((ele:any) => ele?.event_card === 'Early Prelims')
    setMainCard(mainCardFighter)
    setprelimsCard(prelimsFighter)
    setEarlyPrelimsCard(earlyPrelimsFighter)
  }, [UserEventParlayTeam]);


    return (
        <div className="table-responsive h-500">
        <table
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            id="kt_subscriptions_table_3"
            role="grid"
        >
            {/*begin::Table head*/}
            <thead className="sticky-top" style={{background:"white"}}>
            {/*begin::Table row*/}
            <tr
                className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-2"
                role="row"
            > 
                <th
                className="min-w-250px cu-12 sorting text-start"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Customer: activate to sort column ascending"
                style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}
                >
                Fighters
                </th>
                {/* <th
                className="min-w-100px sorting"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Status: activate to sort column descending"
                style={{ width: 100 }}
                // aria-sort="ascending"
                >
                Moneyline
                </th> 
                <th
                  className="min-w-100px sorting"
                  tabIndex={0}
                  aria-controls="kt_subscriptions_table"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Billing: activate to sort column ascending"
                  style={{ width: 100 }}
                >
                  Result
                </th> */}
                <th
                className="min-w-175px sorting"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Product: activate to sort column ascending"
                style={{ width: 175 }}
                >
                Me 
                </th>               
            </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold cu-table-01">
              {/* Main Card */}
            {mainCard?.length > 0 ? (
              <>
             <div className="badge badge-pill btn-orange" style = {{fontSize:'14px', marginTop:'2px',marginLeft:'10px', position:'sticky', left:0}}>Main Card: {moment(mainCard[0]?.eventCardTime).format("hh:mm a")}</div>
             {mainCard.map((obj: any, index: any) => (
              <>    
                <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"} 
                  className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                  <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                        }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}
                  {
                    obj.moneyline_away 
                    ? 
                      uniqueMe?.length > 0
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayAwayMe( ob, obj, itsMe, parlayevents, setParlayEvents, UserparlayLock ) )
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 2, parlayevents, setParlayEvents, UserparlayLock, props?.isPublicProfileUser, obj.moneyline_away) : <td className="table-column">-</td>                    
                    :
                    <td className="table-column">-</td>
                  }  
                </tr>            
                <tr className="bg-table-row-2">
                  <td className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                    <span className="image-avatar-wrapper"  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {
                        !obj?.homeTeamPic
                        ?
                        <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                          <span
                            className={`symbol-label ${
                              obj.moneyline_home < 0
                                ? "bg-light-success text-success"
                                : obj.moneyline_home > 0
                                ? "bg-light-danger text-danger"
                                : "bg-light text-dark"
                            } 40px`}
                          >
                            {obj?.home_team.charAt(0)}
                          </span>
                        </span>
                        :
                        <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                          <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                        </div>
                      }
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {
                    obj?.moneyline_home 
                    ?
                      (uniqueMe?.length > 0)
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayHomeMe( ob, obj, itsMe, parlayevents, setParlayEvents, UserparlayLock ))
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 1, parlayevents, setParlayEvents, UserparlayLock, props?.isPublicProfileUser, obj?.moneyline_home ) : <td className="table-column">-</td>
                    :
                    <td className="table-column">-</td>
                  }             
                </tr>                
              </>
            ))}</>
          ) : (
            <tr className="bg-table-row-transparent">
              <td className="text-gray-800 text-start ps-4">
                {
                  props?.isPublicProfileUser
                  ?
                  "No picks are locked yet"
                  :
                  "Data Not found..."
                }
              </td>
            </tr>
          )}

          {/* Prelims */}

          {prelimsCard?.length > 0 ? (
            <>
             <div className="badge badge-pill btn-orange" style = {{fontSize:'14px', marginTop:'2px',marginLeft:'10px'}}>Prelims: {moment(prelimsCard[0]?.eventCardTime).format("hh:mm a")}</div>
            {prelimsCard.map((obj: any, index: any) => (
              <>    
                <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"} 
                  className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                  <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                        }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}
                  {
                    obj.moneyline_away 
                    ? 
                      uniqueMe?.length > 0
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayAwayMe( ob, obj, itsMe, parlayevents, setParlayEvents, UserparlayLock ) )
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 2, parlayevents, setParlayEvents, UserparlayLock, props?.isPublicProfileUser, obj.moneyline_away) : <td className="table-column">-</td>                    
                    :
                    <td className="table-column">-</td>
                  }  
                </tr>            
                <tr className="bg-table-row-2">
                  <td className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                    <span className="image-avatar-wrapper"  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {
                        !obj?.homeTeamPic
                        ?
                        <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                          <span
                            className={`symbol-label ${
                              obj.moneyline_home < 0
                                ? "bg-light-success text-success"
                                : obj.moneyline_home > 0
                                ? "bg-light-danger text-danger"
                                : "bg-light text-dark"
                            } 40px`}
                          >
                            {obj?.home_team.charAt(0)}
                          </span>
                        </span>
                        :
                        <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                          <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                        </div>
                      }
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {
                    obj?.moneyline_home 
                    ?
                      (uniqueMe?.length > 0)
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayHomeMe( ob, obj, itsMe, parlayevents, setParlayEvents, UserparlayLock ))
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 1, parlayevents, setParlayEvents, UserparlayLock, props?.isPublicProfileUser, obj?.moneyline_home ) : <td className="table-column">-</td>
                    :
                    <td className="table-column">-</td>
                  }             
                </tr>                
              </>
            ))}</>
          ) : (
            <tr className="bg-table-row-transparent">
              <td className="text-gray-800 text-start ps-4">
                {/* {
                  props?.isPublicProfileUser
                  ?
                  "No picks are locked yet"
                  :
                  "Data Not found..."
                } */}
                
              </td>
            </tr>
          )}

          {/* Early Prelims */}

          {earlyPrelimsCard?.length > 0 ? (
            <>
             <div className="badge badge-pill btn-orange" style = {{fontSize:'14px', marginTop:'2px',marginLeft:'10px'}}>Early Prelims: {moment(earlyPrelimsCard[0]?.eventCardTime).format("hh:mm a")}</div>

            { earlyPrelimsCard.map((obj: any, index: any) => (
              <>    
                <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"} 
                  className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                  <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                        }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}
                  {
                    obj.moneyline_away 
                    ? 
                      uniqueMe?.length > 0
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayAwayMe( ob, obj, itsMe, parlayevents, setParlayEvents, UserparlayLock ) )
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 2, parlayevents, setParlayEvents, UserparlayLock, props?.isPublicProfileUser, obj.moneyline_away) : <td className="table-column">-</td>                    
                    :
                    <td className="table-column">-</td>
                  }  
                </tr>            
                <tr className="bg-table-row-2">
                  <td className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                    <span className="image-avatar-wrapper"  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {
                        !obj?.homeTeamPic
                        ?
                        <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                          <span
                            className={`symbol-label ${
                              obj.moneyline_home < 0
                                ? "bg-light-success text-success"
                                : obj.moneyline_home > 0
                                ? "bg-light-danger text-danger"
                                : "bg-light text-dark"
                            } 40px`}
                          >
                            {obj?.home_team.charAt(0)}
                          </span>
                        </span>
                        :
                        <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                          <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                        </div>
                      }
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span  onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {
                    obj?.moneyline_home 
                    ?
                      (uniqueMe?.length > 0)
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayHomeMe( ob, obj, itsMe, parlayevents, setParlayEvents, UserparlayLock ))
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 1, parlayevents, setParlayEvents, UserparlayLock, props?.isPublicProfileUser, obj?.moneyline_home ) : <td className="table-column">-</td>
                    :
                    <td className="table-column">-</td>
                  }             
                </tr>                
              </>
            ))}</>
          ) : (
            ""
            // <tr className="bg-table-row-transparent">
            //   <td className="text-gray-800 text-start ps-4">
            //     {
            //       props?.isPublicProfileUser
            //       ?
            //       "No picks are locked yet"
            //       :
            //       "Data Not found..."
            //     }
            //   </td>
            // </tr>
          )}
          {/* Total Number Row  */}
          {UserEventParlayTeam?.length > 0 &&
          <tr className="total_number">
            <td className="ps-5 text-start" style={{ width: 175, position: "sticky", left: 0, background: "black", zIndex: 1}}>
               Parlay Bet Amount and ROI
            </td>
            {/* <td></td>
            <td></td> */}
            <td>
              {
                myData && myData.map((ele:any) => {
                  return <div className="d-flex align-items-center justify-content-between">
                 <div className={`w-50 ${ele == undefined || ele?.parleyRisk == 0 ? '':ele?.isParleyWin ? "" : "r Lose"}`}>
                      {
                        UserparlayLock 
                        ? 
                        <div>{!isNaN(risk) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(risk) : "$ 0.00"}</div>
                         :
                        <span className="d-flex position-relative">
                          {risk ? <span className="m-2 position-absolute inputBoxDollarsign">$</span> : ""} {/* if value then show $ */}
                          {
                          mainCard?.length > 0 ? 
                          getDiffrenceOfTimeInMinutes(mainCard[0]?.eventCardTime) > 0 ? <input type="text" className="form-control form-control-solid ms-1" placeholder=" " name="risk" value={risk} onChange={handleOnChange} disabled={UserparlayLock} /> : <span className="mx-6">$0.00</span>
                          :
                          prelimsCard?.length > 0 ?
                          getDiffrenceOfTimeInMinutes(prelimsCard[0]?.eventCardTime) > 0 ? <input type="text" className="form-control form-control-solid ms-1" placeholder=" " name="risk" value={risk} onChange={handleOnChange} disabled={UserparlayLock} /> : <span className="mx-6">$0.00</span>
                          :
                          earlyPrelimsCard?.length > 0 ?
                          getDiffrenceOfTimeInMinutes(earlyPrelimsCard[0]?.eventCardTime) > 0 ? <input type="text" className="form-control form-control-solid ms-1" placeholder=" " name="risk" value={risk} onChange={handleOnChange} disabled={UserparlayLock} /> : <span className="mx-6">$0.00</span>
                          :
                          ''
                          }
                        </span> 
                      }
  
                  </div>
                  <div className={`w-50 ${ele?.isParleyWin ? "w Win" : ""}`}>
                      {UserparlayLock ? "$" + ele?.parlayWin : getParlayWin(parlayevents, risk, setTotalWin)}   
                  </div>
                </div>
                })
              }
              
            </td>
          </tr>}
            </tbody>
            {/*end::Table body*/}
        </table>
    </div>
    )
}
export default ParlaysEventBetslip
