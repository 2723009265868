import { useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import  {firebaseConfig}  from "./firebaseConfig"
import { BrowserRouter as Router } from 'react-router-dom';
import RootRouting from "./Routing/RootRouting";
import {Provider} from 'react-redux';
import makeStore from "./Store/index.store";
const store = makeStore();

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  useEffect(()=>{
    console.log("analytics", analytics);
  },[])
  return (
    <Provider store={store}>
      <Router>
      <RootRouting />
      </Router>
    </Provider>
  );
}

export default App;
