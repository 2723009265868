import loginData from "./Login.reducer";
import signUpData from "./Login.reducer";
import forgotPwd from "./Login.reducer";
import resetPwd from "./Login.reducer";
import userProfile from "./UserInfo.reducer";
import getCountry from "./UserInfo.reducer";
import updateUser from "./UserInfo.reducer";
import createClub from "./UserInfo.reducer";
import changePwd from "./UserInfo.reducer";
import getClubDetails from "./UserInfo.reducer";
import inviteFrnds from "./UserInfo.reducer";
import joinClub from './UserInfo.reducer';
import profilePic from "./UserInfo.reducer";
import joiningClubDetails from "./UserInfo.reducer";
import clubPic from "./UserInfo.reducer";
import sheduleByYear from "./Events.reducer";
import teamData from "./Events.reducer";
import joinEvent from "./Events.reducer";
import clubById from "./Events.reducer";
import previousSchedule from "./Events.reducer";
import previousTeamData from "./Events.reducer";
import clubMemberList from "./Events.reducer";
import removeClubMember from "./Events.reducer";
import leaveClub from "./Events.reducer";
import eventParlayTeam from "./Events.reducer";
import joinParlayEvent from "./Events.reducer";
import summaryData from "./Events.reducer";
import profileBets from "./Events.reducer";
import userEventByDate from "./Events.reducer";
import userSummary from "./Events.reducer";
import userParlayBydate from "./Events.reducer";
import userPreviousBet from "./Events.reducer";
import userPreviousParlay from "./Events.reducer";
import clubPreviousParlay from "./Events.reducer";
import getInviteCode from "./Events.reducer";
import getClubPreSummary from "./Events.reducer";
import getProfilePreSummary from "./Events.reducer";
import statsEventsScore from "./Events.reducer";
import publicstatsEventScore from "./Events.reducer";
import clubStandings from "./Events.reducer";
import leaderboardReducer from "./Leaderboard.reducer"
import chatReducer from "./Chat.reducer"

const allData = {
  loginData,
  signUpData,
  forgotPwd,
  resetPwd,
  userProfile,
  getCountry,
  updateUser,
  createClub,
  changePwd,
  getClubDetails,
  inviteFrnds,
  joinClub,
  profilePic,
  joiningClubDetails,
  clubPic,
  sheduleByYear,
  teamData,
  joinEvent,
  clubById,
  previousSchedule,
  previousTeamData,
  clubMemberList,
  removeClubMember,
  leaveClub,
  eventParlayTeam,
  joinParlayEvent,
  summaryData,
  profileBets,
  userEventByDate,
  userSummary,
  userParlayBydate,
  userPreviousBet,
  userPreviousParlay,
  clubPreviousParlay,
  getInviteCode,
  getClubPreSummary,
  getProfilePreSummary,
  statsEventsScore,
  clubStandings,
  leaderboardReducer,
  chatReducer
};

export default allData
