import * as yup from "yup";

export const initialValues_prsnlSetting = {
    email:"",
    firstName : "",
    lastName:  "",
    country: {},
    bio:'',
    isFollowNotificationEnabled:true,
    isClubNotificationEnabled:true,
    isChatNotificationEnabled:true
}

export const validationSchema_prsnlSetting = yup.object().shape({
   firstName : yup.string().required("Required").nullable(),
   email: yup.string().required("Required").email("Invalid email"),
   lastName : yup.string().required("Required").nullable(),
//    country: yup.object().required("Please select country"),
    status: yup.string().max(100, "Maximum 100 characters allowed").nullable(),
    bio:yup.string().max(500, "Maximum 500 characters allowed").nullable()
})

export const getFormat = (data : any) => {

    const dataIs = data?.map((obj:any) => {
        return {
            
            label: obj.name,
            value: obj.name,
            ...obj
        }
    })

    return dataIs;
}