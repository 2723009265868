import EventBets from "../EventBets/EventBets";
import ParlaysEventBetslip from "../EventsParlays/ParlaysEventBetslip";
import SummaryEventBetslip from "../EventSummary/SummaryEventBetslip";
import { connect } from "react-redux";
import {
  sheduleByYear,
  teamData,
  joinEvent,
  eventParlayTeam,
  summaryData,
  joinParlayEvent,
  userEventByDate,
} from "../../../../../Store/action/Events.action";
import React, { useEffect, useState } from "react";
import Spinner, { ButtonSpinner } from "../../../../../Common/Spinner/Spinner";
import { getCurrentWin } from "../../AllEventCalculation/Calculation";
import { problity } from "../EventsParlays/ParlayCalculation/ParlayCalculation";
import moment from "moment";
import Countdown from 'react-countdown';
import { getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import LiveGif from "../../../../../assets/images/live.gif"

const EventBetSlip = (props?: any) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [betType, setBetType] = useState<string>("1");
  const [teamList, setTeamList] = useState<any>(null);
  const [eventBetTotal , setEventBetTotal] = useState<any>(0)
  const [Loading, setLoading] = useState<boolean>(false);
  const [betData, setBetData] = useState<IBetdata[]>([{
    scheduleId: 0, 
    risk: "", 
    pickedTeam: ""
  }]);
  const [Loader, setLoader] = useState<boolean>(false);
  const [statusIs, setStatus] = useState<string>("0");
  const [IsLocked, setLocked] = useState<boolean>(false);
  const [teamLoader, setTeamLoader] = useState<boolean>(false);
  const [eventParlayTeam, setParlayTeam] = useState<any>(null);
  const [parlayLoader, setParlayLoader] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [sortingIs , setSorting] = useState<boolean>(false)
  const [activeSortName , setActiveSort] = useState<string>("")
  const [summaryLoader, setSummaryLoader] = useState<boolean>(false);
  const [parlayevents, setParlayEvents] = useState<Iparlayevents[]>([
    {
      scheduleId: 0, 
      pickedTeam: "", 
      riskId: ""
    }
  ]);
  const [risk, setrisk] = useState<number>(0);
  const [parlaybetLoader, setParlaybetLoader] = useState<boolean>(false);
  const [parlayLock, setParlayLock] = useState<boolean>(false);
  const [totalWinIs , setTotalWin] = useState<any>(0)
  const [selectedBet, setSelectedBet] = useState<any>()
  
  const liveDateTIme =  props?._userEventByDate?.teamDataIs?.data?.slice(-1)[0]?.date_event
  const getRiskData = () => {
    if (teamList?.length) {
      const a = teamList.map((w: ITeamList) => {  
        const meData = w.events?.find((l: any) => l.isMe === true);
        const moneyLine = meData?.pickedTeam === 1 ? w?.moneyline_home : w?.moneyline_away;
        
        return {
          scheduleId: w.id,
          risk: meData ? meData.risk : "",
          pickedTeam: meData ? meData.pickedTeam : "",
          win : getCurrentWin(meData?.risk , moneyLine),
          moneyLine: (meData?.pickedTeam && !isNaN(meData?.pickedTeam)) ? (meData?.pickedTeam===2 ? w?.moneyline_away : w?.moneyline_home) : ""
        };
      });
      setBetData(a);
    }
  };

  const checkBetLocked = () => {
    if (teamList?.length) {
      const isLocked = teamList.some((s: ITeamList) => s.isLock === false) ? false : true
      setLocked(isLocked);
    }
  };

  const checkParlayLocked = () => {
    if (eventParlayTeam?.length) {
      const isLocked = eventParlayTeam.some((s: any) => s.isLock === true);
      setParlayLock(isLocked);
    }
  };

  useEffect(() => {
    getRiskData();
    checkBetLocked();
  }, [teamList]);
  
  const getParlayRiskData = () => {
    if (eventParlayTeam?.length) {
      const a = eventParlayTeam.map((w: any) => {
        const meData = w.events.find((l: any) => l.isMe === true);
        return {
          scheduleId: w.id,
          pickedTeam: meData ? meData.pickedTeam : "",
          riskId: meData ? meData.riskId : "",
          problity : meData?.pickedTeam ? problity(w, meData.pickedTeam) : ""
        };
      });
      setParlayEvents(a);
    }
  };


  useEffect(() => {
    checkParlayLocked();
    getParlayRiskData();
  }, [eventParlayTeam]);

  const handleEventType = (type: string) => {
    setBetType(type);
  };

  useEffect(() => {
    getTeam();
  }, [betType, selectedOption]);

  useEffect(() => {
    setLoading(true);
    props.$sheduleByYear();
  }, []);

  useEffect(() => {
    const eventData = props._sheduleByYear.sheduleIs;
    if (eventData?.status === 1 && Loading) {
      setLoading(false);
      const ObjIs = eventData?.data && eventData?.data[0];
      const defaultValueIs = {
        label: ObjIs?.date_event,
        ...ObjIs,
      };
      setSelectedOption(defaultValueIs);
    }
  }, [props._sheduleByYear]);

  const getSummary = (payload: any) => {
    props.$summaryData(payload);
    setSummaryLoader(true);
  };

  const getTeam = () => {
    if(selectedOption?.label){
      const payload = { date: selectedOption?.label, event_name : selectedOption?.event_name, club: props?.clubId, betType };
      if (payload && betType === "1") {
        setTeamLoader(true);
        setTimeout(()=>{
        props.$teamData(payload);        
      },1000)      
    } else if (payload && betType === "2") {
      props.$eventParlayTeam(payload);
      getSummary(payload)
      setParlayLoader(true);
    } else if (payload && betType === "3") {
      getSummary(payload);
    }
  }
  };

  useEffect(() => {
    const data = props._teamData.teamDataIs;
    if (data?.status === "1") {
      setTeamList(data?.data);
      setEventBetTotal(data?.clubUserBetTotal)
      setTeamLoader(false);
    }
  }, [props._teamData]);

  useEffect(() => {
    const parlayData = props._eventParlayTeam.parlayListIs;

    if (parlayData?.status === "1") {
      setParlayTeam(parlayData?.data);
      setParlayLoader(false);
    }
  }, [props._eventParlayTeam]);

  useEffect(() => {
    const summaryIs = props._summaryData.summaryIs;
    if (summaryIs?.status === "1" ) {
      setParlayLoader(false)
      setSummaryLoader(false);
      setSummaryData(summaryIs?.data);
    }
  }, [props._summaryData]);

  //   Save and Lock *************************

  const getbets = (status: string, handleSaveDraft:boolean = true) => {
    const dataIs = { status, betType, events : betData };
    if(dataIs){
    if (dataIs?.events?.filter((b:any)=>(b.risk !== "" && b.risk !== null)).length > 0) {
      if(handleSaveDraft){
        setLoader(true);
      }      
      props.$joinEvent(dataIs);
    } else {
      alert("Atleast 1 risk is Required..");
    }
   }
  };

  useEffect(() => {
    if(selectedBet){
      const dataIs = { status:selectedBet && selectedBet[0]?.status ? selectedBet[0]?.status : "2", betType, events : selectedBet };  
      if(dataIs?.status === "2"){
        setLoader(true)
      }
      props.$joinEvent(dataIs);
    }
  }, [selectedBet])

  const getParlays = (status: string) => {
    const mainCardFightTime = eventParlayTeam.find((ele:any) => ele?.event_card)?.eventCardTime
    const lastCardDuration = getDiffrenceOfTimeInMinutes(mainCardFightTime)
    let eventsData: any = [];
    parlayevents?.forEach((el: Iparlayevents) => {
      const riskId = el?.riskId ? el.riskId : "";
      const a =
        !el.riskId && el.pickedTeam
          ? { ...el, action: 1, riskId }
          : el?.riskId && el?.pickedTeam
          ? { ...el, action: 2, riskId }
          : el?.riskId && !el?.pickedTeam
          ? { ...el, action: 3, riskId }
          : { ...el, action: "", riskId };
      eventsData.push(a);
    });
    const events = eventsData?.filter((el: any) => el.action);
    const noParlayBetIs = events.some((obj : any) => (obj.action !== 3))
    if(!noParlayBetIs){
      setrisk(0)
    }
    const dataIs = { status, betType, risk: noParlayBetIs ? risk : 0, events, totalWin : totalWinIs };
    if (lastCardDuration > 0) {
      if(dataIs){
        const {events} = dataIs
        const selectedData = eventParlayTeam.filter((ele:any) => events.find((el:any) => el.scheduleId === ele.id))
        if(Boolean(selectedData?.length)){
          for(let i = 0; i < selectedData.length; i++){
            if(getDiffrenceOfTimeInMinutes(selectedData[i].eventCardTime) <= 0 ){
              alert("Some of the fighters you selected are no longer available. Please update your Parlay before locking in")
              getTeam() 
              return
            }
          }
        }
        if (risk || (!noParlayBetIs)) {
          if (dataIs?.events?.filter((e:any)=>e.action !== 3).length > 1) {
            setParlaybetLoader(true);        
            props.$joinParlayEvent(dataIs);
          } else {
            alert("You must select at least 2 fighters for a Parlay");
          }
        } else {
          alert("You must enter your Risk amount to lock in Bets");
        }
      }
    }else{
      alert("Sorry, but the window to add a Parlay has passed")
    }
  };

  const handleSaveDraft = (status: string, toshowLoader:boolean) => {
    if (betType === "1") {
      getbets(status, toshowLoader);
    } else if (betType === "2") {
      getParlays(status);
    }
    setStatus(status);
  };

  useEffect(() => {
    const joinedData = props._joinEvent.joinEventData;
    if (joinedData && Loader) {
      setLoader(false);
      getTeam();
    }
  }, [props._joinEvent]);

  useEffect(() => {
    const joinedParlayData = props._joinParlayEvent.joinParlayIs;
    if (joinedParlayData?.status === 1 && parlaybetLoader) {
      setParlaybetLoader(false);
      getTeam();
    }
  }, [props._joinParlayEvent]);

  //   Save and Lock *************************
  // const getdateDuration = () => {
  //   const now = moment(new Date()); //todays date
  //   const end = moment(selectedOption?.date_event); // another date
  //   const duration1 = moment.duration(end.diff(now));
  //   setDateDiff(Date.now() + duration1?.asSeconds()*1000)
  // }
  
  // useEffect(() =>{
    // getdateDuration()
    // setInterval(() =>{
      // getdateDuration()
    // },10000)
  // },[selectedOption?.date_event])    
  

  const renderer = ({days, hours, minutes, seconds, completed }:any) => {
    if(days===0 && hours===0 && (minutes===14 || minutes===15) && seconds===0){
      getTeam()
    }
    if (completed) {
      return <></>
    } else {
      // Render a countdown
      return <span className="text-danger">{days + " Days " + hours + " Hrs " + minutes + " Min "}</span>
    }
  };
  return (
    <>
    <section className="mb-15">
      <div className="row">
        <div className="col-sm-12">
          <div className="accordion shadow-xs" id="kt_accordion_2">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button accordion-button-cu bg-white flex-wrap text-dark fw-bolder fs-2">
                  Event Betslip
                  {
                  getDiffrenceOfTimeInMinutes(liveDateTIme) <= 0
                    ?
                    <span className="mx-2 bold text-danger"><img style={{width: "30px"}} src={LiveGif} />LIVE</span>
                    :
                    ""
                  }
                  <span className="right_side right_side_unset text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
                    <span style={{marginRight:10}}>{selectedOption?.event_name}</span>{" "}
                  </span>
                  <span
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_1_body_2"
                    aria-expanded="true"
                    aria-controls="kt_accordion_1_body_2"
                  ></span>
                  <div className="col-12 mt-3 mb-0">
                  <small>
                     {
                      selectedOption?.date_event && ((!IsLocked && betType === "1") || (!parlayLock && betType === "2")) 
                      ?
                      <div className="row">
                        <div className="col right_side text-muted"> 
                          {getDiffrenceOfTimeInMinutes(selectedOption?.date_event) > 0 ? 
                       liveDateTIme && <p className="event-countdown text-danger">Event starts in: <Countdown date={liveDateTIme} renderer={renderer} /></p>
                        :
                        ""}
                        </div>                        
                      </div>
                      :
                      ""
                    }
                  </small>
                  </div>
                </button>
              </h2>            
              <div
                id="kt_accordion_1_body_2"
                className="accordion-collapse collapse show"
                aria-labelledby="kt_accordion_1_header_2"
                data-bs-parent="#kt_accordion_2"
              >
                <div className="accordion-body pt-3">
                  <div className="card-xl-stretch">
                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 pt-3 px-2">
                      <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                        <ul className="nav">
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_1"
                              onClick={() => handleEventType("1")}
                            >
                              Bets
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_2"
                              onClick={() => handleEventType("2")}
                            >
                              Parlays
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_3"
                              onClick={() => handleEventType("3")}
                            >
                              Summary
                            </a>
                          </li>
                        </ul>
                      </div>

                      {/* save and draft button */}
                      {betType !== "3" && getDiffrenceOfTimeInMinutes(selectedOption?.eventCardTime) > 0 && (
                        <div className="card-title flex-column text-end text-sm-start mob-w-100 mb-4 mb-sm-0 lockButtons">
                          {(
                            // (IsLocked && betType === "1") ||
                            (!parlayLock && betType === "2")
                          ) && (
                            <button
                              type="button"
                              className="btn btn-sm btn-light fw-bold"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Saving as a Draft will store your initial thoughts, but your picks will not be officially recorded and other users will not see them until you Lock In."
                              onClick={() => handleSaveDraft("1", true)}
                            >
                              {(Loader || parlaybetLoader) &&
                              statusIs === "1" ? (
                                <ButtonSpinner color="text-light  spinner-border-sm " />
                              ) : (
                                "Save Draft"
                              )}
                            </button>
                          )}
                          <button
                            type="button"
                            className={`btn btn-sm btn-orange fw-bold ms-4 ${
                              (IsLocked && betType === "1") ||
                              (parlayLock && betType === "2")
                                ? "not-allowed"
                                : ""
                            }`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Once you select Lock In, other users can see your picks and you will not be able to change or add to them"
                            onClick={
                              !(
                                (IsLocked && betType === "1") ||
                                (parlayLock && betType === "2")
                              )
                                ? () => handleSaveDraft("2", true)
                                : () => {}
                            }
                          >
                            {!(
                              (IsLocked && betType === "1") ||
                              (parlayLock && betType === "2")
                            ) ? (
                              (Loader || parlaybetLoader) &&
                              statusIs === "2" ? (
                                <ButtonSpinner color="text-light  spinner-border-sm " />
                              ) : (
                                betType === "1" ? "Lock All" : "Lock In "
                              )
                            ) : (
                              "Locked"
                            )}
                          </button>
                        </div>
                      )}
                      {/* save and draft button */}
                    </div>
                    <div className="card-body py-3 px-2">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="kt_table_widget_1_tab_1"
                        >
                          {teamLoader ? (
                            <Spinner color="text-dark  spinner-border-sm text-center" />
                          ) : (
                            <EventBets
                              teamList={teamList}
                              betType={betType}
                              betData={betData}
                              setBetData={setBetData}
                              IsLocked={IsLocked}
                              eventBetTotal={eventBetTotal}                              
                              handleSaveDraft={handleSaveDraft}    
                              setSelectedBet={setSelectedBet}                        
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_2"
                        >
                          {parlayLoader ? (
                            <Spinner color="text-dark  spinner-border-sm text-center" />
                          ) : (
                            <ParlaysEventBetslip
                              eventParlayTeam={eventParlayTeam}
                              betType={betType}
                              risk={risk}
                              setrisk={setrisk}
                              parlayevents={parlayevents}
                              setParlayEvents={setParlayEvents}
                              parlayLock={parlayLock}
                              setTotalWin={setTotalWin}
                              summaryData={summaryData}
                              setSummaryData ={setSummaryData}
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_3"
                        >
                          {summaryLoader ? (
                            <Spinner color="text-dark  spinner-border-sm text-center" />
                          ) : (
                            <SummaryEventBetslip summaryData={summaryData}  setSummaryData ={setSummaryData} sortingIs ={sortingIs} 
                            setSorting = {setSorting} activeSortName ={activeSortName} setActiveSort = {setActiveSort}/>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};
const mapState = ({
  sheduleByYear,
  teamData,
  joinEvent,
  eventParlayTeam,
  summaryData,
  joinParlayEvent,
  userEventByDate,

}: any) => {
  return {
    _sheduleByYear: sheduleByYear,
    _teamData: teamData,
    _joinEvent: joinEvent,
    _eventParlayTeam: eventParlayTeam,
    _summaryData: summaryData,
    _joinParlayEvent: joinParlayEvent,
    _userEventByDate: userEventByDate,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $sheduleByYear: () => dispatchEvent(sheduleByYear()),
  $teamData: (values?: IclubForAllbetPayload) => dispatchEvent(teamData(values)),
  $joinEvent: (values?: IDraftForbets) => dispatchEvent(joinEvent(values)),
  $eventParlayTeam: (values?: IclubForAllbetPayload) => dispatchEvent(eventParlayTeam(values)),
  $summaryData: (values?: IclubForAllbetPayload) => dispatchEvent(summaryData(values)),
  $joinParlayEvent: (values?: IDraftForParlay) => dispatchEvent(joinParlayEvent(values)),
  $userEventByDate: (values?: IAllBetForUser) => dispatchEvent(userEventByDate(values)),
});
export default connect(mapState, mapDispatch)(EventBetSlip);
