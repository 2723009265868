import Layout from "../../../../Common/Layout";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  initialValues_forgotPwd,
  validationSchema_forgotPwd,
} from "./Validation";
import FormError from "../../../../Common/FormError/FormError";
import { connect } from "react-redux";
import { forgotPwd } from "../../../../Store/action/Login.action";
import { useEffect, useState } from "react";
import { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import Alert from "../../../../Common/Alert/Alert";

const ResetPassword = ({ $forgotPwd, _forgotPwd }: IforgetPwdProps) => {
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });

  const onSubmit = (values: IforgetPwd) => {
    setLoader(true);
    $forgotPwd(values);
  };

  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  useEffect(() => {
    const fogetPwdData = _forgotPwd.forgotPwdIs;
    const errData = _forgotPwd.forgotPwdErrIs;
    if (fogetPwdData?.status === 1 && LoadingIs) {
      getAlert(fogetPwdData?.message, "alert-success");
      setLoader(false);
    } else if (errData && LoadingIs) {
      getAlert(errData?.message, "alert-danger");
      setLoader(false);
    }
  }, [_forgotPwd]);

  useEffect(() => {
    if (alert.activeIs) {
      setTimeout(() => {
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 3000);
    }
  }, [alert]);

  return (
    <Layout options="fill_Header">
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="container">
            <div className="row">
              <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                {alert.activeIs && (
                  <Alert
                    statusIs={`alert ${alert.statusIs}`}
                    message={alert.msgIs}
                  />
                )}
                <Formik
                  initialValues={initialValues_forgotPwd}
                  validationSchema={validationSchema_forgotPwd}
                  onSubmit={onSubmit}
                  validateOnMount
                >
                  {(formik) => (
                    <Form
                      className="form w-100"
                      noValidate
                      id="kt_sign_in_form"
                      action="#"
                    >
                      <div className="text-center mb-10">
                        <h1 className="text-dark mb-4 text-capitalize">
                          Reset password
                        </h1>
                        <p className="text-gray-400 fw-bolder fs-4">
                          Please provide your registered email address and we
                          will send a link with instructions.
                        </p>
                      </div>
                      <div className="fv-row mb-10">
                        <label className="form-label fs-6 fw-bolder text-dark">
                          Email
                        </label>
                        <Field
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.email &&
                            formik.errors.email &&
                            `border border-danger`
                          }`}
                          type="text"
                          name="email"
                          id="email"
                          autoComplete="off"
                          placeholder="Enter Email"
                        />
                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          disabled={!formik.isValid}
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-5"
                        >
                          <span className="indicator-label fw-bolder">
                            {LoadingIs ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              `Send Link`
                            )}
                          </span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapState = ({ forgotPwd }: any) => {
  return {
    _forgotPwd: forgotPwd,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $forgotPwd: (values?: IforgetPwd) => dispatchEvent(forgotPwd(values)),
});
export default connect(mapState, mapDispatch)(ResetPassword);
